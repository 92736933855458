import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AxiosInstance from "../../../utils/axios";
export const sendPdfEmail = createAsyncThunk(
  "sendPdfEmail",
  async (phoneReportId) => {
    try {
      const response = await AxiosInstance.post(
        `/phone_report/email_phone_report_pdf`,
        {
          phone_report_id: phoneReportId,
        }
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data.message;
      } else {
        throw error;
      }
    }
  }
);

const sendPdfEmailSlice = createSlice({
  name: "sendPdfEmail",
  initialState: {
    isLoading: false,
    sendPdfEmail: null,
    isError: false,
    errorMessage: "",
  },
  extraReducers: {
    [sendPdfEmail.pending]: (state) => {
      state.isLoading = true;
    },
    [sendPdfEmail.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.sendPdfEmail = action.payload;
      state.isError = false;
      state.errorMessage = "";
    },
    [sendPdfEmail.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.error.message;
    },
  },
});

export default sendPdfEmailSlice.reducer;
