import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import AxiosInstance from "../../../utils/axios";
export const createPdfSubscription = createAsyncThunk(
  "createPdfSubscription",
  async (subscriptionId) => {
    try {
      const response = await AxiosInstance.post(
        `/report_subscription/pdf_download/create`,
        {
          subscription_id: subscriptionId
        }
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data.message;
      } else {
        throw error;
      }
    }
  }
);

const createPdfSubscriptionSlice = createSlice({
  name: "createPdfSubscription",
  initialState: {
    isLoading: false,
    createPdfSubscription: null,
    isError: false,
    errorMessage: ""
  },
  extraReducers: {
    [createPdfSubscription.pending]: (state) => {
      state.isLoading = true;
    },
    [createPdfSubscription.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.createPdfSubscription = action.payload;
      state.isError = false;
      state.errorMessage = "";
    },
    [createPdfSubscription.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.error.message;
    }
  }
});

export default createPdfSubscriptionSlice.reducer;
