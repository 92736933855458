import React, { useState, useEffect } from "react";
import "./polyAnimation.scss";
import { useTranslation } from "react-i18next";

const PolyAnimation = ({ setIsOpenAnimation }) => {
  const [textIndex, setTextIndex] = useState(0);
  const texts = [
    "SOCIAL",
    "EMAILS",
    "PHONES",
    "ADDRESSES",
    "Preparing the report...",
    "COMPLETE",
  ];
  const { t } = useTranslation();

  useEffect(() => {
    document.body.style.overflow = "hidden"; // Disable scrolling when component mounts
    const interval = setInterval(() => {
      setTextIndex((prevIndex) => {
        if (prevIndex === texts.length - 2) {
          clearInterval(interval); // Stop interval when "Complete" is next
          setIsOpenAnimation(false);
          document.body.style.overflow = "auto"; // Re-enable scrolling when navigating away
          return prevIndex + 1; // Move to "Complete"
        } else {
          return (prevIndex + 1) % texts.length;
        }
      });
    }, 2000);

    return () => {
      clearInterval(interval);
      document.body.style.overflow = "auto"; // Re-enable scrolling when component unmounts
    };
  }, []);

  return (
    <div className="main_root">
      <div className="classUp_mrg">
        <div className="polyanimation_wrapper">
          <div className="animation_title">
            <h2>{t("PR_STEP_3_TITLE_2")}</h2>
            <p>{t("PR_ANIMATION_TEXT")}</p>
          </div>
          <div className="animation_inner_wrap">
            <div className="animation_spin1"></div>
            <div className="animation_spin2"></div>
            <div className="animation_spin3"></div>
            <div className="animation_spin4"></div>
            <div className="animation_spin5"></div>
            <div className="animation_text_wrap">{texts[textIndex]}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PolyAnimation;
