import React from "react";
import { t } from "i18next";
import {
  accordionIcon,
  premiumToggleBar,
  possiblePhotosImg,
  nodataIcon
} from "../../../assets/images";
import { Collapse } from "antd";
import UnlockReport from "../unlockReport/unlockReport";
import { renderValue, toCamelCase } from "../../../utils/commonUtils";

const WorkHistory = ({ isLocked, sectionName, reportDetail }) => {
  const { possible_jobs } = reportDetail?.phoneReport || {};
  const { data, status } = possible_jobs || {};

  return isLocked ? (
    <UnlockReport
      title={t("PR_PREMIUM_SECTION_TITLE_2")}
      image={possiblePhotosImg}
      buttonText={t("PR_UNLOCK_THIS_SECTION").toUpperCase()}
      shouldKnowText={t("PR_UNLOCK_TITLE")}
      knownText={t("PR_WORK_HISTORY_MESSAGE")}
      sectionName={sectionName}
      pricingSectionName={"possible_jobs"}
    />
  ) : (
    <div
      className="ds--unlock-jobs white-bg-wrap"
      id={toCamelCase(t("PR_PREMIUM_SECTION_TITLE_2"))}
    >
      <div className="ds--unlock__title left-content main_title toggle_area">
        <h2>{t("PR_PREMIUM_SECTION_TITLE_2")}</h2>
        <div className="premium_toggle">
          <p to={""}>
            <img src={premiumToggleBar} alt="" />
          </p>
        </div>
      </div>
        {status === "found" && data.length > 0 ? (
          <>
            <div className="ds--accordion-collapse">
              <Collapse
                items={[
                  {
                    key: "1",
                    label: "Learn more",
                    children: <p>2 {t("PR_WORK_HISTORY_TITLE_2")}</p>
                  }
                ]}
                expandIconPosition="end"
                expandIcon={({ isActive }) => (
                  <img
                    src={isActive ? accordionIcon : accordionIcon}
                    width="2em"
                    height="2em"
                    alt=""
                  />
                )}
              />
            </div>
          <div className="ds--job-wrapper">
            {data.map((job, index) => (
              <div key={job._id} className="ds--job-box">
                <h3> Job {index + 1}</h3>
                <p>
                  <b>{t("Company Name")}:</b> {renderValue(job?.company_name)}
                </p>
                <p>
                  <b>{t("Job Title")}:</b> {renderValue(job?.job_title)}
                </p>
                <p>
                  <b>{t("Industry")}:</b> {renderValue(job?.industry)}
                </p>
                <p>
                  <b>{t("Start Date")}:</b> {renderValue(job?.start_date)}
                </p>
              </div>
            ))}
          </div>
          </>
        ) : (
            <p className="data_notfound"><img src={nodataIcon} alt="" /> Record Not Found</p>
        )}
    </div>
  );
};

export default WorkHistory;
