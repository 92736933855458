import React, { useEffect, useState } from "react";
import {
  emailpdfIcon,
  pdfFileIcon,
  printIcon,
} from "../../../../assets/images";
import "./downloadSection.scss";
import { generatePdfBlob, toCamelCase } from "../../../../utils/commonUtils";
import { useTranslation } from "react-i18next";
import Notify from "../../../../../components/common/Notify/notify";
import { pdfSubscriptionStatus } from "../../../../redux/slice/pdfSubscriptionStatusSlice";
import { getPdfUrl } from "../../../../redux/slice/getPdfUrlSlice";
import { connect } from "react-redux";
import Loader from "../../../common/loader/loader";
import ReportMultiactionModal from "../../Modals/downloadReportPayModal/reportMultiactionModal";
import DownloadReportPayModal from "../../Modals/downloadReportPayModal/downloadReportPayModal";
import { uploadPdf } from "../../../../redux/slice/uploadPdfSlice";
import { sendPdfEmail } from "../../../../redux/slice/sendPdfEmailSlice";

const DownloadSection = (props) => {
  const {
    // API and state
    phoneReportDetails,
    callPdfSubscriptionStatus,
    callGetPdfUrl,
    pricingTitleList,
    callSendPdfEmail,
    callUploadPdf
  } = props;

  const { t } = useTranslation();
  const { phoneReport } = phoneReportDetails || {};
  const [isLoading, setIsLoading] = useState(false);
  const [downloadPricingData, setDownloadPricingData] = useState({});

  useEffect(() => {
    if (pricingTitleList) {
      setDownloadPricingData({
        label_1: pricingTitleList.sections["download_one_report"].amount,
        label_2: pricingTitleList.subscriptions["pdf_download"].amount,
        symbol: pricingTitleList.sections["download_one_report"].symbol,
      });
    }
  }, [pricingTitleList]);

  const [downloadReportModal, setDownloadReportModal] = useState(false);
  const [pdfModalType, setPdfModalType] = useState(false);
  const [pdfActionModal, setPdfActionModal] = useState(false);

  const handleDownloadReport = async () => {
    setIsLoading(true);
    const response = await callGetPdfUrl(phoneReport?._id);
    if (response.type === "getPdfUrl/fulfilled") {
      const { url } = response.payload.data || {};
      if (url) {
        window.location.href = url;
      } else {
        const res = await callPdfSubscriptionStatus(phoneReport?._id);
        if (res.type === "pdfSubscriptionStatus/fulfilled") {
          const { subscription, pdf_payment, canceled } = res.payload.data;
          if (!subscription && canceled) {
            setPdfModalType("renew_subscription");
            setPdfActionModal(true);
          } else if (!subscription && !pdf_payment) {
            setDownloadReportModal(true);
          } else if (subscription || pdf_payment) {
            setPdfModalType("download_pdf");
            setPdfActionModal(true);
          }
        } else {
          Notify("error", res.error.message, "");
        }
      }
    } else {
      Notify("error", response.error.message, "");
    }
    setIsLoading(false);
  };

  const handleSendEmail = async () => {
    try {
      setIsLoading(true);
      if (phoneReport?.status === "locked") {
        Notify(
          "error",
          "Please unlock at least one section to Email this report",
          ""
        );
        setIsLoading(false);
        return;
      }
      let res = await callSendPdfEmail(phoneReport?._id);
      if (res.type === "sendPdfEmail/fulfilled") {
        Notify(
          "success",
          res.payload.message || "PDF sended successfully to Email",
          ""
        );
        return;
      }
      if (res.error?.message === "The PDF URL is not available.") {
        const pdfBlob = await generatePdfBlob("main_root");
        const formData = new FormData();
        formData.append("pdfFile", pdfBlob, "report.pdf");
        formData.append("phone_report_id", phoneReport?._id);

        res = await callUploadPdf(formData);
        if (res.type === "uploadPdf/fulfilled") {
          res = await callSendPdfEmail(phoneReport?._id);
          if (res.type === "sendPdfEmail/fulfilled") {
            Notify(
              "success",
              res.payload.message || "PDF sended successfully to Email",
              ""
            );
          } else {
            Notify("error", res.error?.message, "");
          }
        } else {
          Notify("error", res.error?.message, "");
        }
      } else {
        Notify("error", res.error?.message, "");
      }
    } catch (error) {
      Notify("error", error.message, "");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <ReportMultiactionModal
        pdfActionModal={pdfActionModal}
        setPdfActionModal={setPdfActionModal}
        pdfModalType={pdfModalType}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
      />
      <DownloadReportPayModal
        isOpen={downloadReportModal}
        onClose={() => setDownloadReportModal(false)}
        pricingData={downloadPricingData}
      />

      <div
        className="monitor__ds_btn ds--monitor-report white-bg-wrap"
        id={toCamelCase("Download")}
      >
        <div className="monitor-cmp-wrap">
          <img src={pdfFileIcon} alt="" />
          <h3>{t("PR_DOWNLOAD_SECTION_TITLE")}</h3>
          <p>{t("PR_DOWNLOAD_SECTION_DESCRIPTION")}</p>
          <div className="button-container">
            <button className="info-button" onClick={handleDownloadReport}>
              <img src={printIcon} alt="" />
              <span>
                {"  "} {t("PR_PRINT_PDF")}
              </span>
            </button>
            <button className="info-button" onClick={handleSendEmail}>
              <img src={emailpdfIcon} alt="" />
              <span>
                {"  "} {t("PR_EMAIL_PDF")}
              </span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    phoneReportDetails:
      state.getPhoneReportDetails?.getPhoneReportDetailsData?.data,
    pricingTitleList: state.pricingTitleList?.getPhoneReportDetailsData?.data,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    callPdfSubscriptionStatus: (data) => dispatch(pdfSubscriptionStatus(data)),
    callGetPdfUrl: (data) => dispatch(getPdfUrl(data)),
    callUploadPdf: (data) => dispatch(uploadPdf(data)),
    callSendPdfEmail: (data) => dispatch(sendPdfEmail(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DownloadSection);
