import React, { useEffect } from "react";
import "./phoneReportDashboard.scss";
import {
  reportBtnIco,
  reportPhoneIcon,
  tableEllipse,
} from "../../assets/images";
import { Link, useNavigate } from "react-router-dom";
import { MdOutlineSearch } from "react-icons/md";
import { Cookies } from "react-cookie";
import { parsePhoneNumber } from "libphonenumber-js";
import PhoneInput from "react-phone-input-2";
import { formatPhoneNumber } from "../../utils/commonUtils";
import { Spin } from "antd";
import { useTranslation } from "react-i18next";

const PhoneReportDashboardComponent = ({
  countryShortName,
  senderNumber,
  number,
  handleOnNumberChange,
  numberError,
  handleLocateNewNumber,
  numberValidate,
  locationListData,
  loading,
  setFormatedNumber,
}) => {
  const navigate = useNavigate();
  const cookies = new Cookies();
  const lang = cookies.get("lang");
  const { t } = useTranslation();

  useEffect(() => {
    if (senderNumber) {
      let fullNumber = "+" + senderNumber.replace("+", "");
      if (fullNumber.length > 8) {
        const phoneNumber = parsePhoneNumber(fullNumber);
        if (phoneNumber) {
          setFormatedNumber(phoneNumber.formatInternational());
        }
      }
    }
  }, [senderNumber]);



  return (
    <>
      <Spin spinning={loading}>
        
        <div className="main_root">
          <div className="ds--dashboard-wrap">
            <div className="main_container">
              <div className="mt-50 dashboard_search__report white-bg-wrap">
                <div className="dashboard__title">
                  <h2>{t("PR_DASHBOARD_TEXT_1")}</h2>
                </div>
                <div className="location_search">
                  <form>
                    <div className="location_form_field">
                      <PhoneInput
                        inputProps={{
                          name: "phone",
                          required: true,
                          autoFocus: true,
                          className: "form-control",
                        }}
                        country={countryShortName?.toLowerCase()}
                        value={number}
                        placeholder={t("BANNER_INPUT")}
                        onChange={handleOnNumberChange}
                        onEnterKeyPress={handleLocateNewNumber}
                        countryCodeEditable={false}
                        autoFormat={false}
                      />
                      <button
                        type="button"
                        className="click-here m-left"
                        id="btnSubmit"
                        onClick={handleLocateNewNumber}
                      >
                        <MdOutlineSearch
                          style={{
                            fontSize: 26,
                            color: "#1677ff",
                          }}
                          country={countryShortName?.toLowerCase()}
                          value={number}
                          placeholder={t("BANNER_INPUT")}
                          onChange={handleOnNumberChange}
                          onEnterKeyPress={handleLocateNewNumber}
                          countryCodeEditable={false}
                          autoFormat={false}
                        />
                        <span>Search</span>
                      </button>
                    </div>
                    {numberError && (
                      <p
                        style={{
                          marginBottom: "1",
                          marginTop: "0",
                          color: "red",
                        }}
                      >
                        {numberError}
                      </p>
                    )}
                    {numberValidate && (
                      <p
                        style={{
                          marginBottom: "1",
                          marginTop: "0",
                          color: "red",
                        }}
                      >
                        {numberValidate}
                      </p>
                    )}
                  </form>
                </div>
              </div>
              {locationListData &&
                locationListData.data &&
                locationListData.data.length > 0 && (
                  <div className="wpb_report_wrapper white-bg-wrap">
                    <div className="dashboard__title">
                      <h2>{t("PR_DASHBOARD_TITLE")}</h2>
                    </div>
                    {locationListData &&
                      locationListData.data.map((item, index) => {
                        return (
                          <div className="wpb_text_content" key={index}>
                            <div className="wpb_box_wrap">
                              <div className="vc_general_icon">
                                <img src={reportPhoneIcon} alt="" />
                              </div>
                              <div className="vc_general_tel">
                                <p>
                                  <a>{item.sender}</a>
                                  <a>
                                    {formatPhoneNumber(
                                      item.phone_number,
                                      item.phone_number_prefix
                                    )}
                                  </a>
                                </p>
                              </div>
                              <div className="vc_general_text vc_general_date">
                                <strong>{t("PR_SEARCH_DATE")}</strong>
                                <span>03-02-2024</span>
                              </div>
                              <div className="vc_general_text vc_general_status">
                                <strong>{t("PR_STEP_3_STATUS")}</strong>
                                <span
                                  className={`badge status-0 ${item.location_status ? "located" : "pending"
                                    }`}
                                >
                                  {item.location_status
                                    ? t("SMS_STATUS_LOCATED")
                                    : t("SMS_STATUS_PENDING")}
                                  <img src={tableEllipse} alt="" />
                                </span>
                              </div>
                              <div
                                className="vc_general_btn"
                                onClick={() => navigate(
                                  `/${lang}/access-report?D=${item.phone_number_prefix}&n=${item.phone_number}&id=${item._id}`
                                )}
                              >
                                <p>
                                  <Link to={""}>
                                    {t("PR_DASHBOARD_BUTTON")}
                                    <img src={reportBtnIco} alt="" />
                                  </Link>
                                </p>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    }
                  </div >
                )}
            </div >
          </div >
        </div >
      </Spin >
    </>
  );
};

export default PhoneReportDashboardComponent;
