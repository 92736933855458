import sendLocationSlice from '../slice/sendLocationSlice';
import geoLocationSlice from '../slice/geoLocationSlice';
import validLocationKeySlice from '../slice/validLocationKeySlice';
import checkIsPhoneNmberExistsSlice from '../slice/checkIsPhoneNmberExistsSlice';
import createLocationSlice from '../slice/createLocationSlice';
import getPhoneReportDetailsSlice from '../slice/getPhoneReportDetailsSlice';
import createFirstReportSlice from '../slice/createFirstReportSlice';
import reportSubscriptionCreateSlice from '../slice/reportSubscriptionCreateSlice';
import getPricingDetailsBasedOnSectionSlice from '../slice/getPricingDetailsBasedOnSectionSlice';
import getPricingTitleListSlice from '../slice/getPricingTitleListSlice';
import generatePaymentSlice from '../slice/generatePaymentSlice';
import retryPaymentSlice from '../slice/retryPaymentSlice';
import updatePaymentMethodSlice from "../slice/updatePaymentMethodSlice";
import createSubscriptionSlice from "../slice/createSubscriptionSlice";
import createPdfSubscriptionSlice from "../slice/createPdfSubscriptionSlice";
import downloadReportSlice from "../slice/downloadReportSlice";
import subscriptionStatusSlice from "../slice/subscriptionStatusSlice";
import openReportSlice from "../slice/openReportSlice";
import renewSubscriptionSlice from "../slice/renewSubscriptionSlice";
import pdfSubscriptionStatusSlice from "../slice/pdfSubscriptionStatusSlice";
import uploadPdfSlice from "../slice/uploadPdfSlice";
import getPdfUrlSlice from "../slice/getPdfUrlSlice";
import addAdditionalInfoSlice from "../slice/addAdditionalInfoSlice";
import subscriptionListSlice from "../slice/subscriptionListSlice";
import cancelSubscriptionSlice from "../slice/cancelSubscriptionSlice";
import pendingCancelSlice from "../slice/pendingCancelSlice";
import sendPdfEmailSlice from '../slice/sendPdfEmailSlice';

const phoneReportStore = {
  sendLocation: sendLocationSlice,
  geoLocation: geoLocationSlice,
  validLocationKeyData: validLocationKeySlice,
  checkIsPhoneNmberExists: checkIsPhoneNmberExistsSlice,
  createLocation: createLocationSlice,
  getPhoneReportDetails: getPhoneReportDetailsSlice,
  createFirstReport: createFirstReportSlice,
  reportSubscriptionCreate: reportSubscriptionCreateSlice,
  getPricingDetailsBasedOnSection: getPricingDetailsBasedOnSectionSlice,
  pricingTitleList: getPricingTitleListSlice,
  generatePayment: generatePaymentSlice,
  retryPayment: retryPaymentSlice,
  updatedPaymentMethod: updatePaymentMethodSlice,
  createSubscription: createSubscriptionSlice,
  createPdfSubscription: createPdfSubscriptionSlice,
  downloadReport: downloadReportSlice,
  subscriptionStatus: subscriptionStatusSlice,
  openReport: openReportSlice,
  renewSubscription: renewSubscriptionSlice,
  pdfSubscriptionStatus: pdfSubscriptionStatusSlice,
  uploadPdf: uploadPdfSlice,
  getPdfUrl: getPdfUrlSlice,
  additionalInfo: addAdditionalInfoSlice,
  subscriptionList: subscriptionListSlice,
  cancelSubscription: cancelSubscriptionSlice,
  pendingCancel: pendingCancelSlice,
  sendPdfEmail : sendPdfEmailSlice,
};

export default phoneReportStore;
