import React from "react";
import "./noInformationFound.scss";
import { Switch, Tooltip } from "antd";
import {
  OverViewMenu,
  PhoneGps,
  PhoneReportMenu,
} from "../../components/common";
import {
  dsMonitorIcon,
  highLevelGraphImg,
  monitorInfoIcon,
  monitorInfoImg,
  monitorMonthlyImg,
  onOffIco,
  premiumToggleBar,
} from "../../assets/images";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import GeneralPhoneInfo from "../../components/partials/sections/generalInfo/generalPhoneInfo";
import { noInformationFoundDetails } from "../../utils/commonUtils";

const NoInformationFoundComponent = () => {
  const { t } = useTranslation();
  return (
    <div className="main_root">
      <PhoneReportMenu />
      <div className="premium_sec_wrap">
        <div className="main_container">
          <div className="report__overview_menu premium_box_wrap">
            <OverViewMenu />
            <div className="premium-section-width">
              <PhoneGps />
              <GeneralPhoneInfo />
              <div className="unlock_premium_sec white-bg-wrap">
                <div className="ds--unlock__title left-content main_title toggle_area">
                  <h2>{t("PR_REPORT_POPUP_TEXT_4")}</h2>
                </div>
                <div className="ds-unlock-content">
                  <div className="reputation-phn-img">
                    <img src={highLevelGraphImg} alt="" />
                  </div>
                  <div className="reputation-phn-content">
                    <p>{t("PR_PHONE_REPUTATION_SCORE_DESCRIPTION")} </p>
                    <ul className="reputation_listing_area">
                      <li>
                        {t("PR_PREMIUM_REPORT_TEXT_1")}
                        <span>1</span>
                      </li>
                      <li>
                        {t("PR_PREMIUM_REPORT_TEXT_2")}
                        <span>1</span>
                      </li>
                      <li>
                        {t("PR_PREMIUM_REPORT_TEXT_3")}
                        <span>1</span>
                      </li>
                      <li>
                        {t("PR_PREMIUM_REPORT_TEXT_4")}
                        <span>1</span>
                      </li>
                      <li>
                        {t("PR_PREMIUM_REPORT_TEXT_5")}
                        <span>1</span>
                      </li>
                      <li>
                        {t("PR_PREMIUM_REPORT_TEXT_6")}
                        <span>1</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              {noInformationFoundDetails.map((item, index) => (
                <div className="monitor_wrapper monitor-white-bg-wrap">
                  <div className="ds--unlock__title left-content main_title">
                    <h2>{t(item.mainTitle)}</h2>
                  </div>
                  <div className="monitor_monthly_wrap">
                    <div className="monthly_info_wrap">
                      <p>
                        <img src={monitorInfoImg} alt="" />
                        {t(item.title)}&nbsp;
                        <a href="tel:(819) 674-0000">{item?.phoneNumber}</a>
                        &nbsp;{t(item?.text)}
                      </p>
                    </div>
                    <div className="monthly_update_wrap">
                      <h3>
                        {t(item?.subTitle)}{" "}
                        <img src={monitorMonthlyImg} alt="" />
                      </h3>
                      <p>{t(item.description)}</p>
                      <p>
                        {t(item?.subDescription)} {"  "}
                        <a href="tel:(819) 674-0000">{item?.mobileNumber}</a>
                      </p>
                    </div>
                  </div>
                  <div className="monitor__ds_btn ds--monitor-report">
                    <div className="monitor-cmp-wrap">
                      <img src={dsMonitorIcon} alt="" />
                      <h2>
                        {t(item.monitorTitle)}{" "}
                        <Tooltip
                          placement="right"
                          title={
                            "We're always updating our data. Keep Report Monitoring activated to receive automatic alerts every time we find changes in your reports."
                          }
                        >
                          {/* <img src={monitorInfoIcon} alt="" /> */}
                        </Tooltip>
                      </h2>
                      <div className="monitor_switch_btn complaints__btn">
                        <Link to={""} className="complaints__btn">
                          {t(item.monitorStatus)} <Switch defaultChecked />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoInformationFoundComponent;
