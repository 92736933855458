import React from "react";
import "./additionalInfoModal.scss";
import ModalComponent from "../../../common/Modal/modal";
import { t } from "i18next";
const AdditionalInfoModal = ({ isOpen, onClose, closable, className }) => {
  return (
    <ModalComponent
      isOpen={isOpen}
      onClose={onClose}
      closable={closable}
      className={className}
    >
      <div className="mga__attribute_popup">
        <div className="attribute_info_popup">
          <div className="information_popup_text">
            <h2>{t("PR_POPUP_TITLE")}</h2>
          </div>
          <div className="attribute_info_content">
            <div className="info_cmnt_text_wrap">
              <h3>{t("PR_AREA_CODE")}</h3>
              <p>{t("PR_POPUP_DESCRIPTION_1")}</p>
            </div>
            <div className="info_cmnt_text_wrap">
              <h3>{t("PR_PHONE_LINE_TYPE")}</h3>
              <p>{t("PR_POPUP_DESCRIPTION_2")}</p>
            </div>
            <div className="info_cmnt_text_wrap">
              <h3>{t("PR_PHONE_LISTING")}</h3>
              <p>
                {t("PR_POPUP_DESCRIPTION_3")}
              </p>
            </div>
            <div className="info_cmnt_text_wrap">
              <h3>{t("PR_CARRIER_TYPE")}</h3>
              <p>{t("PR_POPUP_SUBTITLE_1")}</p>
            </div>
            <div className="info_cmnt_text_wrap">
              <h3>{t("PR_PHONE_NUMBER_TYPE")}</h3>
              <p>
                {t("PR_POPUP_DESCRIPTION_4")}
              </p>
            </div>
            <div className="info_cmnt_text_wrap">
              <h3>{t("PR_NUMBER_PORTABILITY")}</h3>
              <p>{t("PR_POPUP_SUBTITLE_2")}</p>
            </div>
            <div className="info_cmnt_text_wrap">
              <h3>{t("PR_WIRE_CENTER_LOCATION")}</h3>
              <p>{t("PR_POPUP_SUBTITLE_3")}</p>
            </div>
          </div>
        </div>
      </div>
    </ModalComponent>
  );
};

export default AdditionalInfoModal;
