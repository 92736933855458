import React from "react";
import Lottie from "react-lottie";
import subLoading from "./animationJson/subLoading.json";
import ModalComponent from "../components/common/Modal/modal";

const UnlockAnimation = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: subLoading,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <ModalComponent isOpen={true} onClose={''} closable={false} maskClosable={false}>
      <Lottie options={defaultOptions} height={100} width={100} />
    </ModalComponent>
  );
};

export default UnlockAnimation;
