import React, { useEffect, useState } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import { useLocation } from "react-router-dom";
import PhoneReportHeader from "../phoneReport/components/layout/header";
import PhoneReportFooter from "../phoneReport/components/layout/footer";
import { isUserLoggedIn } from "../phoneReport/utils/commonUtils";
import { Cookies } from "react-cookie";

const AppLayout = ({ children }) => {
  const location = useLocation();
  const phoneReportHeaderLayout = [
    "premium-section",
    "premium-report",
    "access-report",
    "transaction",
    "my-account",
    "dashboard",
    "contact-us",
    "no-information-found",
    "faqs",
    "cookiePolicy",
    "privacyPolicy",
    "termsConditions",
    // "privacy-policy",
    // "terms",
    // "cookie-policy",
  ];
  const phoneReportFooterLayout = [
    "premium-section",
    "premium-report",
    "access-report",
    "my-account",
    "dashboard",
    "contact-us",
    "no-information-found",
    "faqs",
    "cookiePolicy",
    "privacyPolicy",
    "termsConditions",
    // "privacy-policy",
    // "terms",
    // "cookie-policy",
  ];
  const navArray = [
    "dash",
    "settings",
    "l",
    "users",
    "admin",
    "track",
    "sign-up",
    "currency",
    "trace",
    "tracking",
    "email-test",
    "step-tutorial",
    "location-preview-popup",
    "updating",
    "location",
    ...phoneReportHeaderLayout,
    ...phoneReportFooterLayout,
  ];

  const [updatedNavArray, setUpdatedNavArray] = useState(navArray);
  const [updatedPhoneReportHeaderLayout, setUpdatedPhoneReportHeaderLayout] =
    useState(phoneReportHeaderLayout);
  const [updatedPhoneReportFooterLayout, setUpdatedphoneReportFooterLayout] =
    useState(phoneReportFooterLayout);

  const cookies = new Cookies();
  const token = cookies.get("token");

  useEffect(() => {
    if (isUserLoggedIn()) {
      setUpdatedNavArray([
        ...updatedNavArray,
        "cookie-policy",
        "terms",
        "privacy-policy",
      ]);

      setUpdatedPhoneReportHeaderLayout([
        ...updatedPhoneReportHeaderLayout,
        "cookie-policy",
        "terms",
        "privacy-policy",
      ]);

      setUpdatedphoneReportFooterLayout([
        ...updatedPhoneReportFooterLayout,
        "cookie-policy",
        "terms",
        "privacy-policy",
      ]);
    } else {
      setUpdatedNavArray(
        updatedNavArray.filter(
          (item) => !["cookie-policy", "terms", "privacy-policy"].includes(item)
        )
      );

      setUpdatedPhoneReportHeaderLayout(
        updatedPhoneReportHeaderLayout.filter(
          (item) => !["cookie-policy", "terms", "privacy-policy"].includes(item)
        )
      );
      setUpdatedphoneReportFooterLayout(
        updatedPhoneReportFooterLayout.filter(
          (item) => !["cookie-policy", "terms", "privacy-policy"].includes(item)
        )
      );
    }
  }, [token]);

  return (
    <React.Fragment>
      {!updatedNavArray.includes(location.pathname.split("/")[2]) && <Header />}
      {updatedPhoneReportHeaderLayout.includes(
        location.pathname.split("/")[2]
      ) && <PhoneReportHeader />}
      {children}
      {!updatedNavArray.includes(location.pathname.split("/")[2]) && <Footer />}
      {updatedPhoneReportFooterLayout.includes(
        location.pathname.split("/")[2]
      ) && <PhoneReportFooter />}
    </React.Fragment>
  );
};

export default AppLayout;
