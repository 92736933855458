import React from "react";
import "./privacyPolicy.scss";

const PrivacyPolicy = () => {
  return <div className="main_root">
  <div className="Policy-header-section">
      <div className="main_container">
          <h1 className="Policy-title">Privacy Policy</h1>
      </div>
  </div>
  <div className="wpb_info_wrapper">
      <div className="main_container">
          <div className="cms__poolicy_wrapper">
              <div class="web_docs_section mb-2 border-bottom">
                  <h2 className="policy_titleh2">1. Description</h2>
                  <p class="policy_desc mb-1">The acceptance of the tracelo.com privacy policy (hereinafter “Privacy Policy”) is a necessary condition to use our tracelo.com service (hereinafter “Service”).</p>
                  <p class="policy_desc mb-1">This Privacy Policy governs the collection, processing and use of your personal and non-personal information as a user of the Service, from the effective data that appears at the top.</p>
                  <p class="policy_desc mb-1">Tracelo.com does not sell your personal information to third parties and will always ask for your consent before using your data for any purpose different to that which is described in this Privacy Policy.</p>
                  <p class="policy_desc mb-1">Tracelo.com does not sell your personal information to third parties and will always ask for your consent before using your data for any purpose different to that which is described in this Privacy Policy.</p>
                  <p class="policy_desc">For this it adopts the technical and organisational measures required to prevent the loss, misuse, alteration, unauthorised access and theft of the personal data given. Always in accordance with the state of technology, the nature of the data and the risks that they are exposed to.</p>
              </div>
              <div class="web_docs_section mb-2 border-bottom">
                  <h2 className="policy_titleh2">2. Information collected</h2>
                  <p class="policy_desc mb-1">To use the Service the user must create a profile when requesting tracelo.com from a mobile device.The information (personal or not) collected by the Service will vary depending on this.</p>
                  <p class="policy_desc mb-1">The personal and non-personal information collected by the Service comes to us in three ways:</p>
                  <p class="policy_desc mb-3"><b>1) information automatically collected</b></p>
                  <p class="policy_desc mb-3"><b>2) information you voluntarily provide us</b></p>
                  <p class="policy_desc mb-3"><b>3) information provided by third parties.</b></p>
                  <div className="wpb_innerlist_wrap mb-1">
                    <h3 className="policy_titleh3"><b>2.1.</b> Information automatically collected</h3>
                    <p className="policy_desc mb-4"><b>This information includes:</b></p>
                    <ul className="inner_list_wrap">
                      <li>Information collected through cookies or similar mechanisms stored on your device, always with your consent. View our Cookies Policy for more information.</li>
                      <li>The IP from which the connection is made, the type of device used and its characteristics, the version of the operating system, the type of browser, the language, the date, the country, the time of the request, the URL reference or the mobile network used (among others).</li>
                      <li>Service usage data and possible errors identified during its usage.</li>
                      <li>Data on your physical location through different technologies: GPS signals sent by a mobile device or the information on Wi-Fi access points or the nearest mobile phone antennas. In any case, this data is always collected in accordance with your device’s privacy settings.</li>
                      <li>tracelo.com also uses Google Analytics, an analytical service provided by Google Inc. based in the United States with headquarters at 1600 Amphitheatre Parkway, Mountain View, California 94043. To provide these services, cookies are used to collect information, including the user’s IP address, which shall be transferred, processed and stored by Google in the terms set out on the website www.google.com. Including the possible transfer of this information to third parties for legal requirements or when these third parties process the information on behalf of Google.</li>
                      <li>In any case, you can disable Google Analytics cookies Link.</li>
                    </ul>
                  </div>
                  <div className="wpb_innerlist_wrap mb-1">
                    <h3 className="policy_titleh3"><b>2.2.</b> Information voluntarily provided by you</h3>
                    <p className="policy_desc mb-4"><b>This information includes:</b></p>
                    <ul className="inner_list_wrap">
                      <li>Information required by tracelo.com in creating your user profile in the Service, indicating at least the information marked with an asterisk on certain fields: email address, telephone, name and surname and payment details.</li>
                      <li>You must also add the mobile phone number you want to track. You accept that you have obtained the number you want to track lawfully and with the owner’s prior consent.</li>
                      <li>The User shall be responsible for protecting the password. In this sense the User shall assume any damage that may arise from its undue use or its cession to third parties.</li>
                      <li>The information, personal or not, that may be contained in messages sent through the contact channels or opinion established in the Service, for example, your name, alias or email address.</li>
                      <li>The email address that you provide to register for the newsletter. If you use the email address of a third party, you recognise having their consent for it.</li>
                    </ul>
                  </div>
                  <div className="wpb_innerlist_wrap mb-1">
                    <h3 className="policy_titleh3"><b>2.3.</b> Information provided by third parties</h3>
                    <p className="policy_desc mb-4"><b>This information includes:</b></p>
                    <ul className="inner_list_wrap">
                      <li>Information provided by payment systems or credit card processors, such as the time of purchase or its amount.</li>
                      <li>Information provided by courier companies such as the delivery time, place and date.</li>
                    </ul>
                  </div>
                  <div className="wpb_innerlist_wrap">
                    <h3 className="policy_titleh3"><b>2.4.</b> The third party that consents to the tracking, shall transfer automated information on their device such as that indicated in section 2.1, their telephone number and their specific geoposition for the time in which they have consented to disclose it.</h3>
                    <p className="policy_desc mb-4"><b>In no case shall the tracking go beyond that specific moment in which it has been accepted.</b></p>
                    <p className="policy_desc">The third party that does not consent to the tracking, shall transfer automated information on your device to tracelo.com such as that indicated in section 2.1, your telephone and the name with which it had been associated.</p>
                    <p className="policy_desc">You may exercise the rights to access, rectification, cancellation and opposition of your personal data at any time by sending an email to: <b><a href="mailto:support@tracelo.com">support@tracelo.com.</a></b></p>
                    <p className="policy_desc">In both cases, identify yourself with your name and surname, as well as a copy of your national ID document.</p>
                  </div>
              </div>
              <div class="web_docs_section mb-2 border-bottom">
                  <h2 className="policy_titleh2">3. Rights and purposes</h2>
                  <p class="policy_desc">We inform you that the completion of the forms is voluntary. Meanwhile, if you do not complete the required fields (marked with an asterisk) the use of the Service shall not be possible or shall be limited</p>
                  <p class="policy_desc">The personal data that you provide us shall be incorporated and processed in the files owned by TRACELO, in order to be able to attend your requests, provide the service requested and to keep you informed on matters related to the company’s activity and its services.</p>
                  <p class="policy_desc mb-1">You may exercise the rights to access, rectification, cancellation and opposition of your personal data at any time by sending an email to: <b><a href="mailtosupport@tracelo.com">support@tracelo.com.</a></b></p>
                  <p class="policy_desc">In both cases, identify yourself with your name and surname, as well as a copy of your national ID document.</p>
                  <p class="policy_desc">tracelo.com agrees to respect the confidentiality of the data collected in the file, to use them according to their purpose, to comply with its obligation of custody, and to adopt the measures required to prevent the alteration, loss, processing or unauthorised access to the data, in accordance with the applicable regulations.</p>
              </div>
              <div class="web_docs_section mb-2 border-bottom">
                  <h2 className="policy_titleh2">4. Age</h2>
                  <p class="policy_desc mb-2">With regards to the use of the Service, you state that you are of legal age and that you have the legal capacity necessary to be bound by this agreement and to use the site in accordance with its terms and conditions, which you fully understand and acknowledge.</p>
                  <p class="policy_desc">You state that all the information you provide to access the Service, before and during its usage, is true, complete and accurate.</p>
              </div>
              <div class="web_docs_section mb-2 border-bottom">
                  <h2 className="policy_titleh2">5. Use of the data</h2>
                  <p class="policy_desc mb-2"><b>tracelo.com shall use the data collected to:</b></p>
                  <div className="wpb_innerlist_wrap mb-2">
                    <ul className="inner_list_wrap">
                      <li>Administer, provide and update the Service.</li>
                      <li>Carry out the requested tracking, provided that the recipient has consented</li>
                      <li>Answer the questions you have.</li>
                      <li>Process the payments you make.</li>
                      <li>Send you via email, if you have authorised it or according to the applicable regulations, our informative newsletter and/or offers from our business partners</li>
                    </ul>
                  </div>
                  <p className="policy_desc mb-4">You may unsubscribe from this email, from your user account or by contacting us at support@tracelo.com. However, you may not unsubscribe to certain correspondence from us, such as messages concerning the security of your data or the terms and conditions of the Service.</p>
                  <div className="wpb_innerlist_wrap mb-2">
                    <ul className="inner_list_wrap">
                      <li>Plan and develop commercial activities of the Service, such as for example, advertising, directly or through third party collaborators.</li>
                      <li>Maintain the security of the Service, investigate unlawful activities, comply with our terms and conditions and help national law enforcements and security forces for the purposes of their investigations.</li>
                    </ul>
                  </div>
                  <p className="policy_desc">Similarly, tracelo.com may use the personal and non-personal information of users in the form of aggregated and anonymous data to show them to third parties. It may also share statistics and demographic information on users and their usage of the Service with third parties. None of this shall enable these third parties to personally identify you.</p>
              </div>
              <div class="web_docs_section mb-2 border-bottom">
                  <h2 className="policy_titleh2">6. Delete account</h2>
                  <p class="policy_desc">You may delete your account by requesting it to <b><a href="mailto:support@tracelo.com">support@tracelo.com.</a></b></p>
                  <p class="policy_desc">If you decide to delete your account, all your data shall be deleted, including back-up copies, within a maximum period of 7 days.</p>
              </div>
              <div class="web_docs_section mb-2 border-bottom">
                  <h2 class="policy_titleh2">7. Data shared with third parties</h2>
                  <p class="policy_desc">There are third parties that manage part of the Service.</p>
                  <p class="policy_desc">tracelo.com requires them to comply with this Privacy Policy as applicable, in addition to having their own. However, tracelo.com shall not be responsible for the compliance of that policy.</p>
                  <p class="policy_desc mb-2">Under certain circumstances, tracelo.com may share, use or preserve with third parties some of the personal information collected:</p>
                  <p class="policy_desc"><b>To provide the Service:</b></p>
                  <p class="policy_desc">The services suppliers that provide functions in our name, such as for example the processing of credit cards, the tracking process, web hosting, business analytics, customer service or marketing.</p>
                  <p class="policy_desc mb-2">These services suppliers may collect and have access to the information necessary to perform their functions, but they are not allowed to share or use the information for any other purpose.</p>
                  <p class="policy_desc mb-4"><b>To cooperate with the relevant authorities:</b></p>
                  <p class="policy_desc mb-2">If we believe that it is reasonably necessary to satisfy any law, legal process or legitimate interest in any part of the world. In any case, we shall only provide the information strictly required.</p>
                  <p class="policy_desc mb-2">If we believe that this action is appropriate to comply with the terms and conditions of tracelo.com.</p>
                  <p class="policy_desc">If it is necessary to identify or prevent fraud or matters related to the security of tracelo.com, its providers and users.</p>
              </div>
              <div class="web_docs_section mb-2 border-bottom">
                  <h2 class="policy_titleh2">8. Security measures</h2>
                  <p class="policy_desc mb-2">Tracelo.com adopts all the necessary technical and organisational measures to protect the security and integrity of the personal and non-personal information collected. Both against unauthorised access and its alteration, loss or accidental destruction.</p>
                  <p class="policy_desc mb-2">The data that you send through the Service is protected by electronic security techniques online such as the SSL protocol. Similarly, the information supplied and stored on the tracelo.com databases is also protected by security systems that impede access to unauthorised third parties.</p>
                  <p class="policy_desc mb-2">Tracelo.com makes its best efforts to have the most up-to-date systems for the effectiveness of these security mechanisms. Also, tracelo.com stores the personal and non-personal information for as long as the regulations allow and require. The information that it does not need is regularly destroyed.</p>
                  <p class="policy_desc">However, tracelo.com cannot guarantee the absolute security of the information collected, therefore, you must collaborate and use common sense at all times on the information shared.</p>
              </div>
              <div class="web_docs_section mb-2 border-bottom">
                  <h2 class="policy_titleh2">9. Changes in the Privacy Policy</h2>
                  <p class="policy_desc mb-2">We may update this Privacy Policy in the future.</p>
                  <p class="policy_desc">We shall inform you of its changes by sending a notification to the email address provided and/or by placing a notification in a visible place on our website.</p>
              </div>
              <div class="web_docs_section">
                  <h2 class="policy_titleh2">10. Contact</h2>
                  <p class="policy_desc mb-2">If you have any questions about this Privacy Policy, please contact us at:</p>
                  <p class="policy_desc">E-mail: <a href="mailto:support@tracelo.com">support@tracelo.com</a></p>
              </div>
          </div>
      </div>
  </div>
</div>;
};

export default PrivacyPolicy;
