import React from "react";
import { t } from "i18next";
import {
  copyPastIcon,
  premiumToggleBar,
  phoneTypeIcon,
  otherPhoneNumbersImg,
  nodataIcon
} from "../../../assets/images";
import UnlockReport from "../unlockReport/unlockReport";
import { renderValue, toCamelCase } from "../../../utils/commonUtils";

const OtherPhoneNumber = ({ isLocked, sectionName, reportDetail }) => {

  const { other_phone_numbers } = reportDetail?.phoneReport || {};
  const { data, status } = other_phone_numbers || {};

  return isLocked ? (
    <UnlockReport
      title={t("PR_REPORT_POPUP_TEXT_6")}
      image={otherPhoneNumbersImg}
      buttonText={t("PR_UNLOCK_THIS_SECTION").toUpperCase()}
      shouldKnowText={t("PR_UNLOCK_TITLE")}
      knownText={t("PR_OTHER_NUMBER_MESSAGE")}
      sectionName={sectionName}
      pricingSectionName={"other_phone_numbers"}
    />
  ) : (
    <div
      className="unlock_phone_type white-bg-wrap"
      id={toCamelCase(t("PR_REPORT_POPUP_TEXT_6"))}
    >
      <div className="ds--unlock__title left-content main_title toggle_area">
        <h2>{t("PR_REPORT_POPUP_TEXT_6")}</h2>
        <div className="premium_toggle">
          <p to={""}>
            <img src={premiumToggleBar} alt="" />
          </p>
        </div>
      </div>
        {status === "found" && data.length > 0 ? (
          <>
            <div className="unlock_title unlock-description">
              <p>{t("PR_OTHER_MESSAGE")}</p>
            </div>
            {data.map((item, index) => {
              return (
                <div className="ds--secondary-head" key={index}>
                  <div className="ds--phone-image">
                    <img src={phoneTypeIcon} alt="" />
                  </div>
                  <div class="ds--phone-copy">
                    <p
                      to={""}
                      onClick={() =>
                        navigator.clipboard.writeText(item.phone_number)
                      }
                    >
                      <img src={copyPastIcon} alt="" />
                    </p>
                  </div>
                  <div className="ds--phone-wrap">
                    <h3>
                      <a href="tel:+1 (819) 674-0000">{item.phone_number}</a>
                    </h3>
                    <div className="ds--phone-content">
                      <p>{t("PR_STEP_3_PHONE")}</p>
                      <span>{renderValue(item.type)}</span>
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        ) : (
          <p className="data_notfound"><img src={nodataIcon} alt="" /> Record Not Found</p>
        )}
    </div>
  );
};

export default OtherPhoneNumber;
