import React, { useState } from "react";
import ModalComponent from "../../../common/Modal/modal";
import "./locatingModal.scss";
import PhoneInput from "react-phone-input-2";
import { Form } from "antd";
import { useTranslation } from "react-i18next";
import { useAppContext } from "../../../../pages/previewPopup/previewPopupContext";
import { isValidPhoneNumber } from "libphonenumber-js";
import Notify from "../../../../../components/common/Notify/notify";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Cookies } from "react-cookie";
import { connect } from "react-redux";
import { sendLocation } from "../../../../redux/slice";
import { createFirstReport } from "../../../../redux/slice/createFirstReportSlice";

const LocatingModal = ({
  className,
  isOpen,
  onClose,
  closable,
  formatedNumber,
  countryShortName,
  setIsLoading,
  sendLocation,
  fetchPhoneReportDetails,
  setIsOpen,
  callSendLocation,
  callCreateFirstReport,
  setPhoneReportModalShow,
}) => {
  const {
    receiverNumber,
    setReceiverNumber,
    message,
    setMessage,
    receiverCountry,
    setReceiverCountry,
    countryCode,
    countryCodeinput,
    messageError,
    setMessageError,
    number,
    setNumber,
    receiverCountryCode,
    setReceiverCountryCode,
    setPhoneReportId,
  } = useAppContext();
  const [popNumberError, setPopNumberError] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [receiverError, setReceiverError] = useState("");
  const [receiverDialCode, setReceiverDialCode] = useState(0);

  const location = useLocation();
  const { t } = useTranslation();
  const cookies = new Cookies();
  const lang = cookies.get("lang");
  const [searchparams] = useSearchParams();
  const searchNumber = Object.fromEntries([...searchparams]);
  const modalClassName = `locating_popup__wrap ${className}`;
  const navigate = useNavigate();

  const handleOnReceiverNumberChange = (value, country) => {
    setReceiverDialCode(country.dialCode);
    setReceiverNumber(value);
    setPopNumberError("");
    setReceiverCountry(country.countryCode.toUpperCase());
    setReceiverCountryCode(`+${country.dialCode}`);
    setReceiverError("");
  };
  const handleChangeMessage = (event) => {
    setMessage(event.target.value);
    if (event.target.value === "") {
      setMessageError(t("SMS_MESSAGE_ERROR"));
    } else {
      setMessageError("");
    }
  };
  const closeLocatingModal = () => {
    let queryString = location.search;
    queryString = queryString.replace(/(\?Edit=true|%3FEdit%3Dtrue)/g, "");
    queryString = queryString.replace(/&&/g, "&").replace(/&$/, "");
    const newUrl = location.pathname + (queryString ? queryString : "");
    setMessage(t("SMS_DEFAULT_TEXT"));
    navigate(newUrl);
    setIsOpen(false);
  };
  // Send message Function
  const handleSendMessage = async () => {
    if (receiverNumber) {
      if (
        isValidPhoneNumber(
          receiverNumber,
          `${
            receiverCountry
              ? receiverCountry.toString().toUpperCase()
              : countryCodeinput.toUpperCase()
          }`
        )
      ) {
        if (!messageError) {
          const sender_mobile_number = `+${number}`
            .toString()
            .replace(countryCode, "");
          const receiver_mobile_number = `+${receiverNumber}`
            .toString()
            .replace(receiverCountryCode, "");

          let result;
          let data = {
            receiver_country_code:
              receiverCountryCode === 1 ? "+1" : receiverCountryCode,
            receiver_mobile_number: receiver_mobile_number,
            message: message,
          };
          if (searchNumber.id && searchNumber.id !== "undefined") {
            const phoneReportId = searchNumber.id.replace("?Edit=true", "");
            data = { ...data, phone_report_id: phoneReportId };
            result = await callSendLocation(data);
          } else {
            data = {
              ...data,
              sender_country_code:
                countryCode === 1 ? "+1" : countryCode.replace(" ", ""),
              sender_mobile_number: sender_mobile_number,
            };
            result = await callCreateFirstReport(data);
          }
          setIsButtonDisabled(true);
          fetchPhoneReportDetails(searchNumber.id);
          if (
            result.type === "sendLocation/fulfilled" ||
            result.type === "createFirstReport/fulfilled"
          ) {
            Notify("success", t("NOTIFY_SEND_LOCATION"), "");
            closeLocatingModal();
            window.onpopstate = null;
            document.body.style.overflow = "unset";
            setIsButtonDisabled(false);
            setReceiverNumber(receiverCountryCode);
            setNumber(countryCode);
            setPhoneReportModalShow(true);
            if (result.type === "createFirstReport/fulfilled") {
              const { payload } = result;
              const { data } = payload;
              const { _id } = data || {};
              setPhoneReportId(_id);
              const reportId = result.id;
              const currentUrl = window.location.href;
              const newUrl = currentUrl.includes("?")
                ? `${currentUrl}&id=${_id}`
                : `${currentUrl}?id=${reportId}`;

              window.history.pushState({ path: newUrl }, "", newUrl);
              fetchPhoneReportDetails(_id);
            }
          } else if (
            result.type === "sendLocation/rejected" ||
            result.type === "createFirstReport/rejected"
          ) {
            Notify("error", result.error.message, "");
            closeLocatingModal();
            document.body.style.overflow = "unset";
            // setIsLoading(false);
            setIsButtonDisabled(false);
          }
        }
      } else {
        setPopNumberError(t("ERROR_VALID_NUMBER_DASHBOARD"));
      }
    } else {
      setPopNumberError(t("ERROR_ENTER_NUMBER_DASHBOARD"));
    }
  };
  const handlePreviewNavigate = () => {
    if (!receiverNumber) {
      setPopNumberError(t("ERROR_ENTER_NUMBER_DASHBOARD"));
      return;
    }
    const countryCode = receiverCountry?.toString().toUpperCase() || countryCodeinput.toUpperCase();
    const isValidNumber = isValidPhoneNumber(receiverNumber, countryCode);
    if (!isValidNumber) {
      setPopNumberError(t("ERROR_VALID_NUMBER_DASHBOARD"));
      return;
    }
    const trimmedNumber = receiverNumber.replace(receiverDialCode, "").trim();
    if (trimmedNumber === "") {
      setReceiverError("valid phone number required");
      return;
    }
    if (!location.search.includes("Edit=true")) {
      cookies.set(
        "locatingmodalurl",
        `${location.pathname}${location.search}?Edit=true`
      );
    }
    const navigatePath = `/${lang}/location-preview-popup${searchNumber.id ? `?id=${searchNumber.id}` : ''}`;
    navigate(navigatePath, { state: { receiverNumber, message } });
  };

  return (
    <ModalComponent
      isOpen={isOpen}
      onClose={onClose}
      closable={closable}
      className={modalClassName}
    >
      <div className="login-modal-section send_message_Modal">
        <div
          className="login-modal-title"
          style={{ fontSize: 20, display: "flex", flexDirection: "column" }}
        >
          <span className="span">{t("LOCATING")}</span>
          <p>{formatedNumber}</p>
        </div>
        <div className="wrap-top-modal">
          <div className="body-dash-modal">
            <div className="dashboard-find-number dashboard-box">
              <label className="mb-1">
                <span className="dot"></span>
                <span>{t("RECEIVE_NUMBER_TEXT")}</span>
              </label>
              <div className="dashboard-locate">
                <PhoneInput
                  country={countryShortName?.toLowerCase()}
                  value={receiverNumber}
                  onChange={handleOnReceiverNumberChange}
                  countryCodeEditable={false}
                  inputProps={{
                    name: "phone",
                    required: true,
                    autoFocus: true,
                    className: "form-control",
                  }}
                  autoFormat={false}
                />
                {popNumberError && (
                  <p style={{ margin: " 0", color: "red" }}>{popNumberError}</p>
                )}
                {receiverError && (
                  <p style={{ margin: " 0", color: "red" }}>{receiverError}</p>
                )}
              </div>
            </div>
            <Form>
              <div className="dashboard-message dashboard-box">
                <label className="mb-1">
                  <span className="dot"></span>
                  <span>{t("MESSAGE")}</span>
                </label>
                <div className="dashboard-locate">
                  <textarea
                    defaultValue={t("SMS_DEFAULT_TEXT")}
                    placeholder={t("SMS_DEFAULT_TEXT")}
                    onChange={handleChangeMessage}
                    value={message}
                  />
                  {messageError && (
                    <p style={{ color: "red", margin: 0 }}>{messageError}</p>
                  )}
                </div>
              </div>
              <div className="submit-btn-modal phone-report-send-message">
                <button
                  onClick={handleSendMessage}
                  className="hl_cta_wrap"
                  type="submit"
                  disabled={isButtonDisabled || sendLocation?.isLoading} // Disable button if isButtonDisabled or API call in progress
                >
                  {t("SENT_MESSAGE_CONTINUE")}
                </button>
                <button
                  onClick={handlePreviewNavigate}
                  className="hl_cta_wrap"
                  type="submit"
                  disabled={sendLocation?.isLoading}
                >
                  {t("PR_PREVIEW")}
                </button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </ModalComponent>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    callSendLocation: (data) => dispatch(sendLocation(data)),
    callCreateFirstReport: (data) => dispatch(createFirstReport(data)),
  };
};

export default connect(null, mapDispatchToProps)(LocatingModal);
