import React, { useEffect, useState } from "react";
import PhoneReportDashboardComponent from "./phoneReportDashboard";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { isValidPhoneNumber } from "libphonenumber-js";
import Notify from "../../../components/common/Notify/notify";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Cookies } from "react-cookie";
import { useAppContext } from "../previewPopup/previewPopupContext";
import {
  locationList,
  sendLocation,
  checkIsPhoneNmberExists,
  createLocation,
} from "../../redux/slice";

const PhoneReportDashboard = ({
  callsendLocation,
  getLocationList,
  locationListData,
  sendDetailsData,
  callCheckIsPhoneNumberExists,
  callCreateLocation
}) => {
  const {t} = useTranslation();
  const [searchparams] = useSearchParams();
  const searchNumber = Object.fromEntries([...searchparams]);
  const navigate = useNavigate();
  const cookies = new Cookies();
  const lang = cookies.get("lang");
  const {
    receiverNumber,
    setReceiverNumber,
    message,
    setMessage,
    setReceiverCountry,
    countryCode,
    setCountryCode,
    countryCodeinput,
    setCountryCodeinput,
    messageError,
    setMessageError,
    number,
    setNumber,
    receiverCountryCode,
    setReceiverCountryCode,
    isEditEnable,
    setEditEnable,
    formatedNumber,
    setFormatedNumber
  } = useAppContext();

  const [countryShortName, setCountryShortName] = useState("");
  const [popNumberError, setPopNumberError] = useState("");
  const [loading, isLoading] = useState(false);
  const [openLocateModal, setOpenLocateModal] = useState(false);
  const [resultError, setResultError] = useState("");
  const [numberError, setNumberError] = useState("");
  const [numberValidate, setNumberValidate] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [receiverError, setReceiverError] = useState("");
  const [receiverDialCode, setReceiverDialCode] = useState(0);

  useEffect(() => {
    setNumber("");
    if (isEditEnable) handleLocateNewNumber();
    window.onpopstate = null;
  }, []);

  useEffect(() => {
    const data = sendDetailsData?.getSendDetailsData?.data;
    if (data) {
      setCountryShortName(data?.country);
      setReceiverDialCode(data?.country_calling_code.replace("+", ""));
    }
  }, [sendDetailsData]);

  useEffect(() => {
    if (searchNumber.D && searchNumber.n) {
      setReceiverCountryCode("+" + searchNumber.D);
      if (!receiverNumber) {
        setReceiverNumber(searchNumber.D);
      }
      setNumber(searchNumber.D + searchNumber.n);
      setCountryCode("+" + searchNumber.D);
    }
  }, [searchNumber.D, searchNumber.n]);

  useEffect(() => {
    getLocationList();
  }, []);

  const handleOnReceiverNumberChange = (value, country) => {
    setReceiverDialCode(country.dialCode);
    setReceiverNumber(value);
    setPopNumberError("");
    setReceiverCountry(country.countryCode.toUpperCase());
    setReceiverCountryCode(`+${country.dialCode}`);
    setReceiverError("");
  };

  const handleChangeMessage = (event) => {
    setMessage(event.target.value);
    setMessageError(event.target.value === "" ? t("SMS_MESSAGE_ERROR") : "");
  };

  const openLocatingModal = () => {
    setOpenLocateModal(true);
  };

  const closeLocatingModal = () => {
    setOpenLocateModal(false);
    setEditEnable(false);
    setMessage(t("SMS_DEFAULT_TEXT"));
    setReceiverNumber("");
    setFormatedNumber("");
  };

  const handleSendMessage = async (isCreateLocation) => {
    const sender_mobile_number = `+${number}`
      .toString()
      .replace(countryCode, "");
    if (number) {
      if (
        isValidPhoneNumber(
          sender_mobile_number,
          `${
            countryShortName
              ? countryShortName.toString().toUpperCase()
              : countryCodeinput.toUpperCase()
          }`
        )
      ) {
        if (!messageError) {
          const data = {
            sender_country_code: countryCode === 1 ? "+1" : countryCode,
            sender_mobile_number: sender_mobile_number
          };
          isLoading(true);
          setIsButtonDisabled(true);
          let result;
          if (isCreateLocation) {
            isLoading(true);
            result = await callCreateLocation(data);
            const {payload} = result || {};
            const {data: responseData} = payload;
            const {_id} = responseData;
            isLoading(false);
            navigate(
              `/${lang}/access-report?D=${
                countryCode === 1 ? "+1" : countryCode
              }&n=${`+${number}`.toString().replace(countryCode, "")}&id=${_id}`
            );

            Notify("success", t("NOTIFY_SEND_LOCATION"), "");
            // closeLocatingModal();
            // document.body.style.overflow = "unset";
            // getLocationList();
            // setIsButtonDisabled(false);
            // isLoading(false);
            setReceiverNumber(receiverCountryCode);
            setNumber(countryCode);
          } else {
            isLoading(true);
            result = await callsendLocation(data);
            isLoading(false);
          }
          if (result.type === "sendLocation/fulfilled") {
            Notify("success", t("NOTIFY_SEND_LOCATION"), "");
            closeLocatingModal();
            document.body.style.overflow = "unset";
            getLocationList();
            setIsButtonDisabled(false);
            isLoading(false);
            setReceiverNumber(receiverCountryCode);
            setNumber(countryCode);
            navigate(`/${lang}/access-report`);
          } else if (result.type === "sendLocation/rejected") {
            setResultError(result.error.message);
            closeLocatingModal();
            document.body.style.overflow = "unset";
            isLoading(false);
            setIsButtonDisabled(false);
          }
        }
      } else {
        setPopNumberError(t("ERROR_VALID_NUMBER_DASHBOARD"));
      }
    } else {
      setPopNumberError(t("ERROR_ENTER_NUMBER_DASHBOARD"));
    }
  };

  const handlePreviewNavigate = () => {
    if (
      receiverNumber.replace(receiverDialCode, "") !== "" &&
      receiverNumber.replace(receiverDialCode, "") !== " "
    ) {
      navigate(`/${lang}/location-preview-popup`, {
        state: {receiverNumber, message}
      });
    } else {
      setReceiverError("valid phone number required");
    }
  };

  const handleOnNumberChange = (value, country) => {
    setCountryCode(`+${country.dialCode}`);
    setNumber(value);
    setCountryShortName(country.countryCode.toUpperCase());
    setNumberError("");
  };

  const handleLocateNewNumber = async () => {
    if (!number && number !== 0) {
      setNumberError(t("ERROR_ENTER_NUMBER_DASHBOARD"));
      return;
    }
    setNumberValidate("");
    const phoneNumberPrefix = countryCode === 1 ? "+1" : countryCode;
    const phoneNumber = `+${number}`.toString().replace(phoneNumberPrefix, "");
    const data = {
      phone_number_prefix: phoneNumberPrefix,
      phone_number: phoneNumber
    };

    isLoading(true);
    const result = await callCheckIsPhoneNumberExists(data);
    if (result.type === "checkIsPhoneNmberExists/fulfilled") {
      const {success, info} = result.payload.data || {};
      const {_id} = info || {};
      if (!success && number) {
        handleSendMessage(!success);
      } else {
        if (number && number !== 0) {
          if (
            isValidPhoneNumber(
              number,
              `${
                countryShortName
                  ? countryShortName.toUpperCase()
                  : countryCodeinput.toUpperCase()
              }`
            )
          ) {
            navigate(
              `/${lang}/access-report?D=${phoneNumberPrefix}&n=${phoneNumber}&id=${_id}`
            );
            setNumberError("");
          } else {
            setNumberValidate(t("ERROR_VALID_NUMBER_DASHBOARD"));
          }
        } else {
          setNumberError(t("ERROR_ENTER_NUMBER_DASHBOARD"));
        }
      }
    } else {
      Notify("error", result.error.message, "");
    }
    isLoading(false);
  };

  return (
    <PhoneReportDashboardComponent
      countryShortName={countryShortName}
      senderNumber={number}
      loading={loading}
      number={number}
      numberError={numberError}
      handleLocateNewNumber={handleLocateNewNumber}
      handleOnNumberChange={handleOnNumberChange}
      locationListData={locationListData.locationListData}
      numberValidate={numberValidate}
      setFormatedNumber={setFormatedNumber}
      ///
      receiverNumber={receiverNumber}
      handleOnReceiverNumberChange={handleOnReceiverNumberChange}
      popNumberError={popNumberError}
      handleChangeMessage={handleChangeMessage}
      messageError={messageError}
      handleSendMessage={handleSendMessage}
      sendLocation={sendLocation}
      locatingModalOpen={openLocateModal}
      setCountryCodeinput={setCountryCodeinput}
      resultError={resultError}
      openLocatingModal={openLocatingModal}
      closeLocatingModal={closeLocatingModal}
      handlePreviewNavigate={handlePreviewNavigate}
      isButtonDisabled={isButtonDisabled}
      receiverError={receiverError}
      formatedNumber={formatedNumber}
      message={message}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    sendLocation: state.sendLocation,
    locationListData: state.locationList,
    sendDetailsData: state.sendDetailsData,
    checkIsPhoneNmberExists: state.checkIsPhoneNmberExists,
    checkIsPhoneNmberExistsData: state.checkIsPhoneNmberExistsData,
    callCreateLocation: state.callCreateLocation
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    callsendLocation: (data) => dispatch(sendLocation(data)),
    getLocationList: () => dispatch(locationList()),
    callCheckIsPhoneNumberExists: (data) =>
      dispatch(checkIsPhoneNmberExists(data)),
    callCreateLocation: (data) => dispatch(createLocation(data))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PhoneReportDashboard);
