import React, { createContext, useContext, useState } from "react";
import { useTranslation } from "react-i18next";

const AppContext = createContext();

export const useAppContext = () => useContext(AppContext);

export const PreviewPopupProvider = ({ children }) => {
  const { t } = useTranslation();
  const [receiverNumber, setReceiverNumber] = useState("");
  const [message, setMessage] = useState(t("SMS_DEFAULT_TEXT"));
  const [countryCode, setCountryCode] = useState(+1);
  const [receiverCountry, setReceiverCountry] = useState(countryCode);
  const [countryCodeinput, setCountryCodeinput] = useState("ca");
  const [messageError, setMessageError] = useState("");
  const [number, setNumber] = useState("");
  const [receiverCountryCode, setReceiverCountryCode] = useState(+1);
  const [isSucessPreviewSend, setSucessPreviewSend] = useState(false);
  const [isEditEnable, setEditEnable] = useState(false);
  const [formatedNumber, setFormatedNumber] = useState(0);
  const [phoneReportId, setPhoneReportId] = useState(0);

  return (
    <AppContext.Provider
      value={{
        receiverNumber,
        setReceiverNumber,
        message,
        setMessage,
        receiverCountry,
        setReceiverCountry,
        countryCode,
        setCountryCode,
        countryCodeinput,
        setCountryCodeinput,
        messageError,
        setMessageError,
        number,
        setNumber,
        receiverCountryCode,
        setReceiverCountryCode,
        isSucessPreviewSend,
        setSucessPreviewSend,
        isEditEnable,
        setEditEnable,
        formatedNumber,
        setFormatedNumber,
        phoneReportId,
        setPhoneReportId,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
