import React from 'react'
import PreviewPopupComponent from './previewPopup'
const PreviewPopup = () => {
  return (
    <div>
      <PreviewPopupComponent />
    </div>
  )
}

export default PreviewPopup
