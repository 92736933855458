import React, { useEffect, useState } from "react";
import { Switch } from "antd";
import { t } from "i18next";
import { parsePhoneNumber } from "libphonenumber-js";
import "./premiumReport.scss";
import { renderValue, toCamelCase } from "../../utils/commonUtils";
import { UnlockFullReport } from "../../components/partials";
import OverViewMenu from "../../components/common/overViewMenu";
import { PhoneGps, PhoneReportMenu } from "../../components/common";
import { neighborSpooFingIcon, dsMonitorIcon } from "../../assets/images";

import {
  AddressHistory,
  DataLeakChecker,
  DownloadSection,
  EmailAddress,
  GeneralPhoneInfo,
  NameAndAncestry,
  OtherInformation,
  OtherPhoneNumber,
  PhoneApproximateLocation,
  PhoneOwnerInformation,
  PhoneReputation,
  PossibleEducation,
  PossiblePhotos,
  Relationship,
  SearchVolume,
  SocialProfile,
  WorkHistory,
} from "../../components/partials/sections";

const PremiumPageComponent = (props) => {
  const {
    phoneReportDetails,
    locatingModalOpen,
    countryShortName,
    sendLocation,
    senderNumber,
    closeLocatingModal,
    setOpenLocateModal,
    fetchPhoneReportDetails,
    locationListData,
    setPhoneReportModalShow,
  } = props;

  const { nuisance_call_scan_detection, potential_neighbors_spoofing } =
    phoneReportDetails?.phoneReport || {};

  const [formateNumber, setFormateNumber] = useState(0);

  useEffect(() => {
    if (senderNumber) {
      let fullNumber = "+" + senderNumber.replace("+", "");
      if (fullNumber.length > 8) {
        const phoneNumber = parsePhoneNumber(fullNumber);
        if (phoneNumber) {
          setFormateNumber(phoneNumber.formatInternational());
        }
      }
    }
  }, [senderNumber]);

  const onClose = () => {
    closeLocatingModal();
  };

  const checkIsLocked = (sectionName) => {
    return phoneReportDetails?.locked.includes(sectionName);
  };

  return (
    <>
      <div className="main_root">
        <PhoneReportMenu />
        <div className="premium_sec_wrap">
          <div className="main_container">
            <div className="premium_box_wrap">
              <OverViewMenu number={formateNumber} />
              <div className="premium-section-width">
                <PhoneGps
                  number={formateNumber}
                  isOpen={locatingModalOpen}
                  onClose={onClose}
                  formatedNumber={formateNumber}
                  countryShortName={countryShortName}
                  sendLocation={sendLocation}
                  setOpenLocateModal={setOpenLocateModal}
                  phoneReportDetails={phoneReportDetails}
                  locationListData={locationListData}
                  fetchPhoneReportDetails={fetchPhoneReportDetails}
                  setPhoneReportModalShow={setPhoneReportModalShow}
                />

                <GeneralPhoneInfo reportDetail={phoneReportDetails} />

                <PhoneReputation reportDetail={phoneReportDetails} />

                <UnlockFullReport />

                <PhoneOwnerInformation
                  isLocked={checkIsLocked("phone_owner_info")}
                  reportDetail={phoneReportDetails}
                  sectionName={"phone_owner_info"}
                />
                <EmailAddress
                  isLocked={checkIsLocked("emails")}
                  reportDetail={phoneReportDetails}
                  sectionName={"emails"}
                />

                <OtherPhoneNumber
                  isLocked={checkIsLocked("other_phone_numbers")}
                  reportDetail={phoneReportDetails}
                  sectionName={"other_phone_numbers"}
                />
                <PhoneApproximateLocation
                  isLocked={checkIsLocked("phone_approximate_location")}
                  reportDetail={phoneReportDetails}
                  sectionName={"phone_approximate_location"}
                />

                <PossiblePhotos
                  isLocked={checkIsLocked("possible_photos")}
                  reportDetail={phoneReportDetails}
                  sectionName={"possible_photos"}
                />

                <SocialProfile
                  isLocked={checkIsLocked("social_media_profiles")}
                  reportDetail={phoneReportDetails}
                  sectionName={"social_media_profiles"}
                />

                <WorkHistory
                  isLocked={checkIsLocked("possible_jobs")}
                  reportDetail={phoneReportDetails}
                  sectionName={"possible_jobs"}
                />

                <PossibleEducation
                  isLocked={checkIsLocked("possible_educations")}
                  reportDetail={phoneReportDetails}
                  sectionName={"possible_educations"}
                />

                <AddressHistory
                  isLocked={checkIsLocked("address_history")}
                  reportDetail={phoneReportDetails}
                  sectionName={"address_history"}
                />

                <Relationship
                  isLocked={checkIsLocked("possible_associates")}
                  reportDetail={phoneReportDetails}
                  sectionName={"possible_associates"}
                />

                <NameAndAncestry
                  isLocked={checkIsLocked("name_and_ancestry")}
                  reportDetail={phoneReportDetails}
                  sectionName={"name_and_ancestry"}
                />

                <DataLeakChecker
                  isLocked={checkIsLocked("data_leak_checker")}
                  reportDetail={phoneReportDetails}
                  sectionName={"data_leak_checker"}
                />
                <OtherInformation
                  isLocked={checkIsLocked("other_information")}
                  reportDetail={phoneReportDetails}
                  sectionName={"other_information"}
                />

                <div
                  className="ds--complaints-sec light-bg-wrap"
                  id={toCamelCase("Nuisance Call Detection")}
                >
                  <div className="ds--alert-box">
                    <div className="unlock-information-sec">
                      <h3>
                        Nuisance Call scan detection:{" "}
                        {renderValue(
                          nuisance_call_scan_detection?.data?.is_spammer
                        )}
                      </h3>
                      <p>{t("PR_NUISANCE_DESCRIPTION")}</p>
                    </div>
                  </div>
                </div>

                <div
                  className="ds--unlock-info-sec light-bg-wrap"
                  id={toCamelCase("Neighbour Spoofing")}
                >
                  <div className="unlock-information-sec text-center">
                    <img src={neighborSpooFingIcon} alt="" />
                    <h3>
                      Potential Neighbour spoofing:{" "}
                      {renderValue(
                        potential_neighbors_spoofing?.data?.is_risky
                      )}
                    </h3>
                    <p>
                      This is only text for testing purpose and data testing and
                      all things are studded
                    </p>
                    <p>{t("PR_NEIGHBOR_DESCRIPTION")}</p>
                  </div>
                </div>

                <SearchVolume reportDetail={phoneReportDetails} />

                <div
                  className="ds--monitor-report white-bg-wrap"
                  id={toCamelCase("monitoring")}
                >
                  <div className="monitor-cmp-wrap">
                    <img src={dsMonitorIcon} alt="" />
                    <h2>{t("PR_MONITOR_SECTION_TITLE")}</h2>
                    <p>{t("PR_MONITOR_SECTION_DESCRIPTION")}</p>
                    <div className="monitor_switch_btn complaints__btn">
                      <a href="javascript:void(0)" className="complaints__btn">
                        {t("PR_MONITOR_SECTION_BUTTON")}
                        <Switch defaultChecked />
                      </a>
                    </div>
                  </div>
                </div>
                <DownloadSection reportDetail={phoneReportDetails} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PremiumPageComponent;
