import {
    moreReportIcon,
    subExpiredIcon,
    unlockReportIcon,
  } from "../../../../assets/images";
import React, { useState } from "react";
import { connect } from "react-redux";
import TransactionDeclinedModal from "../../../../pages/transactionDeclined/transactionDeclined";
import ModalComponent from "../../../common/Modal/modal";
import Loader from "../../../common/loader/loader";
import { openReport } from "../../../../redux/slice/openReportSlice";
import { subscriptionStatus } from "../../../../redux/slice/subscriptionStatusSlice";
import { renewSubscription } from "../../../../redux/slice/renewSubscriptionSlice";
import { generatePayment } from "../../../../redux/slice/generatePaymentSlice";
import { retryPayment } from "../../../../redux/slice/retryPaymentSlice";
import { updatePaymentMethod } from "../../../../redux/slice/updatePaymentMethodSlice";
import { getPhoneReportDetails } from "../../../../redux/slice";
import Notify from "../../../../../components/common/Notify/notify";
import { confirmPayment } from "../../../../utils/commonUtils";
import "./multiActionModal.scss";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { createSubscription } from "../../../../redux/slice/createSubscriptionSlice";

const MultiActionModal = (props) => {
  const {
    modalType,
    actionModal,
    setActionModal,
    phoneReportDetails,
    isLoading,
    setIsLoading,
    //API
    callOpenReport,
    callRenewSubscription,
    callGeneratePayment,
    callRetryPayment,
    callUpdatePaymentMethod,
    callCreateSubscription,
    callGetPhoneReportDetails,
  } = props;
  const stripe = useStripe();
  const elements = useElements();
  const [retryModal, setRetryModal] = useState(false);
  const [stripeError, setStripeError] = useState("");
  const { phoneReport } = phoneReportDetails || {};

  const handleOpenReport = async () => {
    setActionModal(false);
    setIsLoading(true);
    const res = await callOpenReport(phoneReport._id);
    if (res.type === "openReport/fulfilled") {
      Notify("success", res.payload.message, "");
      await callGetPhoneReportDetails(phoneReport._id);
    } else {
      Notify("error", res.error.message, "");
    }
    setIsLoading(false);
  };

  // handleFlow
  const handleFlow = async () => {
    if (!stripe || !elements) {
      return;
    }
    setIsLoading(true);
    setActionModal(false);

    const payload = {
      price_title: "unlock_full_report",
      type: "subscription",
    };
    const res = await callGeneratePayment(payload);
    if (res.type === "generatePayment/fulfilled") {
      const { client_secret, payment_method, subscription_id } =
        res.payload.data || {};
      const { error } = await confirmPayment(
        stripe,
        client_secret,
        payment_method
      );
      if (error) {
        setRetryModal(true);
      } else {
        const payload =
          modalType === "more_report_subscription"
            ? { phone_report_id: phoneReport?._id, subscription_id }
            : { subscription_id, subscription_name: "unlock_full_report" };
        const res =
          modalType === "more_report_subscription"
            ? await callCreateSubscription(payload)
            : await callRenewSubscription(payload);

        if (res?.type?.endsWith("fulfilled")) {
          Notify("success", res.payload.message, "");
        } else {
          Notify("error", res?.error?.message, "");
        }
      }
    } else {
      Notify("error", res.error.message, "");
    }
    setIsLoading(false);
  };

  // Handled retry payment request

  const onRetry = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    setIsLoading(true);
    setActionModal(false);
    const cardNumberElement = elements.getElement(CardNumberElement);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardNumberElement,
    });
    const data = {
      price_title: "unlock_full_report",
      type: "subscription",
    };
    const res = await callRetryPayment(data);
    if (res.type === "retryPayment/fulfilled") {
      const { client_secret: clientSecret, subscription_id } =
        res.payload.data || {};
      const { error } = await confirmPayment(
        stripe,
        clientSecret,
        paymentMethod.id
      );
      if (error) {
        elements.getElement(CardNumberElement).clear();
        elements.getElement(CardExpiryElement).clear();
        elements.getElement(CardCvcElement).clear();
        setRetryModal(false);
        setTimeout(() => {
          setRetryModal(true);
        }, 500);
      } else {
        const payload =
          modalType === "more_report_subscription"
            ? { phone_report_id: phoneReport?._id, subscription_id }
            : { subscription_id, subscription_name: "unlock_full_report" };
        const res =
          modalType === "more_report_subscription"
            ? await callCreateSubscription(payload)
            : await callRenewSubscription(payload);

        if (res?.type?.endsWith("fulfilled")) {
          Notify("success", res.payload.message, "");
        } else {
          Notify("error", res?.error?.message, "");
        }
        callUpdatePaymentMethod(paymentMethod.id);
      }
    } else {
      Notify("error", res.error.message, "");
    }
    setIsLoading(false);
  };
  return (
    <>
      {isLoading && <Loader />}
      <ModalComponent
        isOpen={actionModal}
        onClose={() => setActionModal(false)}
        closable={false}
        className={modalType === "renew_subscription" ? "wpb_expired__wrapper" : modalType === "open_report" ? "wpb_unlock__one_wrapper" : modalType === "more_report_subscription" ?  "wpb_buy__reports_wrapper" : ""}
      >
        {modalType === "renew_subscription" ? (
            <div className="wpb_report__wrapper">
            <div className="vc_icon__general">
                <img src={subExpiredIcon} alt="" />
            </div>
            <div className="vc_content__general">
                <h2>Your Phone Tracking Subscription Expired</h2>
                <p>Your Phone Tracking subscription ended on [DATE] and was not automatically renewed. Please renew your subscription to continue using this service.</p>
            </div>
            <div className="vc_button__general">
                <button className="vc_btn3-inline" onClick={() => setActionModal(false)}>Cancel</button>
                <button className="vc_btn3-inline"  onClick={handleFlow}>Renew Subscription</button>
            </div>
            <div className="vc_info__general">
                <p> I understand that I will be billed $XX.XX today, with my membership renewing every month thereafter. I may cancel my account hassle-free online, or by contacting Customer Care by email.</p>
            </div>
        </div>
        ) : modalType === "more_report_subscription" ? (
                    <div className="wpb_report__wrapper">
                  <div className="vc_icon__general">
                      <img src={moreReportIcon} alt="" />
                  </div>
                  <div className="vc_content__general">
                      <h2>Unlock More Report</h2>
                      <p>You have run out of reports for this month. To continue accessing detailed phone reports, you can purchase an additional 30 reports by clicking the Confirm button below.</p>
                  </div>
                  <div className="vc_button__general">
                      <button className="vc_btn3-inline" onClick={() => setActionModal(false)}>Cancel</button>
                      <button className="vc_btn3-inline"  onClick={handleFlow}>Confirm</button>
                  </div>
                  <div className="vc_info__general">
	                   <p>I acknowledge that I will be billed $XX.XX for access to 30 additional premium phone reports, with my membership renewing every month thereafter. I may cancel my account hassle-free online, or by contacting Customer Care by email.</p>
	               </div>
            </div>
        ) : modalType === "open_report" ? (
              <div className="wpb_report__wrapper">
                  <div className="vc_icon__general">
                      <img src={unlockReportIcon} alt="" />
                  </div>
                  <div className="vc_content__general">
                      <h2>Are sure you want to unlock this report</h2>
                      <p>Unlocking this report will use one of your monthly credits. You can always purchase additional credits if you need more before the month ends.</p>
                  </div>
                  <div className="vc_button__general">
                      <button className="vc_btn3-inline" onClick={() => setActionModal(false)}>Cancel</button>
                      <button className="vc_btn3-inline"  onClick={handleOpenReport}>Unlock Report</button>
                  </div>
            </div>
        ) : null}
      </ModalComponent>
      <TransactionDeclinedModal
        isOpen={retryModal}
        onClose={() => (setRetryModal(false), setStripeError(""))}
        closable={false}
        handleSubmit={onRetry}
        stripeError={stripeError}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    subscriptionStatus: state.subscriptionStatus,
    phoneReportDetails:
      state.getPhoneReportDetails?.getPhoneReportDetailsData?.data,
    openReport: state.openReport,
    renewSubscription: state.renewSubscription,
    generatePayment: state.generatePayment,
    retryPayment: state.retryPayment,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    callOpenReport: (data) => dispatch(openReport(data)),
    callCheckSubscriptionStatus: (data) => dispatch(subscriptionStatus(data)),
    callRenewSubscription: (data) => dispatch(renewSubscription(data)),
    callGeneratePayment: (data) => dispatch(generatePayment(data)),
    callRetryPayment: (data) => dispatch(retryPayment(data)),
    callUpdatePaymentMethod: (data) => dispatch(updatePaymentMethod(data)),
    callCreateSubscription: (data) => dispatch(createSubscription(data)),
    callGetPhoneReportDetails: (data) => dispatch(getPhoneReportDetails(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MultiActionModal);
