import React from "react";
import { t } from "i18next";
import {
  premiumToggleBar,
  accordionIcon,
  possibleEducation,
  nodataIcon
} from "../../../assets/images";
import { Collapse } from "antd";
import UnlockReport from "../unlockReport/unlockReport";
import { renderValue } from "../../../utils/commonUtils";

const PossibleEducation = ({ isLocked, sectionName, reportDetail }) => {
  const { possible_educations } = reportDetail?.phoneReport || {};
  const { data, status } = possible_educations || {};

  return isLocked ? (
    <UnlockReport
      title={"educational Background"}
      image={possibleEducation}
      buttonText={t("PR_UNLOCK_THIS_SECTION").toUpperCase()}
      shouldKnowText={t("PR_UNLOCK_TITLE")}
      knownText={t("PR_EDUCTION_MESSAGE")}
      sectionName={sectionName}
      pricingSectionName={"possible_educations"}
    />
  ) : (
    <div className="ds--unlock-jobs white-bg-wrap" id="educationalBackground">
      <div className="ds--unlock__title left-content main_title toggle_area">
        <h2>{t("PR_PREMIUM_SECTION_TITLE_3")}</h2>
        <div className="premium_toggle">
          <p to={""}>
            <img src={premiumToggleBar} alt="" />
          </p>
        </div>
      </div>
        {status === "found" && data?.length > 0 ? (
          <>
            <div className="ds--accordion-collapse">
              <Collapse
                items={[
                  {
                    key: "1",
                    label: "Learn more",
                    children: (
                      <p>
                        Any educational institutions that Andrew D Assinesi
                        currently or previously attended, as well as any degrees
                        conferred while there that are documented on publicly
                        accessible sources are shown below:
                      </p>
                    )
                  }
                ]}
                expandIconPosition="end"
                expandIcon={({ isActive }) => (
                  <img
                    src={isActive ? accordionIcon : accordionIcon}
                    width="2em"
                    height="2em"
                    alt=""
                  />
                )}
              />
            </div>
            <div className="ds--job-wrapper">
              {data.map((edu, index) => (
              <div className="ds--job-box" key={edu._id}>
                <h3>{`Education ${index + 1}`}</h3>
                <p>
                  <b>University Name :</b> {renderValue(edu.university_name)}
                </p>
                <p>
                  <b>Degree: </b> {renderValue(edu.degrees)}
                </p>
                <p>
                  <b>Start Date :</b> {renderValue(edu.start_date)}
                </p>
                <p>
                  <b>End Date :</b> {renderValue(edu.end_date)}
                </p>
              </div>
              ))}
            </div>
          </>
        ) : (
          <p className="data_notfound"><img src={nodataIcon} alt="" /> Record Not Found</p>
        )}
    </div>
  );
};

export default PossibleEducation;
