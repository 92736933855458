import React from "react";
import { Modal } from "antd";
import "./modal.scss";

const ModalComponent = ({
  isOpen,
  onClose,
  children,
  closable,
  className = "",
  maskClosable = true,
}) => {
  const modalClassName = `multiples_popup__wrap_ ${className}`;
  return (
    <Modal
      centered
      open={isOpen}
      cancelButtonProps={{ style: { display: "none" } }}
      okButtonProps={{ style: { display: "none" } }}
      onCancel={onClose}
      closable={closable}
      className={modalClassName}
      maskClosable={maskClosable}
    >
      {children}
    </Modal>
  );
};

export default ModalComponent;
