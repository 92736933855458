import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { connect } from "react-redux";
import { Spin } from "antd";

import AccessReportComponent from "./accessReport";
import { useAppContext } from "../previewPopup/previewPopupContext";
import { getPhoneReportDetails } from "../../redux/slice";
import { createFirstReport } from "../../redux/slice/createFirstReportSlice";
import PremiumPage from "../premiumPage";
import { getPricingTitleList } from "../../redux/slice/getPricingTitleListSlice";

const AccessReport = ({
  sendLocation,
  sendDetailsData,
  callGetPhoneReportDetails,
  phoneReportDetails,
  callGetPricing,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchparams] = useSearchParams();
  const searchNumber = Object.fromEntries([...searchparams]);

  const {
    receiverNumber,
    setReceiverNumber,
    setMessage,
    countryCode,
    setCountryCode,
    number,
    setNumber,
    setReceiverCountryCode,
    phoneReportId,
    setPhoneReportId,
  } = useAppContext();

  const [countryShortName, setCountryShortName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [openLocateModal, setOpenLocateModal] = useState(false);
  const [phoneReportModalShow, setPhoneReportModalShow] = useState(false);
  const [locationListData, setLocationListData] = useState({});
  const [defaultAccess, setDefaultAccess] = useState(false);
  const [fullAccess, setFullAccess] = useState(false);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    const data = sendDetailsData?.getSendDetailsData?.data;
    if (data) {
      setCountryShortName(data?.country);
    }
  }, [sendDetailsData]);

  useEffect(() => {
    if (searchNumber.D || searchNumber.n || searchNumber.id) {
      setReceiverCountryCode("+" + searchNumber.D);
      if (!receiverNumber) {
        setReceiverNumber(searchNumber.D);
      }
      const sanitizedNumber = searchNumber.n.replace("?Edit=true", "");
      setNumber(searchNumber.D + sanitizedNumber);
      setCountryCode("+" + searchNumber.D);
      setPhoneReportId(searchNumber.id);
    }
  }, [searchNumber.D, searchNumber.n, searchNumber.id]);

  useEffect(() => {
    if (searchNumber.id && searchNumber.id !== undefined) {
      fetchPhoneReportDetails(searchNumber.id);
    }
  }, [searchNumber.id]);

  useEffect(() => {
    if (window.location.search.includes("Edit=true")) {
      setOpenLocateModal(true);
    }
  }, []);

  const fetchPhoneReportDetails = async (id) => {
    if (searchNumber.id || phoneReportId || id) {
      setPhoneReportId(id);
      setIsLoading(true);
      const response = await callGetPhoneReportDetails(
        searchNumber.id || phoneReportId || id
      );
      setLocationListData(response);
      const status = response?.payload?.data?.phoneReport?.status;
      const isPhoneReport = response?.payload?.data?.isPhoneReport;
      if (
        status === "unlocked" ||
        status === "partially" ||
        (status === "locked" && isPhoneReport)
      ) {
        setFullAccess(true);
        setDefaultAccess(false);
      } else if (status === "locked" && !isPhoneReport) {
        setDefaultAccess(true);
      } else {
        setDefaultAccess(true);
      }
      setIsLoading(false);
    }
  };

  const closeLocatingModal = () => {
    let queryString = location.search;
    queryString = queryString.replace(/(\?Edit=true|%3FEdit%3Dtrue)/g, "");
    queryString = queryString.replace(/&&/g, "&").replace(/&$/, "");
    const newUrl = location.pathname + (queryString ? queryString : "");
    setMessage(t("SMS_DEFAULT_TEXT"));
    navigate(newUrl);
    setOpenLocateModal(false);
  };

  useEffect(() => {
    callGetPricing();
  }, []);

  return (
    <Spin spinning={isLoading}>
      {(() => {
        const props = {
          locatingModalOpen: openLocateModal,
          setOpenLocateModal: setOpenLocateModal,
          countryShortName: countryShortName,
          sendLocation: sendLocation,
          senderNumber: number,
          closeLocatingModal: closeLocatingModal,
          phoneReportModalShow: phoneReportModalShow,
          searchNumber: searchNumber,
          locationListData: locationListData,
          fetchPhoneReportDetails: fetchPhoneReportDetails,
          setPhoneReportModalShow: setPhoneReportModalShow,
          phoneReportDetails:
            phoneReportDetails?.getPhoneReportDetailsData?.data,
          countryCode: countryCode,
        };

        if (defaultAccess) {
          return <AccessReportComponent {...props} />;
        } else if (fullAccess) {
          return <PremiumPage {...props} />;
        } else {
          return <AccessReportComponent {...props} />;
        }
      })()}
    </Spin>
  );
};
const mapStateToProps = (state) => {
  return {
    sendLocation: state.sendLocation,
    sendDetailsData: state.sendDetailsData,
    phoneReportDetails: state.getPhoneReportDetails,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    callGetPhoneReportDetails: (data) => dispatch(getPhoneReportDetails(data)),
    callCreateFirstReport: (data) => dispatch(createFirstReport(data)),
    callGetPricing: (data) => dispatch(getPricingTitleList(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccessReport);
