import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AxiosInstance from "../../../utils/axios";

export const reportSubscriptionCreate = createAsyncThunk(
  "reportSubscriptionCreate",
  async (data) => {
    try {
      const response = await AxiosInstance.post(
        `/report_subscription/phone_report/create`,
        data
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data.message;
      } else {
        throw error;
      }
    }
  }
);

const reportSubscriptionCreateSlice = createSlice({
  name: "reportSubscriptionCreate",
  initialState: {
    isLoading: false,
    reportSubscriptionCreateData: null,
    isError: false,
    errorMessage: "",
  },
  extraReducers: {
    [reportSubscriptionCreate.pending]: (state) => {
      state.isLoading = true;
    },
    [reportSubscriptionCreate.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.reportSubscriptionCreateData = action.payload;
      state.isError = false;
      state.errorMessage = "";
    },
    [reportSubscriptionCreate.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.error.message;
    },
  },
});

export default reportSubscriptionCreateSlice.reducer;
