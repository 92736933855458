import { Cookies } from "react-cookie";
import {
  dataLeakCheckerImg,
  emailAddresses,
  nameAncestryImg,
  otherPhoneNumbersImg,
  possibleAssociatesImg,
  possibleEducation,
  possiblePhotosImg,
  premiumPossibleJobsImg,
  staticMap
} from "../assets/images";
import {NextArrowComponent, PrevArrowComponent} from "../components/common";
import html2canvas from "html2canvas";
import {PDFDocument} from "pdf-lib";
import {t} from "i18next";

export const carouselFullWidth = {
  dots: false,
  arrows: true,
  infinite: true,
  speed: 750,
  slidesToShow: 3,
  slidesToScroll: 1,
  prevArrow: <PrevArrowComponent />,
  nextArrow: <NextArrowComponent />,

  responsive: [
    {
      breakpoint: 1750,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1
      }
    },

    {
      breakpoint: 1366,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true
      }
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true
      }
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true
      }
    },
    {
      breakpoint: 680,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        infinite: true
      }
    },
    {
      breakpoint: 380,
      settings: {
        slidesToShow: 1,
        infinite: true
      }
    }
  ]
};

export const unLockSectionArray1 = [
  {
    title: "PR_EMAIL_ADDRESS",
    buttonText: "PR_UNLOCK_THIS_SECTION",
    image: emailAddresses,
    altText: "Lock Icon",
    knownText: "PR_UNLOCK_EMAIL_MESSAGE",
    shouldKnowText: "PR_UNLOCK_TITLE"
  },
  {
    title: "PR_REPORT_POPUP_TEXT_6",
    buttonText: "PR_UNLOCK_THIS_SECTION",
    image: otherPhoneNumbersImg,
    altText: "Lock Icon",
    knownText: "PR_OTHER_NUMBER_MESSAGE",
    shouldKnowText: "PR_UNLOCK_TITLE"
  },
  {
    title: "PR_POSSIBLE_PHOTOS",
    buttonText: "PR_UNLOCK_THIS_SECTION",
    image: possiblePhotosImg,
    altText: "Lock Icon",
    knownText: "PR_POSSIBLE_PHOTOS_MESSAGE",
    shouldKnowText: "PR_UNLOCK_TITLE"
  },
  {
    title: "PR_PHONE_APPROXIMATE_LOCATION",
    buttonText: "PR_UNLOCK_THIS_SECTION",
    image: possiblePhotosImg,
    altText: "Lock Icon",
    knownText: "PR_APPROXIMATE_LOCATION_MESSAGE",
    shouldKnowText: "PR_UNLOCK_TITLE"
  },
  {
    title: "PR_SOCIAL_PROFILES",
    buttonText: "PR_UNLOCK_THIS_SECTION",
    image: possiblePhotosImg,
    altText: "Lock Icon",
    knownText: "PR_SOCIAL_PROFILE_MESSAGE",
    shouldKnowText: "PR_UNLOCK_TITLE"
  },
  {
    title: "PR_PREMIUM_SECTION_TITLE_2",
    buttonText: "PR_UNLOCK_THIS_SECTION",
    image: premiumPossibleJobsImg,
    altText: "Lock Icon",
    knownText: "PR_WORK_HISTORY_MESSAGE",
    shouldKnowText: "PR_UNLOCK_TITLE"
  },
  {
    title: "PR_PREMIUM_SECTION_TITLE_3",
    buttonText: "PR_UNLOCK_THIS_SECTION",
    image: possibleEducation,
    altText: "Lock Icon",
    knownText: "PR_EDUCTION_MESSAGE",
    shouldKnowText: "PR_UNLOCK_TITLE"
  }
];

export const unLockSectionArray2 = [
  {
    title: "PR_ADDRESS_HISTORY",
    buttonText: "PR_UNLOCK_THIS_SECTION",
    image: possiblePhotosImg,
    altText: "Lock Icon",
    knownText: "PR_ADDRESS_HISTORY_MESSAGE",
    shouldKnowText: "PR_UNLOCK_TITLE"
  },
  {
    title: "PR_PREMIUM_SECTION_TITLE_4",
    buttonText: "PR_UNLOCK_THIS_SECTION",
    image: possibleAssociatesImg,
    altText: "Lock Icon",
    knownText: "PR_ASSOCIATE_MESSAGE",
    shouldKnowText: "PR_UNLOCK_TITLE"
  },
  {
    title: "PR_PREMIUM_SECTION_TEXT",
    buttonText: "PR_UNLOCK_THIS_SECTION",
    image: nameAncestryImg,
    altText: "Lock Icon",
    knownText: "PR_ANCESTRY_MESSAGE",
    shouldKnowText: "PR_UNLOCK_TITLE"
  },

  {
    title: "Educational Background",
    buttonText: "PR_UNLOCK_THIS_SECTION",
    image: possibleAssociatesImg,
    altText: "Lock Icon",
    knownText:
      "Believe it or not, there are still many counties in the United States that haven't made their records digitally available - and many others are only partially digitized. It is possible that records may exist that cannot be accessed digitally.",
    shouldKnowText: "PR_UNLOCK_TITLE"
  },

  {
    title: "PR_PREMIUM_SECTION_TITLE_5",
    buttonText: "PR_UNLOCK_THIS_SECTION",
    image: dataLeakCheckerImg,
    altText: "Lock Icon",
    knownText: "PR_DATA_LEAK_CHECKER_MESSAGE",
    shouldKnowText: "PR_UNLOCK_TITLE"
  }
];

export const unLockFullReportData = [
  {label: "Phone Owner Information", sectionName: "phone_owner_info"},
  {label: "Email Addresses", sectionName: "emails"},
  {label: "Other Phone Numbers", sectionName: "other_phone_numbers"},
  {
    label: "Phone Approximate Location",
    sectionName: "phone_approximate_location"
  },
  {label: "Possible Photos", sectionName: "possible_photos"},
  {label: "Social Profiles", sectionName: "social_media_profiles"},
  {label: "Work History", sectionName: "possible_jobs"},
  {label: "Educational Background", sectionName: "possible_educations"},
  {label: "Address History", sectionName: "address_history"},
  {label: "Relationships & Associates", sectionName: "possible_associates"},
  {label: "Name & Ancestry", sectionName: "name_and_ancestry"},
  {label: "Data Leak Checker", sectionName: "data_leak_checker"}
];

function formatName(firstName) {
  const formattedFirstName = firstName.replace(/_/g, " ");
  const formattedFirstNameCapitalized =
    formattedFirstName.charAt(0).toUpperCase() + formattedFirstName.slice(1);
  return formattedFirstNameCapitalized;
}

export const validateName = (name, value) => {
  const displayName = formatName(name);
  if (!value || !value.trim()) {
    return `${displayName} is required.`;
  } else if (!/^[a-zA-Z ]+$/.test(displayName)) {
    return `${displayName} can only contain letters and spaces.`;
  } else if (displayName.length < 2 || displayName.length > 50) {
    return `${displayName} must be between 2 and 50 characters long.`;
  } else {
    return null; // No error
  }
};

export const validateStreetAddress = (name, address) => {
  const addressRegex = /^[a-zA-Z0-9\s,'-]*$/;
  if (!address || !address.trim()) {
    return "Street address is required.";
  }
  if (!addressRegex.test(address)) {
    return "Please enter a valid street address.";
  }
  return "";
};
export const validatePostalCode = (name, postalCode) => {
  if (!postalCode || !postalCode.trim()) {
    return "Postal Code is required.";
  }
  return "";
};

export const toCamelCase = (str) => {
  return str
    .toLowerCase()
    .replace(/[^a-zA-Z0-9 ]/g, "") // Remove non-alphanumeric characters (optional)
    .split(" ")
    .map((word, index) =>
      index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)
    )
    .join("");
};

export function formatPhoneNumber(phoneNumber, phoneNumberPrefix) {
  // Check if phoneNumber or phoneNumberPrefix is null or undefined
  if (!phoneNumber || !phoneNumberPrefix) {
    return null; // or any appropriate default value or error handling
  }

  // Remove any non-numeric characters from the phone number
  const cleanedPhoneNumber = phoneNumber.replace(/\D/g, "");

  // Concatenate prefix and phone number
  return phoneNumberPrefix + cleanedPhoneNumber;
}

export const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

export const isUserLoggedIn = () => {
  const cookies = new Cookies();
  const token = cookies.get("token");
  if (token) return true;
  else return false;
};

export const formatSectionNames = (input) => {
  // Decode the URL-encoded string
  let decodedString = decodeURIComponent(input);

  // Convert the string to lowercase
  let lowerCaseString = decodedString.toLowerCase();

  // Replace spaces with underscores
  let transformedString = lowerCaseString.replace(/ /g, "_");

  return transformedString;
};

export const confirmPayment = async (stripe, clientSecret, paymentMethod) => {
  return await stripe.confirmPayment({
    clientSecret,
    confirmParams: {
      payment_method: paymentMethod
    },
    redirect: "if_required"
  });
};

export const getSectionName = (key) => {
  switch (key) {
    case "phone_owner_info":
      return "Phone Owner Information";
    case "other_phone_numbers":
      return "Other Phone Numbers";
    case "emails":
      return "Email Addresses";
    case "social_media_profiles":
      return "Social Profiles";
    case "possible_educations":
      return "Educational Background";
    case "possible_photos":
      return "Possible Photos";
    case "possible_jobs":
      return "Work History";
    case "possible_associates":
      return "Relationships & Associates";
    case "address_history":
      return "Address History";
    case "name_and_ancestry":
      return "Name & Ancestry";
    case "phone_approximate_location":
      return "Phone Approximate Location";
    case "data_leak_checker":
      return "Data Leak Checker";
    case "other_information":
      return "Other Information";
    case "general_phone_info":
      return "General Phone Information";
    case "phone_reputation_score":
      return "Phone Reputation Score";
    case "search_volume":
      return "Search Volume";
    case "potential_neighbors_spoofing":
      return "Neighbour Spoofing";
    case "nuisance_call_scan_detection":
      return "Nuisance Call Detection";

    default:
      break;
  }
};

export const mapOptions = {
  disableDefaultUI: true, // Disable default map controls
  zoomControl: false, // Disable zoom control
  streetViewControl: false, // Disable street view control
  fullscreenControl: false, // Disable fullscreen control
  mapTypeControl: false, // Disable map type control
  gestureHandling: "greedy",
  styles: [
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [{color: "#abcdef"}, {visibility: "on"}]
    },
    {
      featureType: "landscape",
      elementType: "all",
      stylers: [{color: "#f2f2f2"}]
    }
  ]
};

export const noInformationFoundDetails = [
  {
    mainTitle: "PR_PHONE_OWNER_INFORMATION",
    title: "PR_NO_INFORMATION_TITLE_5",
    phoneNumber: "(819) 674-0000",
    text: "PR_FOUND",
    subTitle: "PR_UPDATE_MONTHLY",
    description: "PR_OWNER_INFORMATION_DESCRIPTION",
    subDescription: "PR_OWNER_SUB_DESCRIPTION",
    mobileNumber: "(819) 674-0000.",
    monitorTitle: "PR_MONITOR_SECTION_DESCRIPTION",
    monitorStatus: "PR_MONITOR_STATUS"
  },
  {
    mainTitle: "PR_EMAIL_ADDRESS",
    title: "PR_POSSIBLE_EMAIL_TITLE",
    subTitle: "PR_UPDATE_MONTHLY",
    description: "PR_EMAIL_ADDRESS_DESCRIPTION_1",
    monitorTitle: "PR_EMAIL_ADDRESS_STATUS",
    monitorStatus: "PR_MONITOR_STATUS"
  },
  {
    mainTitle: "PR_OTHER_PHONE_NUMBERS",
    title: "PR_OTHER_PHONE_NUMBER_SUBTITLE",
    subTitle: "PR_UPDATE_MONTHLY",
    description: "PR_OTHER_PHONE_NUMBER_DESCRIPTION_1",
    monitorTitle: "PR_OTHER_PHONE_NUMBER_STATUS",
    monitorStatus: "PR_UPDATE_MONTHLY"
  },
  {
    mainTitle: "PR_PHONE_APPROXIMATE_LOCATION",
    title: "PR_NO_INFORMATION_TITLE_4",
    phoneNumber: "(819) 674-0000",
    text: "PR_NOT_FOUND",
    subTitle: "PR_UPDATE_MONTHLY",
    description: "PR_PHONE_APPROXIMATE_LOCATION_DESCRIPTION",
    subDescription: "PR_SUB_DESCRIPTION_LOCATION",
    mobileNumber: "(819) 674-0000.",
    monitorTitle: "PR_MONITOR_SECTION_DESCRIPTION",
    monitorStatus: "PR_MONITOR_STATUS"
  },
  {
    mainTitle: "PR_POSSIBLE_PHOTOS",
    title: "PR_NO_INFORMATION_TITTLE_1",
    subTitle: "PR_UPDATE_DAILY",
    description: "PR_POSSIBLE_PHOTOS_DESCRIPTION",
    monitorTitle: "PR_MONITOR_SECTION_DESCRIPTION",
    monitorStatus: "PR_MONITOR_STATUS"
  },
  {
    mainTitle: "PR_SOCIAL_PROFILES",
    title: "PR_NO_INFORMATION_TITLE_3",
    subTitle: "PR_UPDATE_MONTHLY",
    description: "PR_SOCIAL_MEDIA_DESCRIPTION_2",
    monitorTitle: "PR_MONITOR_UPDATE_REPORT",
    monitorStatus: "PR_MONITOR_STATUS"
  },
  {
    mainTitle: "PR_PREMIUM_SECTION_TITLE_2",
    title: "PR_EMPLOYMENT_HISTORY",
    subTitle: "PR_UPDATE_MONTHLY",
    description: "PR_HISTORY_TITLE_1",
    monitorTitle: "PR_MONITOR_UPDATE_REPORT_1",
    monitorStatus: "PR_MONITOR_STATUS"
  },
  {
    mainTitle: "PR_PREMIUM_SECTION_TITLE_3",
    title: "PR_POSSIBLE_EDUCTION_TITLE",
    subTitle: "PR_UPDATE_MONTHLY",
    description: "PR_EMAIL_DESCRIPTION",
    monitorTitle: "PR_MONITOR_SECTION_DESCRIPTION",
    monitorStatus: "PR_MONITOR_STATUS"
  },
  {
    mainTitle: "PR_ADDRESS_HISTORY",
    title: "PR_ADDRESS_HISTORY_TITLE",
    subTitle: "PR_UPDATE_MONTHLY",
    description: "PR_ADDRESS_HISTORY_SUBTITLE_2",
    monitorTitle: "PR_ADDRESS_MONITOR_REPORT_DESCRIPTION",
    monitorStatus: "PR_MONITOR_STATUS"
  },
  {
    mainTitle: "PR_PREMIUM_SECTION_TITLE_4",
    title: "PR_RELATIONSHIP_TITLE",
    subTitle: "PR_UPDATE_MONTHLY",
    description: "PR_RELATIONSHIP_DESCRIPTION",
    monitorTitle: "PR_MONITOR_UPDATE_REPORT_2",
    monitorStatus: "PR_MONITOR_STATUS"
  },
  // {
  //   mainTitle: "PR_EDUCATIONAL_BACKGROUND",
  //   title: "PR_POSSIBLE_EMAIL_TEXT",
  //   phoneNumber: "(819) 674-0000",
  //   text: "PR_FOUND",
  //   subTitle: "PR_UPDATE_MONTHLY",
  //   description: "PR_EMAIL_DESCRIPTION",
  //   subDescription: "PR_SUB_DESCRIPTION",
  //   mobileNumber: "(819) 674-0000.",
  //   monitorTitle: "PR_MONITOR_SECTION_DESCRIPTION",
  //   monitorStatus: "PR_MONITOR_STATUS",
  // },
  {
    mainTitle: "PR_PREMIUM_SECTION_TEXT",
    title: "PR_NO_INFORMATION_TITLE_6",
    phoneNumber: "(819) 674-0000",
    text: "PR_FOUND",
    subTitle: "PR_UPDATE_MONTHLY",
    description: "PR_EMAIL_DESCRIPTION",
    subDescription: "PR_SUB_DESCRIPTION",
    mobileNumber: "(819) 674-0000.",
    monitorTitle: "PR_MONITOR_SECTION_DESCRIPTION",
    monitorStatus: "PR_UPDATE_MONTHLY"
  },
  {
    mainTitle: "PR_PREMIUM_SECTION_TITLE_5",
    title: "PR_NO_INFORMATION_TITLE_6",
    phoneNumber: "(819) 674-0000",
    text: "PR_FOUND",
    subTitle: "PR_UPDATE_MONTHLY",
    description: "PR_EMAIL_DESCRIPTION",
    subDescription: "PR_SUB_DESCRIPTION",
    mobileNumber: "(819) 674-0000.",
    monitorTitle: "PR_MONITOR_SECTION_DESCRIPTION",
    monitorStatus: "PR_UPDATE_MONTHLY"
  }
];

export const generatePdfBlob = async (elementSelector) => {
  const mainRoot = document.querySelector(`.${elementSelector}`);
  if (!mainRoot) throw new Error("Element not found");

  // SideBar For Mobile
  const menuMobileElement = mainRoot.querySelector(".menu-mobile");
  const originalDisplay = menuMobileElement?.style.display;

  // SideBar
  const sideBarElement = mainRoot.querySelector(".premium-menu-width");
  const originalPositionSideBar = sideBarElement?.style.position;

  // SectionBox
  const sectionBoxes = mainRoot.querySelectorAll(".white-bg-wrap");
  const originalSectionBoxShadows = Array.from(sectionBoxes).map(
    (sectionBox) => sectionBox.style.boxShadow
  );

  const mapContainers = mainRoot.querySelectorAll(".map-container");

  const originalMapStates = [];

  mapContainers.forEach((mapContainer) => {
    const originalPosition = mapContainer.style.position;
    originalMapStates.push({mapContainer, originalPosition});

    mapContainer.style.position = "relative";
    const googleMapContainer = mapContainer.querySelector(".gm-style");

    let imageMap = null;
    if (googleMapContainer) {
      imageMap = document.createElement("img");
      imageMap.src = staticMap;
      imageMap.style.position = "absolute";
      imageMap.style.top = 0;
      imageMap.style.width = "100%";
      imageMap.style.height = "100%";
      mapContainer.appendChild(imageMap);

      googleMapContainer.style.display = "none";
    }

    originalMapStates.push({
      mapContainer,
      googleMapContainer,
      imageMap
    });
  });

  const restoreMaps = () => {
    originalMapStates.forEach(
      ({mapContainer, googleMapContainer, imageMap, originalPosition}) => {
        if (imageMap && mapContainer.contains(imageMap)) {
          mapContainer.removeChild(imageMap);
          if (googleMapContainer) {
            googleMapContainer.style.display = "";
          }
        }
        mapContainer.style.position = originalPosition;
      }
    );
  };

  try {
    // SideBar For Mobile
    if (window.innerWidth < 900 && menuMobileElement) {
      menuMobileElement.style.display = "none";
    }

    // SideBar
    if (sideBarElement) {
      sideBarElement.style.position = "unset";
    }

    // sectionBoxes
    sectionBoxes.forEach((sectionBox) => {
      sectionBox.style.boxShadow = "unset";
      sectionBox.style.border = "1px solid rgba(36, 83, 123, 0.3)";
    });

    const scale = 1.5; // window.devicePixelRatio * 2;
    console.log("scale", scale);
    const canvas = await html2canvas(mainRoot, {scale});
    const imgData = canvas.toDataURL("image/jpeg", 1);
    const imgBytes = await fetch(imgData).then((res) => res.arrayBuffer());
    const pdfDoc = await PDFDocument.create();
    const canvasWidth = canvas.width;
    const canvasHeight = canvas.height;
    const pdfWidth = 595;
    const pdfHeight = (canvasHeight * pdfWidth) / canvasWidth;
    const page = pdfDoc.addPage([pdfWidth, pdfHeight]);
    const image = await pdfDoc.embedJpg(imgBytes);
    page.drawImage(image, {
      x: 0,
      y: 0,
      width: pdfWidth,
      height: pdfHeight
    });

    if (menuMobileElement) {
      menuMobileElement.style.display = originalDisplay;
    }
    if (sideBarElement) {
      sideBarElement.style.position = originalPositionSideBar;
    }
    sectionBoxes.forEach((sectionBox, index) => {
      sectionBox.style.boxShadow = originalSectionBoxShadows[index];
      sectionBox.style.border = "unset";
    });
    const pdfBytes = await pdfDoc.save();
    return new Blob([pdfBytes], {type: "application/pdf"});
  } finally {
    restoreMaps();
  }
};

export const getSubscriptionName = (key) => {
  return key === "Phone Report"
    ? "phone_report"
    : key === "Unlock Full Report"
    ? "unlock_full_report"
    : key === "Pdf Download"
    ? "pdf_download"
    : "";
};

export const renderValue = (value) => {
  if (typeof value === "boolean") {
    return value ? t("YES") : t("NO");
  }
  return value !== undefined && value !== null && value !== "" ? value : "N/A";
};
