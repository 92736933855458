import React, { useState } from "react";
import { connect } from "react-redux";
import TransactionDeclinedModal from "../../../../pages/transactionDeclined/transactionDeclined";
import ModalComponent from "../../../common/Modal/modal";
import Loader from "../../../common/loader/loader";
import { subscriptionStatus } from "../../../../redux/slice/subscriptionStatusSlice";
import { renewSubscription } from "../../../../redux/slice/renewSubscriptionSlice";
import { generatePayment } from "../../../../redux/slice/generatePaymentSlice";
import { retryPayment } from "../../../../redux/slice/retryPaymentSlice";
import { updatePaymentMethod } from "../../../../redux/slice/updatePaymentMethodSlice";
import Notify from "../../../../../components/common/Notify/notify";
import { confirmPayment, generatePdfBlob } from "../../../../utils/commonUtils";
import "./reportMultiactionModal.scss";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { uploadPdf } from "../../../../redux/slice/uploadPdfSlice";
import { downloadPdf } from "../../../../assets/images";

const ReportMultiActionModal = (props) => {
  const {
    pdfModalType,
    pdfActionModal,
    setPdfActionModal,
    isLoading,
    setIsLoading,

    //API
    phoneReportDetails,
    callRenewSubscription,
    callGeneratePayment,
    callRetryPayment,
    callUpdatePaymentMethod,
    callUploadPdf,
  } = props;
  const stripe = useStripe();
  const elements = useElements();
  const [retryModal, setRetryModal] = useState(false);
  const [stripeError, setStripeError] = useState("");
  const { phoneReport } = phoneReportDetails || {};

  const handleRenewSubscription = async () => {
    if (!stripe || !elements) {
      return;
    }
    setIsLoading(true);
    setPdfActionModal(false);
    const payload = {
      price_title: "pdf_download",
      type: "subscription",
    };
    const res = await callGeneratePayment(payload);
    if (res.type === "generatePayment/fulfilled") {
      const { client_secret, payment_method, subscription_id } =
        res.payload.data || {};
      const { error } = await confirmPayment(
        stripe,
        client_secret,
        payment_method
      );
      if (error) {
        setRetryModal(true);
      } else {
        const payload = {
          subscription_id,
          subscription_name: "pdf_download",
        };
        const res = await callRenewSubscription(payload);
        if (res?.type?.endsWith("fulfilled")) {
          Notify("success", res.payload.message, "");
        } else {
          Notify("error", res?.error?.message, "");
        }
      }
    } else {
      Notify("error", res.error.message, "");
    }
    setIsLoading(false);
  };

  // Handled retry payment request

  const onRetry = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    setIsLoading(true);
    setPdfActionModal(false);
    const cardNumberElement = elements.getElement(CardNumberElement);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardNumberElement,
    });
    if (error) {
      setIsLoading(false);
      setStripeError(error.message);
      return;
    }
    const data = {
      price_title: "pdf_download",
      type: "subscription",
    };
    const res = await callRetryPayment(data);
    if (res.type === "retryPayment/fulfilled") {
      const { client_secret: clientSecret, subscription_id } =
        res.payload.data || {};
      const { error } = await confirmPayment(
        stripe,
        clientSecret,
        paymentMethod.id
      );
      if (error) {
        elements.getElement(CardNumberElement).clear();
        elements.getElement(CardExpiryElement).clear();
        elements.getElement(CardCvcElement).clear();
        setRetryModal(false);
        setTimeout(() => {
          setRetryModal(true);
        }, 500);
      } else {
        const payload = {
          subscription_id,
          subscription_name: "pdf_download",
        };
        const res = await callRenewSubscription(payload);
        if (res?.type?.endsWith("fulfilled")) {
          Notify("success", res.payload.message, "");
        } else {
          Notify("error", res?.error?.message, "");
        }
        callUpdatePaymentMethod(paymentMethod.id);
      }
    } else {
      Notify("error", res.error.message, "");
    }
    setIsLoading(false);
  };

  const handleDownloadPdf = async () => {
    setIsLoading(true);
    setPdfActionModal(false);
    if (phoneReport?.status === "locked") {
      Notify(
        "error",
        "Please unlock at least one section to download this report ",
        ""
      );
      setPdfActionModal(false);
      setIsLoading(false);
      return;
    }
    const pdfBlob = await generatePdfBlob("main_root");
    console.log('pdfBlob', pdfBlob)
    const formData = new FormData();
    formData.append("pdfFile", pdfBlob, "report.pdf");
    formData.append("phone_report_id", phoneReport?._id);
    const res = await callUploadPdf(formData);
    if (res.type === "uploadPdf/fulfilled") {
      Notify("success", res.payload.message, "");
      const { url } = res.payload.data || {};
      if (url) {
        window.location.href = url;
      }
    } else {
      Notify("error", res.error.message, "");
    }
    setIsLoading(false);
  };

  return (
    <>
      {isLoading && <Loader />}
      <ModalComponent
        isOpen={pdfActionModal}
        onClose={() => setPdfActionModal(false)}
        closable={false}
        className={pdfModalType === "download_pdf" ? "wpb_deownload__pdf_wrapper" :  ""}
      >
        {pdfModalType === "renew_subscription" ? (
          <div style={{ padding: 30 }}>
            <h1>Your phone tracking subscription is Expired</h1>
            <button onClick={() => setPdfActionModal(false)}>Cancel</button>
            <button onClick={handleRenewSubscription}>
              Renew Subscription from download
            </button>
          </div>
        ) : pdfModalType === "download_pdf" ? (
          <div className="wpb_report__wrapper">
          <div className="vc_icon__general">
              <img src={downloadPdf} alt="" />
          </div>
          <div className="vc_content__general">
              <h2>Download Your PDF</h2>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore.</p>
          </div>
          <div className="vc_button__general">
              <button className="vc_btn3-inline" onClick={handleDownloadPdf} >Download PDF</button>
              <button className="vc_back-inline" onClick={() => setPdfActionModal(false)}>Back</button>
          </div>
    </div>
        ) : null}
      </ModalComponent>
      <TransactionDeclinedModal
        isOpen={retryModal}
        onClose={() => (setRetryModal(false), setStripeError(""))}
        closable={false}
        handleSubmit={onRetry}
        stripeError={stripeError}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    subscriptionStatus: state.subscriptionStatus,
    phoneReportDetails:
      state.getPhoneReportDetails?.getPhoneReportDetailsData?.data,
    openReport: state.openReport,
    renewSubscription: state.renewSubscription,
    generatePayment: state.generatePayment,
    retryPayment: state.retryPayment,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    callCheckSubscriptionStatus: (data) => dispatch(subscriptionStatus(data)),
    callRenewSubscription: (data) => dispatch(renewSubscription(data)),
    callGeneratePayment: (data) => dispatch(generatePayment(data)),
    callRetryPayment: (data) => dispatch(retryPayment(data)),
    callUpdatePaymentMethod: (data) => dispatch(updatePaymentMethod(data)),
    callUploadPdf: (data) => dispatch(uploadPdf(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportMultiActionModal);
