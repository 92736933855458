import React, { useEffect, useState } from "react";
import { renderValue, toCamelCase } from "../../../../utils/commonUtils";
import { t } from "i18next";
import "./PhoneOwnerInformation.scss";
import { fullUnlockReportImg } from "../../../../assets/images";
import PhoneReportPayModal from "../../Modals/phoneReportPayModal/phoneReportPayModal";
import { useSelector } from "react-redux";

const PhoneOwnerInformation = ({ isLocked, sectionName, reportDetail }) => {
  const pricingTitleList = useSelector(
    (state) => state.pricingTitleList?.getPhoneReportDetailsData?.data
  );
  const [payModal, setPayModal] = useState(false);
  const [pricingData, setPricingData] = useState({});

  const { phone_owner_info } = reportDetail?.phoneReport || {};
  const { data, status } = phone_owner_info || {};

  useEffect(() => {
    if (pricingTitleList && isLocked) {
      setPricingData({
        label_1: pricingTitleList.sections["phone_owner_info"].amount,
        label_2: pricingTitleList.sections["unlock_all_section"].amount,
        symbol: pricingTitleList.sections["phone_owner_info"].symbol
      });
    }
  }, [pricingTitleList]);
  const isData = status === "found";
  return (
    <div
      className="unlock_premium_sec white-bg-wrap"
      id={toCamelCase("Phone Owner Information")}
    >
      <div className="ds--unlock__title left-content main_title toggle_area">
        <h2>{t("PR_PHONE_OWNER_INFORMATION")}</h2>
      </div>
      <div className="phone-owner-wrap">
        <div className="phone-owner-box">
          <span>{t("NAME")}</span>
          <h3 class={isLocked ? "blurred" : null}>
            {isData ? renderValue(data.name) : "Mahesh"}
          </h3>
          <hr />
        </div>
        <div className="phone-owner-box">
          <span>{t("PR_AGE")}</span>
          <h3 class={isLocked ? "blurred" : null}>
            {isData ? renderValue(data.age) : 38}
          </h3>
          <hr />
        </div>
        <div className="phone-owner-box">
          <span>{t("PR_GENDER")}</span>
          <h3 class={isLocked ? "blurred" : null}>
            {isData ? renderValue(data.gender) : "Male"}
          </h3>

          <hr />
        </div>
        <div className="phone-owner-box">
          <span>{t("LANGUAGE")}</span>
          <h3 class={isLocked ? "blurred" : null}>
            {isData ? renderValue(data.language) : "English"}
          </h3>
          <hr />
        </div>
        <div className="phone-owner-box">
          <span>{t("PR_ORIGIN_COUNTRY")}</span>
          <h3 class={isLocked ? "blurred" : null}>
            {isData ? renderValue(data.country_origin) : "India"}
          </h3>

          <hr />
        </div>
      </div>
      {isLocked ? (
        <button className="unlock-btn" onClick={() => setPayModal(true)}>
          <span>{t("PR_UNLOCK_THIS_SECTION").toUpperCase()}</span>
          <div className="unlock-btn-icon">
            <img src={fullUnlockReportImg} alt="Group" />
          </div>
        </button>
      ) : null}
      <PhoneReportPayModal
        isOpen={payModal}
        onClose={() => setPayModal(false)}
        sectionName={sectionName}
        mode="section"
        title={"PR_PHONE_OWNER_INFORMATION"}
        pricingData={pricingData}
      />
    </div>
  );
};

export default PhoneOwnerInformation;
