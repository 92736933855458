import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Cookies } from "react-cookie";
import { t } from "i18next";
import { FaCircleDot } from "react-icons/fa6";
import { CloseOutlined as IoMdClose } from "@ant-design/icons";
import { connect } from "react-redux";

import ModalComponent from "../../../common/Modal/modal";
import Notify from "../../../../../components/common/Notify/notify";
import { reportSubscriptionCreate } from "../../../../redux/slice";
import { useAppContext } from "../../../../pages/previewPopup/previewPopupContext";
import {
  listingPopupImage,
  listingPopupImg,
  reportCheckIcon,
} from "../../../../assets/images";
import "./phoneReportModal.scss";

const PhoneReportModal = ({
  isOpen,
  current,
  setPhoneReportModalOpen,
  number,
  callReportSubscriptionCreate,
  countryCode,
  setIsOpenAnimation,
  fetchPhoneReportDetails,
}) => {
  const [showError, setShowError] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const cookies = new Cookies();
  const lang = cookies.get("lang");
  const navigate = useNavigate();
  const [searchparams] = useSearchParams();
  const { phoneReportId } = useAppContext();
  const searchNumber = Object.fromEntries([...searchparams]);

  const onClose = () => {
    setIsChecked(false);
    setPhoneReportModalOpen(false);
  };

  const handleOnClickOfSeeReport = async () => {
    if (isChecked) {
      onClose();
      setIsOpenAnimation(true);
      let data = { phone_report_id: phoneReportId };
      const result = await callReportSubscriptionCreate(data);
      if (result.type === "reportSubscriptionCreate/fulfilled") {
        const delay = new Promise((resolve) => setTimeout(resolve, 10000));
        await delay;
        Notify("success", result.payload.message, "");
        fetchPhoneReportDetails();
        navigate(
          `/${lang}/access-report?D=${
            countryCode === 1 ? "+1" : countryCode.replace(" ", "")
          }&n=${searchNumber.n}&id=${phoneReportId}`
        );
      } else {
        setIsOpenAnimation(false);
        Notify("error", result.error.message, "");
      }
    } else {
      setShowError(true);
    }
  };

  return (
    <ModalComponent isOpen={isOpen} onClose={onClose} closable={false}>
      {/* Modal 1 */}
      {current === "modal1" && (
        <div className="lp__report_popup">
          {/* <div className="wpb_table_element">
            <table>
              <thead>
                <tr>
                  <th>PR_NUMBER</th>
                  <th>STATUS</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <a href="tel:(819) 674-0000">(819) 674-0000</a>
                  </td>
                  <td>Search in Progress</td>
                </tr>
              </tbody>
            </table>
          </div> */}
          <div className="wpb_popup_body">
            <div className="popup_body_description">
              <p>
                Our system is currently tracking{" "}
                <a href="tel:212-587-9854">212-587-9854</a>.<br />
                In the meantime, you have the option to learn more about the
                owner of this number by viewing a detailed phone report and the
                owner's background information.
              </p>
            </div>
            <div className="popup_body_image">
              <img src={listingPopupImg} alt="" />
            </div>
            <div className="popup_body_listing">
              <div className="popup__title">
                <h2>This Background + Report May Include:</h2>
              </div>
              {/* <div className="body__list">
                <ul>
                  <li>
                    <FaCircleDot /> Accidents
                  </li>
                  <li>
                    <FaCircleDot /> Bankruptcies
                  </li>
                  <li>
                    <FaCircleDot /> Civil Court Records
                  </li>
                  <li>
                    <FaCircleDot /> Corporate Affiliations
                  </li>
                  <li>
                    <FaCircleDot /> Death Search
                  </li>
                  <li>
                    <FaCircleDot /> Foreclosures
                  </li>
                  <li>
                    <FaCircleDot /> Liens & Judgements
                  </li>
                  <li>
                    <FaCircleDot /> Marriage & Divorce Search
                  </li>
                  <li>
                    <FaCircleDot /> People at Work
                  </li>
                </ul>
              </div> */}
              <div class="body__list">
                <ul>
                  <li>
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 512 512"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-352a96 96 0 1 1 0 192 96 96 0 1 1 0-192z"></path>
                    </svg>
                    Accidents
                  </li>
                  <li>
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 512 512"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-352a96 96 0 1 1 0 192 96 96 0 1 1 0-192z"></path>
                    </svg>
                    Corporate Affiliations
                  </li>
                  <li>
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 512 512"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-352a96 96 0 1 1 0 192 96 96 0 1 1 0-192z"></path>
                    </svg>
                    Liens & Judgements
                  </li>
                </ul>
                <ul>
                  <li>
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 512 512"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-352a96 96 0 1 1 0 192 96 96 0 1 1 0-192z"></path>
                    </svg>
                    Corporate Affiliations
                  </li>
                  <li>
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 512 512"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-352a96 96 0 1 1 0 192 96 96 0 1 1 0-192z"></path>
                    </svg>
                    Death Search
                  </li>
                  <li>
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 512 512"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-352a96 96 0 1 1 0 192 96 96 0 1 1 0-192z"></path>
                    </svg>
                    Marriage & Divorce Search
                  </li>
                </ul>
                <ul>
                  <li>
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 512 512"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-352a96 96 0 1 1 0 192 96 96 0 1 1 0-192z"></path>
                    </svg>
                    Civil Court Records
                  </li>
                  <li>
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 512 512"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-352a96 96 0 1 1 0 192 96 96 0 1 1 0-192z"></path>
                    </svg>
                    Foreclosures
                  </li>
                  <li>
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 512 512"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-352a96 96 0 1 1 0 192 96 96 0 1 1 0-192z"></path>
                    </svg>
                    People at Work
                  </li>
                </ul>
              </div>
              <div className="report__btn" onClick={handleOnClickOfSeeReport}>
                <a className="popup_btn__report">SEE REPORT</a>
              </div>
              <div
                className="thanks_btn"
                onClick={() => setPhoneReportModalOpen(false)}
              >
                <a href="#">No thanks, I will wait</a>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Modal 2 */}
      {current === "modal2" && (
        <div className="lp__report_popup">
          <div className="wpb_popup_body">
            <div className="popup_body_description">
              <p>
                Our system is currently tracking{" "}
                <a href="tel:212-587-9854">212-587-9854</a>.<br />
                In the meantime, you have the option to learn more about the
                owner of this number by viewing a detailed phone report and the
                owner's background information.
              </p>
            </div>
            <div className="popup_body_image">
              <img src={listingPopupImage} alt="" />
            </div>
            <div className="popup_body_listing">
              <div className="popup__title">
                <h2>This Background + Report May Include:</h2>
              </div>
              <div class="body__list">
                <ul>
                  <li>
                    <FaCircleDot /> Accidents1
                  </li>
                  <li>
                    <FaCircleDot /> Corporate Affiliations
                  </li>
                  <li>
                    <FaCircleDot /> Liens & Judgements
                  </li>
                </ul>
                <ul>
                  <li>
                    <FaCircleDot /> Corporate Affiliations
                  </li>
                  <li>
                    <FaCircleDot /> Death Search
                  </li>
                  <li>
                    <FaCircleDot /> Marriage & Divorce Search
                  </li>
                </ul>
                <ul>
                  <li>
                    <FaCircleDot /> Civil Court Records
                  </li>
                  <li>
                    <FaCircleDot /> Foreclosures
                  </li>
                  <li>
                    <FaCircleDot /> People at Work
                  </li>
                </ul>
              </div>
              <div className="popup_radio_btn">
                <input type="radio" id="popup_radio" name="Policy_terms" />
                <label htmlFor="popup_radio">
                  I read and I agree to the Privacy Policy & Terms.
                </label>
              </div>
              <div className="report__btn" onClick={handleOnClickOfSeeReport}>
                <a className="popup_btn__report">SEE REPORT</a>
              </div>
              <div
                className="thanks_btn"
                onClick={() => setPhoneReportModalOpen(false)}
              >
                <a href="#">No thanks, I will wait</a>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Modal 3 */}
      {current === "modal3" && (
        <div className="lp__report_popup">
          <div className="wpb_table_element">
            <table>
              <thead>
                <tr>
                  <th>{t("PR_NUMBER")}</th>
                  <th>{t("PR_STEP_3_STATUS")}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <a>{number}</a>
                  </td>
                  <td>{t("SMS_STATUS_LOCATED")}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="wpb_popup_body">
            <div className="popup_body_description">
              <p>
                {t("PR_ACCESS_REPORT_POPUP_TITLE_1")}{" "}
                <a href="tel:212-587-9854">212-587-9854</a>,{" "}
                {t("PR_ACCESS_REPORT_POPUP_TITLE_2")}
              </p>
              {/* <p>{t("PR_REPORT_POPUP_DESCRIPTION_1")}</p> */}
            </div>
            <div className="popup_body_image">
              <img src={listingPopupImage} alt="" />
            </div>
            <div className="popup_body_listing">
              <div className="popup__title">
                <h2>{t("PR_ACCESS_REPORT_TITLE_3")}</h2>
              </div>
              <div class="body__list">
                <ul>
                  <li>
                    <img src={reportCheckIcon} alt="" />{" "}
                    {t("PR_PHONE_NUMBER_INFORMATION")}
                  </li>
                  <li>
                    <img src={reportCheckIcon} alt="" /> {t("PR_PHOTOS")}
                  </li>
                  <li>
                    <img src={reportCheckIcon} alt="" />{" "}
                    {t("PR_REPORT_POPUP_TEXT_5")}
                  </li>
                  <li>
                    <img src={reportCheckIcon} alt="" />{" "}
                    {t("PR_REPORT_POPUP_TEXT_9")}
                  </li>
                </ul>
                <ul>
                  <li>
                    <img src={reportCheckIcon} alt="" />{" "}
                    {t("PR_SOCIAL_MEDIA_PROFILES")}
                  </li>
                  <li>
                    <img src={reportCheckIcon} alt="" />{" "}
                    {t("PR_ADDRESS_HISTORY")}
                  </li>
                  <li>
                    <img src={reportCheckIcon} alt="" />{" "}
                    {t("PR_OTHER_PHONE_NUMBERS")}
                  </li>
                  <li>
                    <img src={reportCheckIcon} alt="" />{" "}
                    {t("PR_PREMIUM_SECTION_TEXT")}
                  </li>
                </ul>
                <ul>
                  <li>
                    <img src={reportCheckIcon} alt="" /> {t("PR_EMAIL_ADDRESS")}
                  </li>
                  <li>
                    <img src={reportCheckIcon} alt="" />{" "}
                    {t("PR_REPORT_POPUP_TEXT_8")}
                  </li>
                  <li>
                    <img src={reportCheckIcon} alt="" />{" "}
                    {t("PR_REPORT_POPUP_TEXT_4")}
                  </li>
                  <li>
                    <img src={reportCheckIcon} alt="" /> {t("PR_OTHER")}
                  </li>
                </ul>
              </div>
              <div
                className="report__btn"
                onClick={() => handleOnClickOfSeeReport()}
              >
                <a className="popup_btn__report">{t("PR_SEE_REPORT")}</a>
              </div>
              <div className="thanks_btn" onClick={onClose}>
                <a href="#">{t("PR_DENIED_BUTTON")}</a>
              </div>
              <div className="popup_checkbox popup_radio_btn">
                {showError && (
                  <p className="invalid-number-error">
                    {t("PR_REPORT_POPUP_ERROR")}
                    <IoMdClose onClick={() => setShowError(false)} />{" "}
                  </p>
                )}
                <input
                  type="checkbox"
                  id="popup_radio"
                  name="Policy_terms"
                  checked={isChecked}
                  onChange={(e) => {
                    const checked = e.target.checked;
                    setIsChecked(checked);
                    setShowError(!checked);
                  }}
                  className={showError ? "checkbox-error" : ""}
                />
                <label htmlFor="popup_radio">{t("PR_TERM")}</label>
              </div>
            </div>
          </div>
        </div>
      )}
    </ModalComponent>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    callReportSubscriptionCreate: (data) =>
      dispatch(reportSubscriptionCreate(data)),
  };
};

export default connect(null, mapDispatchToProps)(PhoneReportModal);
