import React from "react";
import { t } from "i18next";
import {
  premiumToggleBar,
  accordionIcon,
  possibleAssociatesImg,
  nodataIcon
} from "../../../assets/images";
import { Collapse } from "antd";
import UnlockReport from "../unlockReport/unlockReport";
import { renderValue, toCamelCase } from "../../../utils/commonUtils";

const Relationship = ({ isLocked, sectionName, reportDetail }) => {

  const { possible_associates } = reportDetail?.phoneReport || {};
  const { data, status } = possible_associates || {};

  return isLocked ? (
    <UnlockReport
      title={t("PR_PREMIUM_SECTION_TITLE_4")}
      image={possibleAssociatesImg}
      buttonText={t("PR_UNLOCK_THIS_SECTION").toUpperCase()}
      shouldKnowText={t("PR_UNLOCK_TITLE")}
      knownText={t("PR_ASSOCIATE_MESSAGE")}
      sectionName={sectionName}
      pricingSectionName={"possible_associates"}
    />
  ) : (
    <div
      className="ds--unlock-jobs white-bg-wrap"
      id={toCamelCase(t("PR_PREMIUM_SECTION_TITLE_4"))}
    >
      <div className="ds--unlock__title left-content main_title toggle_area">
        <h2>{t("PR_PREMIUM_SECTION_TITLE_4")}</h2>
        <div className="premium_toggle">
          <p to={""}>
            <img src={premiumToggleBar} alt="" />
          </p>
        </div>
      </div>
        {status === "found" && data.length > 0 ? (
          <>
            <div className="ds--accordion-collapse">
              <Collapse
                items={[
                  {
                    key: "1",
                    label: "Learn more",
                    children: <p>{t("PR_RELATIONSHIP_ASSOCIATE_TITLE_2")}</p>
                  }
                ]}
                expandIconPosition="end"
                expandIcon={({ isActive }) => (
                  <img
                    src={isActive ? accordionIcon : accordionIcon}
                    width="2em"
                    height="2em"
                    alt=""
                  />
                )}
              />
            </div>
            <div className="ds--job-wrapper">
              {data.map((item, index) => {
                return (
                  <div className="ds--job-box email--underline" key={index}>
                    {item.name && <h3>{item.name}</h3>}
                    <p>
                      <b>Relationship</b>
                      {renderValue(item.relationship)}
                    </p>
                  </div>
                );
              })}
            </div>
          </>
        ) : (
          <p className="data_notfound"><img src={nodataIcon} alt="" /> Record Not Found</p>
        )}
    </div>
  );
};

export default Relationship;
