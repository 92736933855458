import React from "react";
import { t } from "i18next";
import { locationIcon, nodataIcon, possiblePhotosImg } from "../../../assets/images";
import UnlockReport from "../unlockReport/unlockReport";
import { mapOptions, renderValue, toCamelCase } from "../../../utils/commonUtils";
import {
  GoogleMap,
  useLoadScript
} from "@react-google-maps/api";
import { googleAPIKey } from "../../../../environment";

const PhoneApproximateLocation = ({ isLocked, sectionName, reportDetail }) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: googleAPIKey
  });

  const { phone_approximate_location } = reportDetail?.phoneReport || {};
  const { data, status } = phone_approximate_location || {};

  return isLocked ? (
    <UnlockReport
      title={t("PR_PHONE_APPROXIMATE_LOCATION")}
      image={possiblePhotosImg}
      buttonText={t("PR_UNLOCK_THIS_SECTION").toUpperCase()}
      shouldKnowText={t("PR_UNLOCK_TITLE")}
      knownText={t("PR_APPROXIMATE_LOCATION_MESSAGE")}
      sectionName={sectionName}
      pricingSectionName={"phone_approximate_location"}
    />
  ) : (
    <div
      className="ds__phn--location white-bg-wrap"
      id={toCamelCase(t("PR_PHONE_APPROXIMATE_LOCATION"))}
    >
      <div className="trs__bg_phone">
        <div className="unlock_title unlock-description">
          <h2>{t("PR_PHONE_APPROXIMATE_LOCATION")}</h2>
            {status === "found" && (
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit Suscipit
                dicta nesciunt officiis perferendis molestiae laudantium quos
                animi soluta repudiandae dolor quisquam deleniti voluptates.
              </p>
            )}
        </div>
          {status === "found" ? (
            <div className="phone__location_map">
              <div className="ds_location__info">
                <div className="location_map__icon">
                  <img src={locationIcon} alt="" />
                </div>
                <div className="location_map__text">
                  <span>{renderValue(data[0]?.location_name)}</span>
                </div>
              </div>
              <div className="ds_location__map">
                <div
                  className="map-container"
                  style={{
                    height: "300px",
                    borderRadius: "15px",
                    overflow: "hidden"
                  }}
                >
                  {isLoaded && (
                    <GoogleMap
                      zoom={10}
                      center={{
                        lat: 5.400,
                        lng: 5.800
                      }}
                      mapContainerStyle={{ height: "100%", width: "100%" }}
                      options={mapOptions}
                    ></GoogleMap>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <p className="data_notfound"><img src={nodataIcon} alt="" /> Record Not Found</p>
          )}
      </div>
    </div>
  );
};

export default PhoneApproximateLocation;
