import React from "react";
import { t } from "i18next";
import { premiumToggleBar, possiblePhotosImg, nodataIcon } from "../../../assets/images";
import UnlockReport from "../unlockReport/unlockReport";
import { renderValue, toCamelCase } from "../../../utils/commonUtils";

const OtherInformation = ({ isLocked, sectionName, reportDetail }) => {
  const { other_information } = reportDetail?.phoneReport || {};
  const { data, status } = other_information || {};

  return isLocked ? (
    <UnlockReport
      title={"Other Information"}
      image={possiblePhotosImg}
      buttonText={t("PR_UNLOCK_THIS_SECTION").toUpperCase()}
      shouldKnowText={t("PR_UNLOCK_TITLE")}
      knownText={"other_information"}
      sectionName={sectionName}
      pricingSectionName={"other_information"}
    />
  ) : (
    <div
      className="ds--unlock-jobs white-bg-wrap"
      id={toCamelCase("other Information")}
    >
      <div className="ds--unlock__title left-content main_title toggle_area">
        <h2>Other Information</h2>
        <div className="premium_toggle">
          <p to={""}>
            <img src={premiumToggleBar} alt="" />
          </p>
        </div>
      </div>

      {status === "found" && data ? (
        <div className="ds--job-wrapper">
          <p>{t("other_information")}</p>
          <div className="ds--job-wrapper">
            <div className="ds--job-box email--underline">
              {data.category && <h3>{renderValue(data.category)}</h3>}
              <p>
                <b>Category</b>
                {renderValue(data.category)}
              </p>
              <p>
                <b>Description</b>
                {renderValue(data.description)}
              </p>
              <p>
                <b>Rating</b>
                {renderValue(data.rating)}
              </p>
              <p>
                <b>Website</b>
                {renderValue(data.website)}
              </p>
              <p>
                <b>Whatsapp Status</b>
                {renderValue(data.whatsapp_status)}
              </p>
              <p>
                <b>Whatsapp Verified</b>
                {renderValue(data.whatsapp_verified)}
              </p>
            </div>
          </div>
        </div>
      ) : (
        <p className="data_notfound"><img src={nodataIcon} alt="" /> Record Not Found</p>
      )}
    </div>
  );
};

export default OtherInformation;
