import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import AxiosInstance from "../../../utils/axios";
export const pdfSubscriptionStatus = createAsyncThunk(
  "pdfSubscriptionStatus",
  async (reportId) => {
    try {
      const response = await AxiosInstance.post(
        `/phone_report/pdf_subscription_status`,
        {
          phone_report_id: reportId
        }
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data.message;
      } else {
        throw error;
      }
    }
  }
);

const pdfSubscriptionStatusSlice = createSlice({
  name: "pdfSubscriptionStatus",
  initialState: {
    isLoading: false,
    pdfSubscriptionStatus: null,
    isError: false,
    errorMessage: ""
  },
  extraReducers: {
    [pdfSubscriptionStatus.pending]: (state) => {
      state.isLoading = true;
    },
    [pdfSubscriptionStatus.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.pdfSubscriptionStatus = action.payload;
      state.isError = false;
      state.errorMessage = "";
    },
    [pdfSubscriptionStatus.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.error.message;
    }
  }
});

export default pdfSubscriptionStatusSlice.reducer;
