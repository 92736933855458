import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import AxiosInstance from '../../../utils/axios';
export const generatePayment = createAsyncThunk(
  'generatePayment',
  async (data) => {
    try {
      const response = await AxiosInstance.post(
        `/report_subscription/generate_payment`,
        data
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data.message;
      } else {
        throw error;
      }
    }
  }
);

const generatePaymentSlice = createSlice({
  name: 'generatePayment',
  initialState: {
    isLoading: false,
    generatePayment: null,
    isError: false,
    errorMessage: ''
  },
  extraReducers: {
    [generatePayment.pending]: (state) => {
      state.isLoading = true;
    },
    [generatePayment.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.generatePayment = action.payload;
      state.isError = false;
      state.errorMessage = '';
    },
    [generatePayment.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.error.message;
    }
  }
});

export default generatePaymentSlice.reducer;
