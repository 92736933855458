import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import AxiosInstance from "../../../utils/axios";
export const getPdfUrl = createAsyncThunk("getPdfUrl", async (reportId) => {
  try {
    const response = await AxiosInstance.get(
      `/phone_report/get_report_pdf_url/${reportId}`
    );
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response.data.message;
    } else {
      throw error;
    }
  }
});

const getPdfUrlSlice = createSlice({
  name: "getPdfUrl",
  initialState: {
    isLoading: false,
    getPdfUrl: null,
    isError: false,
    errorMessage: ""
  },
  extraReducers: {
    [getPdfUrl.pending]: (state) => {
      state.isLoading = true;
    },
    [getPdfUrl.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.getPdfUrl = action.payload;
      state.isError = false;
      state.errorMessage = "";
    },
    [getPdfUrl.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.error.message;
    }
  }
});

export default getPdfUrlSlice.reducer;
