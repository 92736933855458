import React, { useEffect, useState } from "react";
import PhoneGpsComponent from "./phoneGpsComponent";
import { useLocation } from "react-router-dom";
import { useLoadScript } from "@react-google-maps/api";
import { googleAPIKey } from "../../../../environment";
const PhoneGps = ({
  number,
  isOpen,
  onClose,
  formatedNumber,
  countryShortName,
  sendLocation,
  setOpenLocateModal,
  locationListData,
  fetchPhoneReportDetails,
  setPhoneReportModalShow,
  phoneReportDetails
}) => {
  const [inFowindowOpen, setInFowindowOpen] = useState(false);
  const [latLong, setLatLong] = useState([
    {
      latlng: {lat: null, lng: null},
      address: ""
    }
  ]);
  const [center, setCenter] = useState({
    lat: 40.7128,
    lng: -73.935242
  });
  const {isLoaded} = useLoadScript({
    googleMapsApiKey: googleAPIKey
  });
  const location = useLocation();
  useEffect(() => {
    const {payload} = locationListData || {};
    const {data} = payload || {};
    const {locations} = data || {};

    if (locations) {
      let defaultLatLong = locations.find((item) => {
        return item.status === true;
      });
      if (defaultLatLong) {
        setLatLong([
          {
            latlng: {
              lat: defaultLatLong.geo.lat,
              lng: defaultLatLong.geo.long
            },
            address: defaultLatLong.geo.formatted
          }
        ]);
        setCenter({
          lat: defaultLatLong.geo.lat,
          lng: defaultLatLong.geo.long
        });
      }
    }
  }, [locationListData]);

  const handleChangeMap = (lat, lng, address) => {
    setInFowindowOpen(false);
    setLatLong([
      ...latLong,
      {
        latlng: {lat: lat, lng: lng},
        address: address
      }
    ]);
    setCenter({
      lat: lat,
      lng: lng
    });
  };

  return (
    <PhoneGpsComponent
      isOpen={isOpen}
      onClose={onClose}
      setIsOpen={setOpenLocateModal}
      number={number}
      formatedNumber={formatedNumber}
      countryShortName={countryShortName}
      sendLocation={sendLocation}
      phoneReportDetails={phoneReportDetails}
      center={center}
      latLong={latLong}
      inFowindowOpen={inFowindowOpen}
      isLoaded={isLoaded}
      setInFowindowOpen={setInFowindowOpen}
      handleChangeMap={handleChangeMap}
      fetchPhoneReportDetails={fetchPhoneReportDetails}
      setPhoneReportModalShow={setPhoneReportModalShow}
    />
  );
};

export default PhoneGps;
