import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import AxiosInstance from "../../../utils/axios";
export const renewSubscription = createAsyncThunk(
  "renewSubscription",
  async (data) => {
    try {
      const response = await AxiosInstance.post(
        `/subscription/renew_expired`,
        data
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data.message;
      } else {
        throw error;
      }
    }
  }
);

const renewSubscriptionSlice = createSlice({
  name: "renewSubscription",
  initialState: {
    isLoading: false,
    renewSubscription: null,
    isError: false,
    errorMessage: ""
  },
  extraReducers: {
    [renewSubscription.pending]: (state) => {
      state.isLoading = true;
    },
    [renewSubscription.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.renewSubscription = action.payload;
      state.isError = false;
      state.errorMessage = "";
    },
    [renewSubscription.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.error.message;
    }
  }
});

export default renewSubscriptionSlice.reducer;
