import React from "react";
import { t } from "i18next";
import {
  premiumToggleBar,
  accordionIcon,
  nameAncestryImg,
  nodataIcon
} from "../../../assets/images";
import { Collapse } from "antd";
import UnlockReport from "../unlockReport/unlockReport";
import { renderValue, toCamelCase } from "../../../utils/commonUtils";
const NameAndAncestry = ({ isLocked, sectionName, reportDetail }) => {

  const { name_and_ancestry } = reportDetail?.phoneReport || {};
  const { data, status } = name_and_ancestry || {};

  return isLocked ? (
    <UnlockReport
      title={t("PR_PREMIUM_SECTION_TEXT")}
      image={nameAncestryImg}
      buttonText={t("PR_UNLOCK_THIS_SECTION").toUpperCase()}
      shouldKnowText={t("PR_UNLOCK_TITLE")}
      knownText={t("PR_ANCESTRY_MESSAGE")}
      sectionName={sectionName}
      pricingSectionName={"name_and_ancestry"}
    />
  ) : (
    <div
      className="ds--unlock-jobs white-bg-wrap"
      id={toCamelCase(t("PR_PREMIUM_SECTION_TEXT"))}
    >
      <div className="ds--unlock__title left-content main_title toggle_area">
        <h2>{t("PR_PREMIUM_SECTION_TEXT")}</h2>
        <div className="premium_toggle">
          <p to={""}>
            <img src={premiumToggleBar} alt="" />
          </p>
        </div>
      </div>
        {status === "found" ? (
          <>
            <div className="ds--accordion-collapse">
              <Collapse
                items={[
                  {
                    key: "1",
                    label: "Learn more",
                    children: <p>{t("PR_LEARN_MORE_DESCRIPTION_3")}</p>
                  }
                ]}
                expandIconPosition="end"
                expandIcon={({ isActive }) => (
                  <img
                    src={isActive ? accordionIcon : accordionIcon}
                    width="2em"
                    height="2em"
                    alt=""
                  />
                )}
              />
            </div>
            <div className="ds--job-wrapper">
              <div className="ds--job-box email--underline">
                <h3>{renderValue(data.name)}</h3>
                <p>
                  <b>Language Spoken</b>
                  {renderValue(data.language_spoken)}
                </p>
                <p>
                  <b>Meaning</b>
                  {renderValue(data.meaning)}
                </p>
                <p>
                  <b>Origin</b>
                  {renderValue(data.origin)}
                </p>
                <p>
                  <b>Possible Ancestry</b>
                  {renderValue(data.possible_ancestry)}
                </p>
              </div>
          </div>
          </>
        ) : (
          <p className="data_notfound"><img src={nodataIcon} alt="" /> Record Not Found</p>
        )}
    </div>
  );
};

export default NameAndAncestry;
