import React, { useEffect, useState } from "react";
import "./accessReport.scss";
import { PhoneGps, PhoneReportMenu } from "../../components/common";
import {
  accessReportsImg,
  fullUnlockReportImg,
  reportCheckIcon,
} from "../../assets/images";
import { PhoneReportModal } from "../../components/partials";
import StepTutorialModal from "../../components/partials/Modals/stepTutorial/stepTutorial";
import { Cookies } from "react-cookie";
import { LocatingModal } from "../../components/partials/Modals";
import { parsePhoneNumber } from "libphonenumber-js";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppContext } from "../previewPopup/previewPopupContext";
import { useTranslation } from "react-i18next";
import PolyAnimation from "../polyAnimation/polyAnimation";
import ModalComponent from "../../components/common/Modal/modal";

const AccessReportComponent = ({
  locatingModalOpen,
  countryShortName,
  sendLocation,
  senderNumber,
  closeLocatingModal,
  setOpenLocateModal,
  phoneReportModalShow,
  searchNumber,
  countryCode,
  locationListData,
  fetchPhoneReportDetails,
  setPhoneReportModalShow,
  phoneReportDetails,
}) => {
  const [formateNumber, setFormateNumber] = useState(0);
  const [stepModalOpen, setStepModalOpen] = useState(false);
  const [isOpenAnimation, setIsOpenAnimation] = useState(false);
  const [phoneReportModalOpen, setPhoneReportModalOpen] = useState(false);

  const cookies = new Cookies();
  const stepCheck = cookies.get("step");
  const phoneReportModal = cookies.get("phoneReport");

  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isSuccessPreviewSend } = useAppContext();

  useEffect(() => {
    if (senderNumber) {
      let fullNumber = "+" + senderNumber.replace("+", "");
      if (fullNumber.length > 8) {
        const phoneNumber = parsePhoneNumber(fullNumber);
        if (phoneNumber) {
          setFormateNumber(phoneNumber.formatInternational());
        }
      }
    }
  }, [senderNumber]);

  useEffect(() => {
    if (phoneReportModalShow || isSuccessPreviewSend) {
      if (phoneReportModal !== "true") {
        if (stepCheck === "true") {
          setTimeout(() => {
            setPhoneReportModalOpen(true);
            cookies.set("phoneReport", "true");
          }, 3000);
        }
      }
    }
  }, [phoneReportModalShow]);

  const onClose = () => {
    closeLocatingModal();
    const { locations } = phoneReportDetails?.phoneReport || {};
    if (
      location.pathname.includes("access-report") &&
      (!locations || locations.length < 0)
    ) {
      navigate("/dashboard");
    }
  };

  useEffect(() => {
    if (
      stepCheck !== "true" &&
      !phoneReportDetails?.phoneReport &&
      !searchNumber.id
    ) {
      setStepModalOpen(true);
    } else if (stepCheck === "true" && !searchNumber.id) {
      navigate(`/dashboard`);
    } else {
      setStepModalOpen(false);
    }
  }, []);

  const onCloseSteps = () => {
    cookies.set("step", "true");
    setStepModalOpen(false);
    setOpenLocateModal(true);
  };

  return (
    <>
      <PhoneReportModal
        isOpen={phoneReportModalOpen}
        current={"modal3"}
        setPhoneReportModalOpen={setPhoneReportModalOpen}
        number={formateNumber}
        countryCode={countryCode}
        setIsOpenAnimation={setIsOpenAnimation}
        fetchPhoneReportDetails={fetchPhoneReportDetails}
      />
      <StepTutorialModal isOpen={stepModalOpen} onClose={onCloseSteps} />
      <LocatingModal
        className={"tracelo_unsubscribe_modal"}
        isOpen={locatingModalOpen}
        closable={true}
        onClose={onClose}
        formatedNumber={formateNumber}
        countryShortName={countryShortName}
        sendLocation={sendLocation}
        fetchPhoneReportDetails={fetchPhoneReportDetails}
        setIsOpen={setOpenLocateModal}
        setPhoneReportModalShow={setPhoneReportModalShow}
      />
      <ModalComponent
        isOpen={isOpenAnimation}
        onClose={() => setIsOpenAnimation(false)}
        closable={false}
        className="polly_animation_modal"
      >
        <PolyAnimation setIsOpenAnimation={setIsOpenAnimation} />
      </ModalComponent>
      <div className="main_root">
        <PhoneReportMenu />
        <div className="ds--access-report premium_sec_wrap">
          <div className="main_container">
            <div className="premium_box_wrap">
              <div className={`premium-section-width`}>
                <PhoneGps
                  number={formateNumber}
                  isOpen={locatingModalOpen}
                  onClose={onClose}
                  formatedNumber={formateNumber}
                  countryShortName={countryShortName}
                  sendLocation={sendLocation}
                  setOpenLocateModal={setOpenLocateModal}
                  phoneReportDetails={phoneReportDetails}
                  locationListData={locationListData}
                  fetchPhoneReportDetails={fetchPhoneReportDetails}
                  setPhoneReportModalShow={setPhoneReportModalShow}
                />
                <div className="ds--report-wrap white-bg-wrap">
                  <div className="main_title">
                    <h2>{t("PR_ACCESS_REPORT_TITLE_1")}</h2>
                    <p>{t("PR_ACCESS_REPORT_TITLE_2")}</p>
                    <p>{t("PR_ACCESS_REPORT_DESCRIPTION")}</p>
                  </div>
                  <div className="report--img">
                    <img src={accessReportsImg} alt="" />
                  </div>
                  <div className="report--listing-title">
                    <h4>{t("PR_ACCESS_REPORT_TITLE_3")}</h4>
                  </div>
                  <div className="report--listing">
                    <div className="report--listing-box">
                      <img src={reportCheckIcon} alt="" />
                      <span>{t("PR_PHONE_NUMBER_INFORMATION")}</span>
                    </div>
                    <div className="report--listing-box">
                      <img src={reportCheckIcon} alt="" />
                      <span>{t("PR_SOCIAL_MEDIA_PROFILES")}</span>
                    </div>
                    <div className="report--listing-box">
                      <img src={reportCheckIcon} alt="" />
                      <span>{t("PR_EMAIL_ADDRESS")}</span>
                    </div>
                    <div className="report--listing-box">
                      <img src={reportCheckIcon} alt="" />
                      <span>{t("PR_PHOTOS")}</span>
                    </div>
                    <div className="report--listing-box">
                      <img src={reportCheckIcon} alt="" />
                      <span> {t("PR_ADDRESS_HISTORY")}</span>
                    </div>
                    <div className="report--listing-box">
                      <img src={reportCheckIcon} alt="" />
                      <span> {t("PR_REPORT_POPUP_TEXT_6")}</span>
                    </div>
                    <div className="report--listing-box">
                      <img src={reportCheckIcon} alt="" />
                      <span> {t("PR_EDUCATION")}</span>
                    </div>
                    <div className="report--listing-box">
                      <img src={reportCheckIcon} alt="" />
                      <span> {t("PR_COMPANIES_JOBS")}</span>
                    </div>
                    <div className="report--listing-box">
                      <img src={reportCheckIcon} alt="" />
                      <span> {t("PR_REPORT_POPUP_TEXT_4")} </span>
                    </div>
                    <div className="report--listing-box">
                      <img src={reportCheckIcon} alt="" />
                      <span> {t("PR_REPORT_POPUP_TEXT_9")}</span>
                    </div>
                    <div className="report--listing-box">
                      <img src={reportCheckIcon} alt="" />
                      <span> {t("PR_PREMIUM_SECTION_TEXT")}</span>
                    </div>
                    <div className="report--listing-box">
                      <img src={reportCheckIcon} alt="" />
                      <span> {t("PR_OTHER")}</span>
                    </div>
                  </div>
                  <div className="report-btn-wrap">
                    <button
                      className={"access-btn-info"}
                      onClick={() => setPhoneReportModalOpen(true)}
                    >
                      <p> {t("PR_GET_ACCESS")}</p>
                      <div className="access-btn-info">
                        <img src={fullUnlockReportImg} alt="Group" />
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccessReportComponent;
