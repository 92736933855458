import React from "react";
import { Route } from "react-router-dom";
import {
  AccessReport,
  MyAccount,
  PreviewPopup,
  PhoneReportDashboard,
  ContactUs,
  FaqComponent,
  NoInformationFound,
  LocationTrack,
  CookiesPolicy,
  TermConditions,
  PrivacyPolicy,
} from "./pages";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const PhoneReportsRoutes = () => {
  const stripePromise = loadStripe(
    "pk_test_51NJ6tvLFvpGNSJY705FwsTG4A6KRBTzvhIxLq2wCRCNbQe8LkTQ2F6QAn6jyLOzAlNNyz8IhjRSzvgncfNYlWNZ100k1daXRrv"
  );
  return (
    <>
      <Route
        path="/:lang/location-preview-popup"
        element={<PreviewPopup />}
      ></Route>
      <Route
        path="/:lang/access-report"
        element={
          <Elements stripe={stripePromise}>
            <AccessReport />
          </Elements>
        }
      ></Route>
      <Route
        path="/:lang/my-account"
        element={
          <Elements stripe={stripePromise}>
            <MyAccount />
          </Elements>
        }
      ></Route>
      <Route path="/:lang/dashboard" element={<PhoneReportDashboard />}></Route>
      <Route path="/:lang/contact-us" element={<ContactUs />}></Route>
      <Route
        path="/:lang/no-information-found"
        element={<NoInformationFound />}
      ></Route>
      <Route path="/:lang/faqs" element={<FaqComponent />}></Route>
      <Route path="/:lang/location" element={<LocationTrack />}></Route>
      <Route path="/:lang/cookiePolicy" element={<CookiesPolicy />}></Route>
      <Route path="/:lang/privacyPolicy" element={<PrivacyPolicy />}></Route>
      <Route path="/:lang/termsConditions" element={<TermConditions />}></Route>
    </>
  );
};

export default PhoneReportsRoutes;
