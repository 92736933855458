import { Collapse } from "antd";
import "./faqComponent.scss";
import React, { useState } from "react";
import { accordionMinusIcon, accordionPlusIcon } from "../../assets/images";
import { t } from "i18next";

const FaqComponent = () => {
  const [activeKey, setActiveKey] = useState(null);

  const handleCollapseChange = (key) => {
    setActiveKey((prevKey) => (prevKey === key ? null : key));
  };

  return (
    <div className="main_root">
      <div className="main_container">
        <div className="faqs__accordion_wrapper">
          <div className="faqs__accordion_title">
            <h2>{t("FAQ_ASKED_QUESTION_TITLE")}</h2>
            <p>{t("PR_FAQ_DESCRIPTION")}</p>
          </div>
          <div className="inner__collapse_accordion">
            <div className="faqs__collapse_accordion">
              <Collapse
                activeKey={activeKey === "1" ? ["1"] : []}
                onChange={() => handleCollapseChange("1")}
                expandIconPosition="end"
                expandIcon={({ isActive }) => (
                  <img
                    src={isActive ? accordionMinusIcon : accordionPlusIcon}
                    width="20px"
                    height="20px"
                    alt=""
                    // onClick={() => handleCollapseChange("1")}
                  />
                )}
              >
                <Collapse.Panel header="What is Tracelo Phone Report?" key="1">
                  <p>
                    Tracelo Phone Report offers a service that enables you to
                    determine a person's location using their phone number.
                    Whether you are seeking to locate a family member or any
                    individual, our service can assist you, all while ensuring
                    ethical practices.
                  </p>
                </Collapse.Panel>
              </Collapse>
            </div>

            <div className="faqs__collapse_accordion">
              <Collapse
                activeKey={activeKey === "2" ? ["2"] : []}
                onChange={() => handleCollapseChange("2")}
                expandIconPosition="end"
                expandIcon={({ isActive }) => (
                  <img
                    src={isActive ? accordionMinusIcon : accordionPlusIcon}
                    width="20px"
                    height="20px"
                    alt=""
                    // onClick={() => handleCollapseChange("2")}
                  />
                )}
              >
                <Collapse.Panel
                  header="How to track a phone number without physical access to the target device?"
                  key="2"
                >
                  <p>
                    Tracelo Phone Report operates ethically, respecting privacy
                    and consent. It does not require physical access to the
                    target phone. To track another person's location, all you
                    need is their phone number. Tracelo Phone Report functions
                    entirely online and remotely, eliminating the need for app
                    installations on your device or the targeted mobile device.
                    You won't have to install any apps; simply access Tracelo
                    Phone Report via a web browser, enter the phone number you
                    wish to locate, and ensure you have the individual's consent
                    to use our service.
                  </p>
                </Collapse.Panel>
              </Collapse>
            </div>

            <div className="faqs__collapse_accordion">
              <Collapse
                activeKey={activeKey === "3" ? ["3"] : []}
                onChange={() => handleCollapseChange("3")}
                expandIconPosition="end"
                expandIcon={({ isActive }) => (
                  <img
                    src={isActive ? accordionMinusIcon : accordionPlusIcon}
                    width="20px"
                    height="20px"
                    alt=""
                    // onClick={() => handleCollapseChange("3")}
                  />
                )}
              >
                <Collapse.Panel
                  header="Is it even legal to use the Tracelo Phone Report phone number tracker?"
                  key="3"
                >
                  <p>
                    Your concerns about the location tracker are natural. Our
                    primary focus is ensuring that tracking is conducted
                    responsibly and with the prior agreement of the person being
                    located. To maintain compliance and respect for privacy, we
                    require users to send SMS location requests only to
                    individuals who've given explicit prior consent to be
                    tracked. Rest assured, Tracelo Phone Report's phone
                    number-based location method is entirely legal and aligned
                    with the Information Commissioner’s Office recommendations.
                  </p>
                </Collapse.Panel>
              </Collapse>
            </div>

            <div className="faqs__collapse_accordion">
              <Collapse
                activeKey={activeKey === "4" ? ["4"] : []}
                onChange={() => handleCollapseChange("4")}
                expandIconPosition="end"
                expandIcon={({ isActive }) => (
                  <img
                    src={isActive ? accordionMinusIcon : accordionPlusIcon}
                    width="20px"
                    height="20px"
                    alt=""
                    // onClick={() => handleCollapseChange("4")}
                  />
                )}
              >
                <Collapse.Panel
                  header="What networks does Tracelo Phone Report support?"
                  key="4"
                >
                  <p>
                    You don’t need to be a tech-savvy person to track a cell
                    phone location by number. You don’t even need to know what
                    network they use, as Tracelo Phone Report works on any
                    network, meaning it can locate anyone anywhere.
                  </p>
                </Collapse.Panel>
              </Collapse>
            </div>

            <div className="faqs__collapse_accordion">
              <Collapse
                activeKey={activeKey === "5" ? ["5"] : []}
                onChange={() => handleCollapseChange("5")}
                expandIconPosition="end"
                expandIcon={({ isActive }) => (
                  <img
                    src={isActive ? accordionMinusIcon : accordionPlusIcon}
                    width="20px"
                    height="20px"
                    alt=""
                    // onClick={() => handleCollapseChange("5")}
                  />
                )}
              >
                <Collapse.Panel
                  header="Can I track the location of the phone number if I don’t know its model?"
                  key="5"
                >
                  <p>
                    Yes, Tracelo Phone Report allows you to track a phone using
                    only their phone number, without any need to know their
                    phone model. However, it's important to note that this
                    tracking is contingent upon obtaining the individual's
                    explicit consent to be tracked, ensuring ethical use.
                    Tracelo Phone Report operates online, accessible through any
                    web browser. Once you've created an account and input their
                    phone number, you can access their precise location,
                    provided that the SMS recipient agrees to share their
                    location.
                  </p>
                </Collapse.Panel>
              </Collapse>
            </div>

            <div className="faqs__collapse_accordion">
              <Collapse
                activeKey={activeKey === "6" ? ["6"] : []}
                onChange={() => handleCollapseChange("6")}
                expandIconPosition="end"
                expandIcon={({ isActive }) => (
                  <img
                    src={isActive ? accordionMinusIcon : accordionPlusIcon}
                    width="20px"
                    height="20px"
                    alt=""
                    // onClick={() => handleCollapseChange("6")}
                  />
                )}
              >
                <Collapse.Panel
                  header="Can I track someone by cell phone number?"
                  key="6"
                >
                  <p>
                    To geolocate a phone, please ensure that you provide us with
                    the phone number associated with the mobile device for which
                    you have obtained prior consent to track. The device you are
                    seeking will then receive a custom SMS message, courteously
                    requesting it to transmit its position. Tracelo Phone Report
                    will subsequently send you its location by SMS, maintaining
                    a process that strictly adheres to consent-based tracking
                    practices.
                  </p>
                </Collapse.Panel>
              </Collapse>
            </div>

            <div className="faqs__collapse_accordion">
              <Collapse
                activeKey={activeKey === "7" ? ["7"] : []}
                onChange={() => handleCollapseChange("7")}
                expandIconPosition="end"
                expandIcon={({ isActive }) => (
                  <img
                    src={isActive ? accordionMinusIcon : accordionPlusIcon}
                    width="20px"
                    height="20px"
                    alt=""
                    // onClick={() => handleCollapseChange("7")}
                  />
                )}
              >
                <Collapse.Panel
                  header="Can I track someone by cell phone number for free?"
                  key="7"
                >
                  <p>
                    Since Tracelo Phone Report is an advanced solution for cell
                    phone tracking by number, its services are paid. Tracelo
                    Phone Report works on a monthly basis. With a first payment
                    of just $0.99 you will have unlimited access to all the
                    platform's services for 24 hours. After this period, the
                    subscription fee of $39 per month applies. Please note that
                    your subscription automatically renews every month. If you
                    no longer wish to use Tracelo Phone Report, you can cancel
                    your active subscription at any time from the website.
                  </p>
                </Collapse.Panel>
              </Collapse>
            </div>

            <div className="faqs__collapse_accordion">
              <Collapse
                activeKey={activeKey === "8" ? ["8"] : []}
                onChange={() => handleCollapseChange("8")}
                expandIconPosition="end"
                expandIcon={({ isActive }) => (
                  <img
                    src={isActive ? accordionMinusIcon : accordionPlusIcon}
                    width="20px"
                    height="20px"
                    alt=""
                    // onClick={() => handleCollapseChange("8")}
                  />
                )}
              >
                <Collapse.Panel
                  header="I want to pay for tracking a phone number, but my payment is declined. What now?"
                  key="8"
                >
                  <p>
                    Your payment might have been declined because there are
                    insufficient funds on your credit card. There also might
                    have been some problems with your transaction. We recommend
                    that you contact your bank and ask for help. As an option,
                    you can write to us at support@Tracelo Phone Report.com and
                    provide us with the details.
                  </p>
                </Collapse.Panel>
              </Collapse>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FaqComponent;
