import React from "react";
import "./phoneReputationScoreDetails.scss";
import ModalComponent from "../../../common/Modal/modal";
import { t } from "i18next";

const PhoneReputationScoreDetails = ({
  isOpen,
  onClose,
  closable,
  className,
}) => {
  return (
    <ModalComponent
      isOpen={isOpen}
      onClose={onClose}
      closable={closable}
      className={className}
    >
        <div className="mga__attribute_popup">
                <div className="attribute_info_popup">
                        <div className="information_popup_text">
                        <h2>Phone Reputation Score Details</h2>
                        </div>
                        <div className="attribute_info_content">
                        <div className="info_cmnt_text_wrap">
                                <h3>Risky</h3>
                                <p>Is this phone number associated with fraudulent activity, scams, robo calls, fake accounts, or other unfriendly behavior?</p>
                        </div>
                        <div className="info_cmnt_text_wrap">
                                <h3>Recent Abuse</h3>
                                <p>Has this phone number been associated with malicious behavior within the past few days?</p>
                        </div>
                        <div className="info_cmnt_text_wrap">
                                <h3>Fraudulent Activity</h3>
                                <p>Has this phone number been associated with recent or ongoing fraud?</p>
                        </div>
                        <div className="info_cmnt_text_wrap">
                                <h3>Spam Reported</h3>
                                <p>Has the phone number has recently been reported for spam or harassing calls/texts?</p>
                        </div>
                        <div className="info_cmnt_text_wrap">
                                <h3>Line Type</h3>
                                <p>The type of line this phone number is associated with (Toll Free, Wireless, Landline, Satellite, VOIP, Premium Rate, Pager) or "N/A" if unknown. Line Type can play an important role for understanding phone number reputation.</p>
                        </div>
                        <div className="info_cmnt_text_wrap">
                                <h3>Prepaid</h3>
                                <p>Is this phone number associated with a prepaid service plan?</p>
                        </div>
                        </div>
                </div>
        </div>
    </ModalComponent>
  );
};

export default PhoneReputationScoreDetails;
