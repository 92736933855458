import React from "react";
import { NextArrow } from "../../../assets/images";

const NextArrowComponent = (props) => {
  const { onClick, icon } = props;
  return (
    // eslint-disable-next-line
    <a className="slick-arrow slick-next outside" onClick={onClick}>
      {icon || <img src={NextArrow} alt="" />}
    </a>
  );
};

export default NextArrowComponent;
