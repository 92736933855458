import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import AxiosInstance from '../../../utils/axios';
export const subscriptionStatus = createAsyncThunk(
  'subscriptionStatus',
  async (sectionKey) => {
    try {
      const response = await AxiosInstance.get(
        `/phone_report/subscription_status/${sectionKey}`
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data.message;
      } else {
        throw error;
      }
    }
  }
);

const subscriptionStatusSlice = createSlice({
  name: "subscriptionStatus",
  initialState: {
    isLoading: false,
    subscriptionStatus: null,
    isError: false,
    errorMessage: ""
  },
  extraReducers: {
    [subscriptionStatus.pending]: (state) => {
      state.isLoading = true;
    },
    [subscriptionStatus.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.subscriptionStatus = action.payload;
      state.isError = false;
      state.errorMessage = "";
    },
    [subscriptionStatus.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.error.message;
    }
  }
});

export default subscriptionStatusSlice.reducer;
