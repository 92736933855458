import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AxiosInstance from "../../../utils/axios";
export const getPricingDetailsBasedOnSection = createAsyncThunk(
  "getPricingDetailsBasedOnSection",
  async (data) => {
    try {
      const response = await AxiosInstance.get(
        `/pricing/get_price/${data.title}/${data.cur}`
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data.message;
      } else {
        throw error;
      }
    }
  }
);

const getPricingDetailsBasedOnSectionSlice = createSlice({
  name: "getPricingDetailsBasedOnSection",
  initialState: {
    isLoading: false,
    getPhoneReportDetailsData: null,
    isError: false,
    errorMessage: "",
  },
  extraReducers: {
    [getPricingDetailsBasedOnSection.pending]: (state) => {
      state.isLoading = true;
    },
    [getPricingDetailsBasedOnSection.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.getPhoneReportDetailsData = action.payload;
      state.isError = false;
      state.errorMessage = "";
    },
    [getPricingDetailsBasedOnSection.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.error.message;
    },
  },
});

export default getPricingDetailsBasedOnSectionSlice.reducer;
