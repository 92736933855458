import React, { useEffect, useState } from "react";
import "./previewPopup.scss";
import { previewPopupImg } from "../../assets/images";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useAppContext } from "./previewPopupContext";
import { isValidPhoneNumber, parsePhoneNumber } from "libphonenumber-js";
import { connect } from "react-redux";
import Notify from "../../../components/common/Notify/notify";
import { useTranslation } from "react-i18next";
import { Cookies } from "react-cookie";
import { sendLocation } from "../../redux/slice";
import { createFirstReport } from "../../redux/slice/createFirstReportSlice";

const PreviewPopupComponent = (props) => {
  const { callsendLocation } = props;
  const {
    receiverNumber,
    message,
    receiverCountry,
    countryCode,
    countryCodeinput,
    messageError,
    number,
    setNumber,
    receiverCountryCode,
    setReceiverNumber,
    setSucessPreviewSend,
    setEditEnable,
  } = useAppContext();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const cookies = new Cookies();
  const lang = cookies.get("lang");
  const [searchparams] = useSearchParams();
  const searchNumber = Object.fromEntries([...searchparams]);

  const [popNumberError, setPopNumberError] = useState(null); // State for error message
  const [formatedNumber, setFormatedNumber] = useState(null); // State for error message

  const handleSendMessage = async () => {
    if (receiverNumber) {
      if (
        isValidPhoneNumber(
          receiverNumber,
          `${
            receiverCountry
              ? receiverCountry.toString().toUpperCase()
              : countryCodeinput.toUpperCase()
          }`
        )
      ) {
        if (!messageError) {
          const sender_mobile_number = `+${number}`
            .toString()
            .replace(countryCode, "");
          const receiver_mobile_number = `+${receiverNumber}`
            .toString()
            .replace(receiverCountryCode, "");
          let data = {
            receiver_country_code:
              receiverCountryCode === 1 ? "+1" : receiverCountryCode,
            receiver_mobile_number: receiver_mobile_number,
            message: message,
          };
          let result;
          if (searchNumber.id && searchNumber.id !== "undefined") {
            const phoneReportId = searchNumber.id.replace("?Edit=true", "");
            data = { ...data, phone_report_id: phoneReportId };
            result = await callsendLocation(data);
          } else {
            data = {
              ...data,
              sender_country_code:
                countryCode === 1 ? "+1" : countryCode.replace(" ", ""),
              sender_mobile_number: sender_mobile_number,
            };
            result = await props.callCreateFirstReport(data);
            if (result.type === "createFirstReport/fulfilled") {
              navigate(`/${lang}/dashboard`);
              document.body.style.overflow = "unset";
              setReceiverNumber(receiverCountryCode);
              setNumber(countryCode);
              setSucessPreviewSend(true);
              Notify("success", t("NOTIFY_SEND_LOCATION"), "");
            } else if (result.type === "sendLocation/rejected") {
              document.body.style.overflow = "unset";
            }
          }

          if (result.type === "sendLocation/fulfilled") {
            Notify("success", t("NOTIFY_SEND_LOCATION"), "");
            document.body.style.overflow = "unset";
            setReceiverNumber(receiverCountryCode);
            setNumber(countryCode);
            setSucessPreviewSend(true);
            navigate(
              `/${lang}/access-report?D=${
                countryCode === 1 ? "+1" : countryCode.replace(" ", "")
              }&n=${sender_mobile_number}&id=${searchNumber.id}`
            );
          } else if (result.type === "sendLocation/rejected") {
            document.body.style.overflow = "unset";
          }
        }
      } else {
        setPopNumberError(t("ERROR_VALID_NUMBER_DASHBOARD"));
      }
    } else {
      setPopNumberError(t("ERROR_ENTER_NUMBER_DASHBOARD"));
    }
  };
  useEffect(() => {
    const phoneNumber = parsePhoneNumber("+" + receiverNumber.toString());
    if (phoneNumber) {
      setFormatedNumber(phoneNumber.formatInternational());
    }
  }, [receiverNumber]);

  const handleClick = () => {
    setEditEnable(true);
    setTimeout(() => {
      let locatingmodalurl = cookies.get("locatingmodalurl");
      locatingmodalurl = locatingmodalurl || `/${lang}/dashboard`;
      navigate(locatingmodalurl);
    }, 2000);
  };
  return (
    <div className="location_popup">
      <div className="location_popup-container">
        <div className="location-info">
          <div className="location-box">
            <h2
              dangerouslySetInnerHTML={{ __html: t("PR_PREVIEW_POPUP_TITLE") }}
            ></h2>
            <h2>
              <a>{formatedNumber}</a>
            </h2>
            <p>{t("PR_PREVIEW_POPUP_DESCRIPTION")}</p>
            {popNumberError && (
              <p className="error-message">{popNumberError}</p>
            )}{" "}
            <div className="location_image_container">
              <img src={previewPopupImg} alt="" className="location_image" />
              <div className="text-overflow">
                <p className="message_overlay">{message}</p>
              </div>
            </div>
            <div className="location_button">
              <p>
                <Link onClick={handleSendMessage} className="defult_btn">
                  {t("PR_SEND")}
                </Link>
              </p>
              <p>
                <Link
                  onClick={() => handleClick()}
                  className="defult_btn btn_border"
                >
                  {t("PR_EDIT")}
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    sendLocation: state.sendLocation,
    callCreateFirstReport: state.callCreateFirstReport,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    callsendLocation: (data) => dispatch(sendLocation(data)),
    callCreateFirstReport: (data) => dispatch(createFirstReport(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PreviewPopupComponent);
