import React, { useState } from "react";
import { t } from "i18next";
import { highLevelGraphImg, nodataIcon } from "../../../assets/images";
import { renderValue, toCamelCase } from "../../../utils/commonUtils";
import { informationIcon } from "../../../assets/images";
import PhoneReputationScoreDetails from "../Modals/phoneReputationScoreDetails/phoneReputationScoreDetails";

const PhoneReputation = ({ reportDetail }) => {
  const { phone_reputation_score } = reportDetail?.phoneReport || {};
  const { data, status } = phone_reputation_score || {};

  const [infoModal, setInfoModal] = useState(false);
  return (
    <>
      <div
        className="unlock_premium_sec white-bg-wrap"
        id={toCamelCase(t("PR_PREMIUM_REPORT_TITLE"))}
      >
        <div className="ds--unlock__title left-content main_title toggle_area">
          <h2>
            {t("PR_PREMIUM_REPORT_TITLE")}{" "}
            <img
              src={informationIcon}
              alt=""
              onClick={() => setInfoModal(true)}
            />
          </h2>
          {/* <div className="premium_toggle">
          <Link to={""}>
            <img src={premiumToggleBar} alt="" />
          </Link>
        </div> */}
        </div>
        {status === "found" ? (
          <div className="ds-unlock-content">
            <div className="reputation-phn-img">
              <img src={highLevelGraphImg} alt="" />
            </div>
            <div className="reputation-phn-content">
              <p>{t("PR_REPUTATION_MESSAGE")} </p>
              <ul className="reputation_listing_area">
                <li>
                  {t("PR_PREMIUM_REPORT_TEXT_1")}
                  <span>{renderValue(data.risk_level)}</span>
                </li>
                <li>
                  {t("PR_PREMIUM_REPORT_TEXT_2")}
                  <span>{renderValue(data.recent_abuse)}</span>
                </li>
                <li>
                  {t("PR_PREMIUM_REPORT_TEXT_3")}
                  <span>{renderValue(data.fraudulent_activity)}</span>
                </li>
                <li>
                  {t("PR_PREMIUM_REPORT_TEXT_4")}
                  <span>{renderValue(data.spam_reported)}</span>
                </li>
                <li>
                  {t("PR_PREMIUM_REPORT_TEXT_5")}
                  <span>{renderValue(data.line_type)}</span>
                </li>
                <li>
                  {t("PR_PREMIUM_REPORT_TEXT_6")}
                  <span>{renderValue(data.prepaid)}</span>
                </li>
              </ul>
            </div>
          </div>
        ) : (
          <p className="data_notfound"><img src={nodataIcon} alt="" /> Record Not Found</p>
        )}
      </div>
      <PhoneReputationScoreDetails
        isOpen={infoModal}
        onClose={() => setInfoModal(false)}
        closable={true}
        className="multiples_popup__wrap_close_button"
      />
    </>
  );
};

export default PhoneReputation;
