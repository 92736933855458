import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import SubscriptionListPage from "./subscription";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe
} from "@stripe/react-stripe-js";

import Loader from "../../../components/common/loader/loader";
import Notify from "../../../../components/common/Notify/notify";
import {retryPayment} from "../../../redux/slice/retryPaymentSlice";
import {pendingCancel} from "../../../redux/slice/pendingCancelSlice";
import {generatePayment} from "../../../redux/slice/generatePaymentSlice";
import {subscriptionList} from "../../../redux/slice/subscriptionListSlice";
import {renewSubscription} from "../../../redux/slice/renewSubscriptionSlice";
import {confirmPayment, getSubscriptionName} from "../../../utils/commonUtils";
import {cancelSubscription} from "../../../redux/slice/cancelSubscriptionSlice";
import {updatePaymentMethod} from "../../../redux/slice/updatePaymentMethodSlice";
import {createSubscription} from "../../../redux/slice/createSubscriptionSlice";

const SubscriptionList = (props) => {
  const {
    callSubscriptionList,
    subscriptionList,
    callCancelSubscription,
    callPendingCancel,
    callGeneratePayment,
    callRenewSubscription,
    callRetryPayment,
    callUpdatePaymentMethod,
    callCreateSubscription
  } = props;

  const stripe = useStripe();
  const elements = useElements();
  const [isLoading, setIsLoading] = useState(false);
  const [retryModal, setRetryModal] = useState(false);
  const [stripeError, setStripeError] = useState("");
  const [currentSubscription, setCurrentSubscription] = useState("");
  const [reactive, setReactive] = useState(false);

  useEffect(() => {
    callSubscriptionList();
  }, []);

  const handleCancelSubscription = async (subscriptionId) => {
    setIsLoading(true);
    const res = await callCancelSubscription(subscriptionId);
    if (res.type === "cancelSubscription/fulfilled") {
      Notify("success", res.payload.message, "");
      callSubscriptionList();
    } else {
      Notify("error", res.error.message, "");
    }
    setIsLoading(false);
  };

  const handlePendingCancel = async (subscriptionId) => {
    setIsLoading(true);
    const res = await callPendingCancel(subscriptionId);
    if (res.type === "pendingCancel/fulfilled") {
      Notify("success", res.payload.message, "");
      callSubscriptionList();
    } else {
      Notify("error", res.error.message, "");
    }
    setIsLoading(false);
  };

  const handleSubscription = async (subscription, isReactive = false) => {
    const priceTitle = getSubscriptionName(subscription?.name);
    if (!stripe || !elements) {
      return;
    }
    setIsLoading(true);
    const payload = {
      price_title: priceTitle,
      type: "subscription"
    };
    const res = await callGeneratePayment(payload);
    if (res.type === "generatePayment/fulfilled") {
      const {client_secret, payment_method, subscription_id} =
        res.payload.data || {};
      const {error} = await confirmPayment(
        stripe,
        client_secret,
        payment_method
      );
      if (error) {
        setRetryModal(true);
        setReactive(isReactive);
        setCurrentSubscription(priceTitle);
      } else {
        const payload = isReactive
          ? {subscription_id, subscription_name: priceTitle}
          : {subscription_id};
        const res = isReactive
          ? await callRenewSubscription(payload)
          : await callCreateSubscription(payload);

        if (res?.type?.endsWith("fulfilled")) {
          Notify("success", res.payload.message, "");
          callSubscriptionList();
        } else {
          Notify("error", res?.error?.message, "");
        }
      }
    } else {
      Notify("error", res.error.message, "");
    }
    setIsLoading(false);
  };

  const onRetry = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    setIsLoading(true);
    setRetryModal(false);
    const cardNumberElement = elements.getElement(CardNumberElement);
    const {error, paymentMethod} = await stripe.createPaymentMethod({
      type: "card",
      card: cardNumberElement
    });
    if (error) {
      setIsLoading(false);
      setStripeError(error.message);
      return;
    }
    const data = {
      price_title: currentSubscription,
      type: "subscription"
    };
    const res = await callRetryPayment(data);
    if (res.type === "retryPayment/fulfilled") {
      const {client_secret: clientSecret, subscription_id} =
        res.payload.data || {};
      const {error} = await confirmPayment(
        stripe,
        clientSecret,
        paymentMethod.id
      );
      if (error) {
        elements.getElement(CardNumberElement).clear();
        elements.getElement(CardExpiryElement).clear();
        elements.getElement(CardCvcElement).clear();
        setRetryModal(false);
        setTimeout(() => {
          setRetryModal(true);
        }, 500);
      } else {
        const payload = reactive
          ? {subscription_id, subscription_name: currentSubscription}
          : {subscription_id};
        const res = reactive
          ? await callRenewSubscription(payload)
          : await callCreateSubscription(payload);
        if (res?.type?.endsWith("fulfilled")) {
          Notify(
            "success",
            res.payload.message || "Subscription created successfully",
            ""
          );
          callSubscriptionList();
        } else {
          Notify("error", res?.error?.message, "");
        }
        callUpdatePaymentMethod(paymentMethod.id);
      }
    } else {
      Notify("error", res.error.message, "");
    }
    setIsLoading(false);
  };
  return (
    <>
      {isLoading && <Loader />}
      <SubscriptionListPage
        subscriptionList={subscriptionList?.subscriptionList?.data}
        handleCancelSubscription={handleCancelSubscription}
        handlePendingCancel={handlePendingCancel}
        handleSubscription={handleSubscription}
        retryModal={retryModal}
        onRetry={onRetry}
        setRetryModal={setRetryModal}
        setStripeError={setStripeError}
        stripeError={stripeError}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    authenticate: state.authenticate,
    subscriptionList: state.subscriptionList
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    callSubscriptionList: (data) => dispatch(subscriptionList(data)),
    callCancelSubscription: (data) => dispatch(cancelSubscription(data)),
    callPendingCancel: (data) => dispatch(pendingCancel(data)),
    callGeneratePayment: (data) => dispatch(generatePayment(data)),
    callRenewSubscription: (data) => dispatch(renewSubscription(data)),
    callRetryPayment: (data) => dispatch(retryPayment(data)),
    callUpdatePaymentMethod: (data) => dispatch(updatePaymentMethod(data)),
    callCreateSubscription: (data) => dispatch(createSubscription(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionList);
