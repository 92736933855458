import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import AxiosInstance from "../../../utils/axios";
export const pendingCancel = createAsyncThunk(
  "pendingCancel",
  async (subscriptionId) => {
    try {
      const response = await AxiosInstance.post(
        `/subscription/reactive_pending_cancel`,
        {
          subscription_id: subscriptionId
        }
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data.message;
      } else {
        throw error;
      }
    }
  }
);

const pendingCancelSlice = createSlice({
  name: "pendingCancel",
  initialState: {
    isLoading: false,
    pendingCancel: null,
    isError: false,
    errorMessage: ""
  },
  extraReducers: {
    [pendingCancel.pending]: (state) => {
      state.isLoading = true;
    },
    [pendingCancel.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.pendingCancel = action.payload;
      state.isError = false;
      state.errorMessage = "";
    },
    [pendingCancel.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.error.message;
    }
  }
});

export default pendingCancelSlice.reducer;
