import React from 'react'
import ContactUsComponent from './contactUs'

const ContactUs=() => {
  return (
        <ContactUsComponent/>
  )
}

export default ContactUs
