import React from "react";
import { t } from "i18next";
import {
  socialFacebookIcon,
  socialInstagramIcon,
  profileLinkIcon,
  socialSnapchatIcon,
  socialLinkedinIcon,
  dribbleIcon,
  flickrIcon,
  githubIcon,
  pinterestIcon,
  redditIcon,
  skypeIcon,
  tumblrIcon,
  twitterIcon,
  whatsappIcon,
  youtubeIcon,
  tikTokIcon,
  weChatIcon,
  telegramIcon,
  discordIcon,
  twitchIcon,
  threadsIcon,
  possiblePhotosImg,
  nodataIcon
} from "../../../assets/images";
import UnlockReport from "../unlockReport/unlockReport";
import { toCamelCase } from "../../../utils/commonUtils";

const iconMapping = {
  Facebook: socialFacebookIcon,
  Skype: skypeIcon,
  Telegram: telegramIcon,
  WhatsApp: whatsappIcon
};

const SocialProfile = ({ isLocked, sectionName, reportDetail }) => {
  const { social_media_profiles } = reportDetail?.phoneReport || {};
  const { data, status } = social_media_profiles || {};

  const getIcon = (name) => {
    return iconMapping[name] || profileLinkIcon;
  };

  return isLocked ? (
    <UnlockReport
      title={t("PR_SOCIAL_PROFILES")}
      image={possiblePhotosImg}
      buttonText={t("PR_UNLOCK_THIS_SECTION").toUpperCase()}
      shouldKnowText={t("PR_UNLOCK_TITLE")}
      knownText={t("PR_SOCIAL_PROFILE_MESSAGE")}
      sectionName={sectionName}
      pricingSectionName={"social_media_profiles"}
    />
  ) : (
    <div
      className="ds--social-profile white-bg-wrap"
      id={toCamelCase(t("PR_SOCIAL_PROFILES"))}
    >
      <div className="unlock_title unlock-description">
        <h2>{t("PR_SOCIAL_PROFILES")}</h2>
          {status === "found" && <p>{t("PR_SOCIAL_PROFILE_SUBTITLE")}</p>}
      </div>
        {status === "found" && data.length > 0 ? (
          <div className="social-profiles">
            {data.map((profile) => (
              <div className="social-profiles--boxes" key={profile._id}>
                <div className="social-profiles-icon">
                  <img src={getIcon(profile.name)} alt={profile.name} />
                </div>
                <div className="social-profiles-content">
                  <span>{profile.name}</span>
                  <p>{profile.url}</p>
                </div>
                <div className="social-profiles-btn">
                  <p>
                    <a
                      href={profile.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t("PR_SOCIAL_PROFILE_BUTTON")}{" "}
                      <img src={profileLinkIcon} alt="Link Icon" />
                    </a>
                  </p>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <p className="data_notfound"><img src={nodataIcon} alt="" /> Record Not Found</p>
        )}
    </div>
  );
};

export default SocialProfile;
