import React from "react";
import { t } from "i18next";
import {
  premiumToggleBar,
  accordionIcon,
  leakedDataIcon,
  safeDataIcon,
  dataLeakCheckerImg,
  nodataIcon
} from "../../../assets/images";
import { Collapse } from "antd";
import UnlockReport from "../unlockReport/unlockReport";
import { toCamelCase } from "../../../utils/commonUtils";

const DataLeakChecker = ({ isLocked, sectionName, reportDetail }) => {
  const dataLeakChecker = reportDetail?.phoneReport?.data_leak_checker || {};
  const data = dataLeakChecker?.data || [];
  const status = dataLeakChecker?.status || "not_found";

  return isLocked ? (
    <UnlockReport
      title={t("PR_PREMIUM_SECTION_TITLE_5")}
      image={dataLeakCheckerImg}
      buttonText={t("PR_UNLOCK_THIS_SECTION").toUpperCase()}
      shouldKnowText={t("PR_UNLOCK_TITLE")}
      knownText={t("PR_DATA_LEAK_CHECKER_MESSAGE")}
      sectionName={sectionName}
      pricingSectionName={"data_leak_checker"}
    />
  ) : (
    <div
      className="ds--checker-info white-bg-wrap"
      id={toCamelCase(t("PR_PREMIUM_SECTION_TITLE_5"))}
    >
      <div className="ds--unlock__title left-content main_title toggle_area">
        <h2>{t("PR_PREMIUM_SECTION_TITLE_5")}</h2>
        <div className="premium_toggle">
          <a href="javascript:void(0)">
            <img src={premiumToggleBar} alt="" />
          </a>
        </div>
      </div>
        {status === "found" && data.length > 0 ? (
          <>
            <div className="ds--accordion-collapse">
              <Collapse
                items={[
                  {
                    key: "1",
                    label: t("PR_LEARN_MORE"),
                    children: <p>{t("PR_LEARN_MORE_DESCRIPTION_3")}</p>
                  }
                ]}
                expandIconPosition="end"
                expandIcon={({ isActive }) => (
                  <img
                    src={accordionIcon}
                    width="2em"
                    height="2em"
                    alt="Accordion Icon"
                  />
                )}
              />
            </div>
            {data.map((item, index) => {
              const {
                name = "N/A",
                website = "N/A",
                breach_date,
                description = "N/A",
                logo_path,
                compromised_data = []
              } = item?.leak_details || {};

              return (
                <div
                  key={index}
                  className="track__accordion ds--accordion-collapse"
                >
                  <Collapse
                    items={[
                      {
                        key: "1",
                        label: name || website,
                        children: (
                          <div className="track_description">
                          <div
                            className={
                              item?.has_leak
                                ? "leaked-data-box data_box_info"
                                : "safe-data-box data_box_info"
                            }
                          >
                            <div className="safedata_title">
                              <h3>
                                <img
                                  src={
                                    item?.has_leak
                                      ? leakedDataIcon
                                      : safeDataIcon
                                  }
                                  alt="Data Icon"
                                />{" "}
                                {item?.has_leak
                                  ? t("PR_DATA_LEAK_UNSAFE")
                                  : t("PR_DATA_LEAK_SAFE")}
                              </h3>
                            </div>
                          </div>
                          {logo_path && (
                            <div className="track_logo">
                              <img src={logo_path} alt={name} />
                            </div>
                          )}
                          {breach_date && (
                            <div className="track_date">
                              <span>
                                {t("PR_BREACH_DATE")}:{" "}
                                {new Date(breach_date).toLocaleDateString()}
                              </span>
                            </div>
                          )}
                          <div className="track__info">
                            <p
                              dangerouslySetInnerHTML={{ __html: description }}
                            ></p>
                          </div>
                          {compromised_data.length > 0 && (
                            <div className="track_countries">
                              <span>COMPROMISED DATA :</span>
                              <ul>
                                {compromised_data.map((dataItem, idx) => (
                                  <li key={idx}>{dataItem}</li>
                                ))}
                              </ul>
                            </div>
                          )}
                        </div>
                        )
                      }
                    ]}
                    expandIconPosition="end"
                    expandIcon={({ isActive }) => (
                      <img
                      src={accordionIcon}
                      width="2em"
                      height="2em"
                      alt="Accordion Icon"
                      />
                    )}
                  />
                </div>
              );
            })}
          </>
        ) : (
          <p className="data_notfound"><img src={nodataIcon} alt="" /> Record Not Found</p>
        )}
    </div>
  );
};

export default DataLeakChecker;
