import React from "react";
import "./myAccount.scss";
import { Link } from "react-router-dom";
import { Form, Input, Select } from "antd";
import countryList from "react-select-country-list";
import Timestamp from "react-timestamp";
import { t } from "i18next";
import SubscriptionList from "./subscriptionList";

const MyAccountComponent = ({
  details,
  handleUpdateDetails,
  loading,
  errors,
  authenticate,
  handleChangeDetails,
  handleChangeCountry,
}) => {
  const countryOptions = countryList().getData();
  return (
    <div className="main_root">
      <div className="wpb_info_wrapper">
        <div className="main_container">
          <div className="mt-20 wpb_content_account account_bg_wrap">
            <div className="account_title">
              <h2>{t("PR_ACCOUNT_INFORMATION")}</h2>
            </div>
            <div className="account_info_inner">
              <div className="wi-50 account_address">
                <strong>{t("UNSUBSCRIBE_EMAIL_LABEL")}</strong>
                <p>
                  <a>{authenticate && authenticate.data.email} </a>
                </p>
              </div>
              <div className="wi-50 account_address">
                <strong>{t("PR_MEMBER_SINCE")}</strong>
                <p>
                  <a>
                    <Timestamp
                      date={authenticate && authenticate.data.createdAt}
                      options={{ twentyFourHour: true }}
                    />
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="wpb_content_billing account_bg_wrap">
            <div className="account_title">
              <h2>{t("PR_BILLING_INFORMATION")}</h2>
            </div>
            <div className="form_group_warp">
              <Form>
                <div className="account-container">
                  <div className="field-account">
                    <label className="lbl_wpb_wrapper" htmlFor="first-name">
                      {t("SETTINGS_FIRST_NAME_LABEL")}{" "}
                      {/* <span className="required-field">*</span> */}
                    </label>
                    <Input
                      className={`ant-input-prefix ${
                        errors.first_name && "error"
                      }`}
                      id="first-name"
                      name="first_name"
                      inputMode="text"
                      placeholder="First Name"
                      value={details.first_name}
                      onChange={(e) => handleChangeDetails(e)}
                      maxLength={30}
                    />
                    {errors.first_name && (
                      <p className="error-message mt-2">{errors.first_name}</p>
                    )}
                  </div>
                  <div className="field-account">
                    <label className="lbl_wpb_wrapper" htmlFor="last-name">
                      {t("SETTINGS_LAST_NAME_LABEL")}{" "}
                    </label>
                    <Input
                      className={`ant-input-prefix ${
                        errors.last_name && "error"
                      }`}
                      id="last-name"
                      inputMode="text"
                      name="last_name"
                      placeholder="Last Name"
                      value={details.last_name}
                      onChange={(e) => handleChangeDetails(e)}
                      maxLength={30}
                    />
                    {errors.last_name && (
                      <p className="error-message mt-2">{errors.last_name}</p>
                    )}
                  </div>
                  <div className="field-account address">
                    <label className="lbl_wpb_wrapper" htmlFor="street-address">
                      {t("SETTINGS_ADDRESS_LABEL")}{" "}
                    </label>
                    <Input
                      className={`ant-input-prefix ${
                        errors.address && "error"
                      }`}
                      id="street-address"
                      name="address"
                      inputMode="text"
                      placeholder="Los Angeles, 9 Stonehedge Dr S"
                      value={details.address}
                      onChange={(e) => handleChangeDetails(e)}
                      maxLength={120}
                    />
                    {errors.address && (
                      <p className="error-message mt-2">{errors.address}</p>
                    )}
                  </div>
                  <div className="field-account">
                    <label className="lbl_wpb_wrapper" htmlFor="country">
                      {t("SETTINGS_COUNTRY_LABEL")}{" "}
                      {/* <span className="required-field">*</span>{" "} */}
                    </label>
                    <Select
                      defaultValue={details.country}
                      className="ant-input-prefix"
                      name="country"
                      onChange={handleChangeCountry}
                      value={details.country}
                      options={countryOptions}
                      showSearch
                      filterOption={(input, option) => {
                        if (option && option.label) {
                          const optionLabel = option.label
                            .toString()
                            .toLowerCase();
                          const inputValue = input.toLowerCase();
                          return optionLabel.indexOf(inputValue) !== -1;
                        }
                        return false;
                      }}
                    />
                    {/* <select
                      className="ant-input-prefix"
                      name="country"
                      onChange={(e) => handleChangeDetails(e)}
                      value={details.country}
                    >
                      <option value="" disabled selected>
                        {t("PR_SELECT_COUNTRY")}
                      </option>
                      {countryOptions.map((item) => (
                        <option key={item.value} value={item.value}>
                          {item.label}
                        </option>
                      ))}
                    </select> */}
                    {errors.country && (
                      <p className="error-message mt-2">{errors.country}</p>
                    )}
                  </div>
                  <div className="field-account">
                    <label className="lbl_wpb_wrapper" htmlFor="city">
                      {t("SETTINGS_CITY_LABEL")}{" "}
                      {/* <span className="required-field">*</span>{" "} */}
                    </label>
                    <Input
                      className="ant-input-prefix"
                      id="city"
                      name="city"
                      inputMode="text"
                      placeholder="Los Angeles"
                      value={details.city}
                      onChange={(e) => handleChangeDetails(e)}
                      maxLength={35}
                    />
                    {errors.city && (
                      <p className="error-message mt-2">{errors.city}</p>
                    )}
                  </div>
                  <div className="field-account">
                    <label className="lbl_wpb_wrapper" htmlFor="postal-code">
                      {t("SETTINGS_POSTAL_CODE_LABEL")}{" "}
                      {/* <span className="required-field">*</span> */}
                    </label>
                    <Input
                      className="ant-input-prefix"
                      id="postal-code"
                      name="zipcode"
                      inputMode="text"
                      placeholder="90077"
                      value={details.zipcode}
                      maxLength={10}
                      onChange={(e) => handleChangeDetails(e)}
                    />
                    {errors.zipcode && (
                      <p className="error-message mt-2">{errors.zipcode}</p>
                    )}
                  </div>
                </div>
                <button
                  className="payment__account"
                  type="button"
                  onClick={handleUpdateDetails}
                  disabled={loading}
                >
                  {t("PR_SAVE_ORDER")}
                </button>
              </Form>
            </div>
          </div>
          <SubscriptionList />
        </div>
      </div>
    </div>
  );
};

export default MyAccountComponent;
