import React, { useEffect, useState } from "react";
import { menuPhoneIcon, overviewlockIcon } from "../../../assets/images";
import "./overViewMenu.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Drawer } from "antd";
import { IoCloseOutline, IoMenu } from "react-icons/io5";
import { RiExpandUpDownLine } from "react-icons/ri";
import { getSectionName, toCamelCase } from "../../../utils/commonUtils";
import { t } from "i18next";

const OverViewMenuComponent = ({ getPhoneReportDetails, number }) => {
  const [open, setOpen] = useState(false);
  const [unLockIcon, setUnLockIcon] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    const unlockSections =
      getPhoneReportDetails?.getPhoneReportDetailsData?.data?.unlocked;
    const array = [];
    if (unlockSections?.length > 0) {
      for (let i = 0; i < unlockSections?.length; i++) {
        array.push(getSectionName(unlockSections[i]));
      }
    }
    setUnLockIcon(["Phone GPS Location", ...array]);
  }, [getPhoneReportDetails?.getPhoneReportDetailsData?.data]);
  // -> New sections
  const menuItems = [
    "Phone GPS Location",
    "General Phone Information",
    "Phone Reputation Score",
    "Phone Owner Information",
    "Email Addresses",
    "Other Phone Numbers",
    "Phone Approximate Location",
    "Possible Photos",
    "Social Profiles",
    "Work History",
    "Educational Background",
    "Address History",
    "Relationships & Associates",
    "Name & Ancestry",
    "Data Leak Checker",
    "Other Information",
    "Nuisance Call Detection",
    "Neighbour Spoofing",
    "Search Volume",
    "Monitoring",
    "Download",
  ];

  // const unLockIcon = [
  //   "Phone GPS Location",
  //   "General Phone Information",
  //   "Phone Reputation Score",
  //   "Nuisance Call Detection",
  //   "Neighbour Spoofing",
  //   "Search Volume",
  // ];

  // -> Old sections
  // const menuItems = [
  //   "Phone GPS Location",
  //   "Unlock Full Report",
  //   "Phone Reputation Score",
  //   "Phone Type",
  //   "Possible Photos",
  //   "Possible Jobs",
  //   "Possible Education",
  //   "Email Addresses",
  //   "Name & Ancestry",
  //   "Possible Associates",
  //   "Neighbour Spoofing",
  //   "Search Volume",
  //   "Other Phone Numbers",
  //   "Phone Approximate Location",
  //   "Social Profiles",
  //   "Address History",
  //   "Data Leak Checker",
  //   "Other",
  // ];

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const navigateOverViewMenu = (sectionName) => {
    // setActiveMenuItem(sectionName);
    const camelCaseSectionName = toCamelCase(sectionName);
    window.location.hash = camelCaseSectionName;
    window.location.href += `#${camelCaseSectionName}`;
    let queryString = location.search;
    const newUrl = location.pathname + (queryString ? queryString : "");
    navigate(`${newUrl}#${camelCaseSectionName}`);
    setOpen(false);
  };

  return (
    <div className="premium-menu-width" id="overview">
      <div className="menu_bg_wrap desktop-menu">
        <div className="overview_menu_head">
          <span>
            <a href="tel:+1 (819) 674-0000">
              <img src={menuPhoneIcon} alt="" />{" "}
              {number ? number : "+1 (819) 674-0000"}
            </a>
          </span>
        </div>
        <div className="overview_menu-title">
          {menuItems.map((item) => (
            <div
              key={item}
              className={`menu_item`}
              onClick={() => navigateOverViewMenu(item)}
            >
              <Link to={""}>
                {item}
                {!unLockIcon.includes(item) && (
                  <img src={overviewlockIcon} alt="" />
                )}
              </Link>
            </div>
          ))}
        </div>
      </div>
      <div className={`menu_bg_wrap menu-mobile ${open ? "active" : ""}`}>
        <div onClick={showDrawer} className="fixed-mobile-menu">
          <div className="mobile-active-menu">
            <IoMenu className="mobile-menu-icon" />
            <p>{t("PR_OVERVIEW")}</p>
          </div>
          <RiExpandUpDownLine className="mobile-menu-updown-icon" />
        </div>
        <Drawer
          title=""
          placement={"bottom"}
          closable={false}
          onClose={onClose}
          open={open}
          key={"bottom"}
          className="sidebar-mobile-menu"
        >
          <div className="menu-head-mobile" onClick={onClose}>
            <IoCloseOutline />
            <p>{t("PR_CLOSE_MENU")}</p>
          </div>
          <div className="overview_menu-title menu-title-mobile">
            {menuItems.map((item) => (
              <div
                key={item}
                className={`menu_item`}
                onClick={() => navigateOverViewMenu(item)}
              >
                <Link to={""}>
                  {item}{" "}
                  {!unLockIcon.includes(item) && (
                    <img src={overviewlockIcon} alt="" />
                  )}
                </Link>
              </div>
            ))}
          </div>
        </Drawer>
      </div>
    </div>
  );
};

export default OverViewMenuComponent;
