import React from "react";
import { t } from "i18next";
import { historyLocationIcon, nodataIcon, possiblePhotosImg } from "../../../assets/images";
import UnlockReport from "../unlockReport/unlockReport";
import { toCamelCase } from "../../../utils/commonUtils";

const formatAddress = (addressObject) => {
  const { address, city, state, country } = addressObject;

  const formattedAddress = [address, city, state, country]
    .filter(Boolean)
    .join(", ");
  return formattedAddress || "No Address Available";
};

const AddressHistory = ({ isLocked, sectionName, reportDetail }) => {
  const { address_history } = reportDetail?.phoneReport || {};
  const { data, status } = address_history || {};

  return isLocked ? (
    <UnlockReport
      title={t("PR_ADDRESS_HISTORY")}
      image={possiblePhotosImg}
      buttonText={t("PR_UNLOCK_THIS_SECTION").toUpperCase()}
      shouldKnowText={t("PR_UNLOCK_TITLE")}
      knownText={t("PR_ADDRESS_HISTORY_MESSAGE")}
      sectionName={sectionName}
      pricingSectionName={"address_history"}
    />
  ) : (
    <div
      className="ds--address-history white-bg-wrap"
      id={toCamelCase(t("PR_ADDRESS_HISTORY"))}
    >
      <div className="unlock_title unlock-description">
          {status === "found" && <h2>{t("PR_ADDRESS_HISTORY")}</h2>}
        <p>{t("PR_ADDRESS_HISTORY_DESCRIPTION_1")}</p>
      </div>
        {status === "found" && data?.length > 0 ? (
            <div className="address-history-box">
              {data.map((addressItem, index) => (
              <div key={addressItem._id} className="history--box-cmt">
                <img src={historyLocationIcon} alt="" />
                <div className="history--cmt">
                  <h3>{formatAddress(addressItem)}</h3>
                </div>
              </div>
              ))}
          </div>
        ) : (
          <p className="data_notfound"><img src={nodataIcon} alt="" /> Record Not Found</p>
        )}
    </div>
  );
};

export default AddressHistory;
