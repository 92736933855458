import React from "react";
import { t } from "i18next";
import {
  accordionIcon,
  premiumToggleBar,
  possiblePhotosImg,
  nodataIcon
} from "../../../assets/images";
import { carouselFullWidth, toCamelCase } from "../../../utils/commonUtils";
import { Collapse } from "antd";
import Slider from "react-slick";
import UnlockReport from "../unlockReport/unlockReport";

const PossiblePhotos = ({ isLocked, sectionName, reportDetail }) => {
  const { possible_photos } = reportDetail?.phoneReport || {};
  const { data, status } = possible_photos || {};

  return isLocked ? (
    <UnlockReport
      title={t("PR_POSSIBLE_PHOTOS")}
      image={possiblePhotosImg}
      buttonText={t("PR_UNLOCK_THIS_SECTION").toUpperCase()}
      shouldKnowText={t("PR_UNLOCK_TITLE")}
      knownText={t("PR_POSSIBLE_PHOTOS_MESSAGE")}
      sectionName={sectionName}
      pricingSectionName={"possible_photos"}
    />
  ) : (
    <div
      className="ds--unlock-photos white-bg-wrap"
      id={toCamelCase(t("PR_POSSIBLE_PHOTOS"))}
    >
      <div className="ds--unlock__title left-content main_title toggle_area">
        <h2>{t("PR_POSSIBLE_PHOTOS")}</h2>
        <div className="premium_toggle">
          <p to={""}>
            <img src={premiumToggleBar} alt="" />
          </p>
        </div>
      </div>
        {status === "found" && data.length > 0 ? (
          <>
            <div className="ds--accordion-collapse">
              <Collapse
                items={[
                  {
                    key: "1",
                    label: "Learn more",
                    children: (
                      <>
                        {" "}
                        <p>1 {t("PR_LEARN_MORE_DESCRIPTION_2")}</p>
                        <p>{t("PR_POSSIBLE_PHOTOS_SUB_DESCRIPTION")}</p>
                      </>
                    )
                  }
                ]}
                expandIconPosition="end"
                expandIcon={({ isActive }) => (
                  <img
                    src={isActive ? accordionIcon : accordionIcon}
                    width="2em"
                    height="2em"
                    alt=""
                  />
                )}
              />
            </div>
          <div className="ds-phone-slider">
            <Slider {...carouselFullWidth}>
              {data.map((item, index) => (
                <div key={index}>
                  <img
                    src={item.url}
                    alt={`Image ${index + 1}`}
                    style={{ height: 185, width: 185, borderRadius: 10 }}
                  />
                </div>
              ))}
            </Slider>
          </div>
          </>
        ) : (
          <p className="data_notfound"><img src={nodataIcon} alt="" /> Record Not Found</p>
        )}
    </div>
  );
};

export default PossiblePhotos;
