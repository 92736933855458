import ModalComponent from "../../../common/Modal/modal";
import React, { useEffect, useState } from "react";
import "./phoneReportPayModal.scss";
import { useNavigate } from "react-router-dom";
import { Cookies } from "react-cookie";
import PhoneNumberAttributeDetails from "../phoneNumberAttributeDetails/phoneNumberAttributeDetails";
import { useTranslation } from "react-i18next";
import UnlockAnimation from "../../../../lottieAnimations/UnlockLottieAnimation";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import Notify from "../../../../../components/common/Notify/notify";
import { connect } from "react-redux";
import { confirmPayment } from "../../../../utils/commonUtils";
import { unlockAllSection } from "../../../../redux/slice/unlockAllSectionSlice";
import { unlockOneSection } from "../../../../redux/slice/unlockOneSectionSlice";
import { generatePayment } from "../../../../redux/slice/generatePaymentSlice";
import { getPhoneReportDetails } from "../../../../redux/slice";
import TransactionDeclinedModal from "../../../../pages/transactionDeclined/transactionDeclined";
import { retryPayment } from "../../../../redux/slice/retryPaymentSlice";
import { updatePaymentMethod } from "../../../../redux/slice/updatePaymentMethodSlice";
import { createSubscription } from "../../../../redux/slice/createSubscriptionSlice";
const PhoneReportPayModal = ({
  // Props from component
  isOpen,
  onClose,
  subscriptionData,
  sectionName,
  mode,
  // API and state
  callGeneratePayment,
  callUnlockAllSection,
  callUnlockOneSection,
  phoneReportDetails,
  callGetPhoneReportDetails,
  callRetryPayment,
  callUpdatePaymentMethod,
  callCreateSubscription,
  // Dynamic Title
  title,
  // Pricing Data
  pricingData,
}) => {
  const label1 =
    mode === "section"
      ? "unlock_this_section"
      : mode === "full_report" || mode === "monitoring_report"
      ? "unlock_full_report"
      : "unlock_this_section";
  const label2 =
    mode === "section"
      ? "unlock_full_report"
      : mode === "full_report" || mode === "monitoring_report"
      ? "unlock_full_report_Subscription"
      : "unlock_full_report";
  const { phoneReport, subscription, unlocked } = phoneReportDetails || {};
  const isSubscription = subscription?.includes("unlock_full_report");
  const { t } = useTranslation();
  const navigate = useNavigate();
  const cookies = new Cookies();
  const lang = cookies.get("lang");
  const stripe = useStripe();
  const elements = useElements();

  const [retryModal, setRetryModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [termStatus, setTermStatus] = useState(false);
  const [termError, setTermError] = useState("");
  const [stripeError, setStripeError] = useState("");
  const [amount, setAmount] = useState(isSubscription ? 75 : 1785);
  const [selectedOption, setSelectedOption] = useState(
    isSubscription ? label1 : label2
  );
  const [infoModal, setInfoModal] = useState(false);

  useEffect(() => {
    if (pricingData && pricingData.label_1) {
      setAmount(isSubscription ? pricingData.label_1 : pricingData.label_2);
    }
  }, [pricingData, isSubscription]);

  const handleClose = () => {
    onClose();
    setTermError("");
    setTermStatus(false);
  };
  const handleChange = (event, amount) => {
    const value = event.target.value;
    setAmount(amount);
    setSelectedOption(value);
  };
  const handleChangeTerm = (event) => {
    setTermStatus(event.target.checked);
    setTermError("");
  };

  const onClickOfPayButton = async () => {
    if (!termStatus) {
      setTermError("Please accept Terms and Condition");
      return;
    }
    if (!stripe || !elements) {
      return;
    }
    handleClose();
    setIsLoading(true);
    const payload = {
      price_title:
        selectedOption === "unlock_this_section"
          ? sectionName
          : "unlock_full_report",
      type:
        selectedOption === "unlock_full_report_Subscription"
          ? "subscription"
          : "one-time",
    };
    // below condition check report is already opened or not, if open then wi throw the message else do process ahead
    if (
      payload.price_title === "unlock_full_report" &&
      payload.type === "one-time" &&
      phoneReport?.status === "unlocked"
    ) {
      setIsLoading(false);
      return Notify("error", "Full Report is already opened", "");
    }
    // mode condition
    if (mode === "section" && unlocked.includes(sectionName)) {
      setIsLoading(false);
      return Notify("error", "Section is already Unlocked", "");
    }
    const res = await callGeneratePayment(payload);
    if (res.type === "generatePayment/fulfilled") {
      const { client_secret, payment_method, subscription_id } =
        res.payload.data || {};
      const { error, paymentIntent } = await confirmPayment(
        stripe,
        client_secret,
        payment_method
      );
      if (error) {
        handleClose();
        setRetryModal(true);
      } else {
        if (subscription_id) {
          const payload = {
            phone_report_id: phoneReport?._id,
            subscription_id: subscription_id,
          };
          const res = await callCreateSubscription(payload);
          if (res.type === "createSubscription/fulfilled") {
            Notify("success", res.payload.message, "");
            // await callGetPhoneReportDetails(phoneReport?._id);
            setInfoModal(true);
          } else {
            Notify("error", res.error.message, "");
          }
        } else {
          handleUnlockSection(paymentIntent.id);
        }
      }
    } else {
      Notify("error", res.error.message, "");
    }
    handleClose();
    setIsLoading(false);
  };

  // Handle retry

  const onRetry = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    const cardNumberElement = elements.getElement(CardNumberElement);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardNumberElement,
    });
    if (error) {
      setIsLoading(false);
      setStripeError(error.message);
      return;
    }
    const data = {
      price_title:
        selectedOption === "unlock_this_section"
          ? sectionName
          : "unlock_full_report",
      type:
        selectedOption === "unlock_full_report_Subscription"
          ? "subscription"
          : "one-time",
    };
    const res = await callRetryPayment(data);
    if (res.type === "retryPayment/fulfilled") {
      const { client_secret: clientSecret, subscription_id } =
        res.payload.data || {};
      const { error, paymentIntent } = await confirmPayment(
        stripe,
        clientSecret,
        paymentMethod.id
      );
      if (error) {
        elements.getElement(CardNumberElement).clear();
        elements.getElement(CardExpiryElement).clear();
        elements.getElement(CardCvcElement).clear();
        setRetryModal(false);
        setTimeout(() => {
          setRetryModal(true);
        }, 500);
      } else {
        if (subscription_id) {
          const payload = {
            phone_report_id: phoneReport?._id,
            subscription_id: subscription_id,
          };
          const res = await callCreateSubscription(payload);
          if (res.type === "createSubscription/fulfilled") {
            Notify("success", res.payload.message, "");
            // await callGetPhoneReportDetails(phoneReport?._id);
            setInfoModal(true);
          } else {
            Notify("error", res.error.message, "");
          }
        } else {
          handleUnlockSection(paymentIntent.id);
        }
        setRetryModal(false);
        callUpdatePaymentMethod(paymentMethod.id);
      }
    } else {
      Notify("error", res.error.message, "");
    }
    setIsLoading(false);
  };

  const handleUnlockSection = async (paymentIntentId) => {
    const obj = {
      phone_report_id: phoneReport?._id,
      section_name:
        selectedOption === "unlock_full_report" ? undefined : sectionName,
      payment_intent_id: paymentIntentId,
    };
    const res =
      selectedOption === "unlock_full_report"
        ? await callUnlockAllSection(obj)
        : await callUnlockOneSection(obj);
    if (res.type.includes("fulfilled")) {
      Notify("success", res.payload.message, "");
      // await callGetPhoneReportDetails(phoneReport?._id);
      setInfoModal(true);
    } else {
      Notify("error", res.error.message, "");
    }
  };

  return (
    <>
      {isLoading && <UnlockAnimation />}
      {isOpen && (
        <ModalComponent isOpen={isOpen} onClose={handleClose} closable={false}>
          <div className="multiple_popup__wrap">
            <div className="wpb_report_popup fixed-size">
              {subscriptionData?.image ? (
                <img
                  src={subscriptionData.image}
                  alt=""
                  height={100}
                  width={100}
                />
              ) : (
                ""
              )}

              <div className="mlt_popup__text">
                <h2>
                  {subscriptionData
                    ? subscriptionData.title
                    : `Unlock ${t(title)}`}
                </h2>
                <p>
                  {subscriptionData
                    ? t(subscriptionData.description)
                    : t("PR_UNLOCK_POPUP_DESCRIPTION") + " " + "819-685-8888!"}
                </p>
              </div>
              <div className="mlt_popup__payment">
                <form action="/">
                  <label htmlFor="unlock_report" className="dsl_report">
                    <div className="dsl_report_field">
                      <input
                        type="radio"
                        id="unlock_report"
                        name="report_option"
                        value={label1}
                        checked={selectedOption === label1}
                        onChange={(e) =>
                          handleChange(
                            e,
                            isOpen && pricingData.label_1 && pricingData.label_1
                          )
                        }
                      />
                      <span className="report_radio_wrap">
                        {subscriptionData
                          ? t(subscriptionData.label1)
                          : t("Unlock this Section")}
                      </span>
                    </div>
                    <div className="dsl_report_payment">
                      <span>
                        {isOpen &&
                          pricingData.label_1 &&
                          pricingData.symbol + pricingData.label_1}
                      </span>
                    </div>
                  </label>
                  {!isSubscription && (
                    <label htmlFor="due_now" className="dsl_report">
                      <div className="dsl_report_field">
                        <input
                          type="radio"
                          id="due_now"
                          name="report_option"
                          value={label2}
                          checked={selectedOption === label2}
                          onChange={(e) =>
                            handleChange(
                              e,
                              isOpen &&
                                pricingData.label_2 &&
                                pricingData.label_2
                            )
                          }
                        />
                        <span className="report_radio_wrap">
                          {subscriptionData
                            ? t(subscriptionData.label2)
                            : t("PR_STEP_3_TITLE_1")}
                        </span>
                      </div>
                      <div className="dsl_report_payment">
                        <span>
                          {isOpen &&
                            pricingData.label_2 &&
                            pricingData.symbol + pricingData.label_2}
                          {subscriptionData && t("PR_UNLOCK_POPUP_TEXT_3")}
                        </span>
                        {subscriptionData && (
                          <small>
                            {30 + " "}
                            {subscriptionData
                              ? t(subscriptionData.reports)
                              : t("PR_UNLOCK_POPUP_REPORTS")}
                          </small>
                        )}
                      </div>
                    </label>
                  )}
                </form>
              </div>
              <div className="mlt_popup__subscribe">
                <div className="policy__content_wrap">
                  <input
                    type="checkbox"
                    id="tracelo_Privacy"
                    name="Privacy Content"
                    onChange={handleChangeTerm}
                  />
                  <label htmlFor="tracelo_Privacy">
                    {t("PR_UNLOCK_POPUP_TERM")}
                  </label>
                </div>
                <div className="error">
                  <p>{termError}</p>
                </div>
                <div className="report__popup_pay_btn">
                  <button type="submit" onClick={() => onClickOfPayButton()}>
                    {t("PR_PAY")} {pricingData.label_1 && pricingData.symbol}{" "}
                    {amount}
                  </button>
                  <p>
                    <span onClick={handleClose}>{t("PR_CANCEL")}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </ModalComponent>
      )}
      {/* Transaction retry Modal */}
      {retryModal && (
        <TransactionDeclinedModal
          isOpen={retryModal}
          onClose={() => (setRetryModal(false), setStripeError(""))}
          closable={false}
          handleSubmit={onRetry}
          stripeError={stripeError}
        />
      )}
      {infoModal && (
        <PhoneNumberAttributeDetails
          isOpen={infoModal}
          onClose={() => {
            setInfoModal(false);
            callGetPhoneReportDetails(phoneReport?._id);
          }}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    phoneReportDetails:
      state.getPhoneReportDetails?.getPhoneReportDetailsData?.data,
    pricingTitleList: state.pricingTitleList?.getPhoneReportDetailsData?.data,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    callUnlockOneSection: (data) => dispatch(unlockOneSection(data)),
    callUnlockAllSection: (data) => dispatch(unlockAllSection(data)),
    callGeneratePayment: (data) => dispatch(generatePayment(data)),
    callGetPhoneReportDetails: (data) => dispatch(getPhoneReportDetails(data)),
    callRetryPayment: (data) => dispatch(retryPayment(data)),
    callUpdatePaymentMethod: (data) => dispatch(updatePaymentMethod(data)),
    callCreateSubscription: (data) => dispatch(createSubscription(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PhoneReportPayModal);
