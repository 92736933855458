import React from "react";
import OverViewMenuComponent from "./overViewMenu";
import { connect } from "react-redux";
const OverViewMenu = ({ getPhoneReportDetails, number }) => {
  return (
    <OverViewMenuComponent
      getPhoneReportDetails={getPhoneReportDetails}
      number={number}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    getPhoneReportDetails: state.getPhoneReportDetails,
  };
};

export default connect(mapStateToProps, null)(OverViewMenu);
